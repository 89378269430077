import * as React from 'react';
import { Card, Typography, Link, Box, IconButton, Grid, Stack, Chip} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { faGripDots, faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import {faHeart as faHeart_solid} from '@fortawesome/pro-solid-svg-icons/faHeart';
import PortalTooltip from "../common/PortalTooltip";
import {isMobile} from 'react-device-detect';

export class ApplicationCard extends React.Component {
  toggleTooltip = () => {
    this.state.tooltipIsOpen= !this.state.tooltipIsOpen;
  };

  writeIconGroup = () => {
    return (<PortalTooltip id={this.props.app?.id + "_tooltip_box"} title={(isMobile || this.props.showReorder) ? '' : this.getTooltipTitle()}>
            <Stack 
            // {condition ? {sm: 'column'} : {sm: "column", md:"row"}}
            directtion = {this.props.isSetup ? 'column' : { sm: "column", md:"column", lg: "row", xl: "column", xxl: "column", 'xxxl': 'column', 'xxxxl': 'row' }} 
            alignItems={"center"} justifyContent={"flex-start"} sx={{width: '100%'}}>
                { this.props.showReorder ? this.writeIconImg() : this.writeLink(this.writeIconImg()) }
                { this.props.showReorder ? this.writeIconTitle() : <Box sx={{width: '100%'}} className="text-center">{this.writeLink (this.writeIconTitle())}</Box> }
            </Stack>
        </PortalTooltip>
    )
  }

  writeIconImg = () => {
    return <Box className="wrapperBox"><Box sx={{ width: '40px'}}  className={'myOdu__logoZoom iconImage ' + (this.props.showReorder? 'reorder' : '')}>
        <img src={this.props.data?.icon ?? 'img/logos/genericApp.svg'} alt={this.props.app?.name ?? "application icon"} />
    </Box></Box>
  }

  writeIconTitle = () => {
    return <Typography variant="smallest" sx={{ wordBreak: 'break-word', width: '100%'}} className="appCardTitle">
            {this.props.app?.name}
        </Typography>
    }

  writeLink = (child) => {
    return <Link className="text-center" id={"applicationCard__link_" + this.props.app?.id} href={this.props.app?.link} target="_blank" underline="none">
            {child}
        </Link>
  }

    getTooltipTitle = () =>{
        return (this.props.app?.link ? 
            (this.props.app?.linkTarget == "_blank") ? 
                "Open " + this.props.app?.name + " in new window" 
                : 
                "Go to " + this.props.app?.name
            :
            this.props.app?.name
        )
  };

    render(){
        // const linkTitle = this.getLinkTitle();
        return (   
            <Card sx={{ p:1, height: '100%', width: '100%'}} className={'myOdu__appCard ' + (this.props.isActive ? "active " : "disabled ") + this.props.className } elevation={0} {...this.props.dragHandleProps}>
                <Grid container direction='column' style={{opacity: this.props.opacity}}>
                {(this.props.showHeart || this.props.showInfo || this.props.showReorder) && 
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent={'space-between'}
                        sx={{mb: "-.5rem"}}
                    >                        
                        {
                        this.props.showReorder && <>
                            <Box sx={{height: '1px', mt:1}}>
                                <PortalTooltip title={ isMobile ? '' : 'Hold to move ' + this.props.app?.name } >
                                    <IconButton className="myOdu__iconButton dragGrip ms-0 ms-sm-1" id={ 'applicationCard__button_reorder_' + this.props.app?.name.replace( ' ', '' ) } size="small" {...this.props.listeners} >
                                        <FontAwesomeIcon icon={ faGripDotsVertical } size="small" />
                                    </IconButton>
                                </PortalTooltip>
                            </Box>
                        </>
                        }

                        {this.props.showHeart && (
                            <PortalTooltip title={ this.props.isActive ? 'Remove ' + this.props.app?.name + ' from favorite apps' : 'Add to favorite apps' } >
                                {/* //TODO: call string cleaner here */}
                                <IconButton
                                    onClick={this.props.onClick} 
                                    size="small"
                                    id={ this.props.id + '__applicationCard__button_favorite_' + this.props.app?.name.replace( ' ', '' ) }
                                   
                                >
                                    {this.props.isActive ? (
                                        <FontAwesomeIcon icon={faHeart_solid} className="myOdu__favorite" size="small" />
                                    ) : (
                                        <FontAwesomeIcon icon={faHeart} size="small" />
                                    )}
                                </IconButton>
                            </PortalTooltip>
                        )}
                        {this.props.showInfo && <>
                                <PortalTooltip title={ this.props.app ?.desc ?? this.props.app?.name } >
                                    <IconButton id={ 'applicationCard__button_moreInfo_' + this.props.app?.name.replace( ' ', '' ) } size="small" >
                                        <FontAwesomeIcon icon={ faCircleInfo } size="small" />
                                    </IconButton>
                                </PortalTooltip>
                            </>
                        }
                    </Stack>
                }

                <Grid item sx={{lineHeight: "110%"}}>
                    <div className={this.props.className}>              
                        {this.writeIconGroup()}
                    </div>
                </Grid>

                {this.props.hasSchool && 
                    <Stack direction="row" alignItems={"flex-end"} justifyContent={"center"} sx={{my:1, pt: 1, height: '100%'}} >
                        { this.props.app.isODU && <PortalTooltip title="Available for Old Dominion University users"><Chip label="ODU" color="primary" sx={{fontSize: '.65rem', mr:1, height: '1rem'}}/></PortalTooltip> }
                        { this.props.app.isEVMS && <PortalTooltip title="Available for Virginia Health Sciences users"><Chip label="VHS" color="secondary" sx={{fontSize: '.65rem', height: '1rem'}}/></PortalTooltip> }
                        {/* <Box sx={{ height: '1rem', width: '1rem', marginTop: '-4px', marginRight: '.5rem' }} >
                            <img src={'/img/oduCrown_blue.svg'} alt="ODU app" />
                        </Box>
                        <Box sx={{ height: '1rem', width: '2.75rem', marginTop: '-4px', marginRight: '.5rem' }} >
                            <img src={'/img/logos/evmsText.svg'} alt="EVMS app" />
                        </Box> */}
                    </Stack>
                }
                </Grid>
            </Card>
        );
    }
}

export default ApplicationCard;

