import {LinearProgress, Paper} from '@mui/material';
import React, {Component} from 'react';
import PortalDataGrid from '../../common/PortalDataGrid';

class ErrorLogTable extends Component {
    state = {
        page: 0,
        rowsPerPage: 10
    };

    render() {
        return (
            <React.Fragment>
                <Paper sx={{width: '100%', overflow: 'hidden'}} elevation={0}>
                    <PortalDataGrid
                        rows={this.props.rows}
                        columns={this.props.columns}
                        handleClick={this.props.onClick}
                        isLoading={this.props.isLoading}
                        columnVisibilityModel={{
                            ...this.props.columnVisibilityModel,
                            createdAt: false
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10}
                            }
                        }}
                        getRowId={row => {
                            return row.id
                                ? row.id
                                : row.STATE.concat('_', row.TIMESTAMP);
                        }}
                        slots={{
                            ...this.props.slots,
                            loadingOverlay: LinearProgress
                        }}
                        slotProps={this.props.slotProps}
                        disableColumnFilter={this.props.disableColumnFilter}
                        disableColumnSelector={this.props.disableColumnSelector}
                        disableDensitySelector={
                            this.props.disableDensitySelector
                        }
                    />
                </Paper>
            </React.Fragment>
        );
    }
}

export default ErrorLogTable;
