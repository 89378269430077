export const cctCourse = {
    "CRNS": "36016,36017,36018",
    "CROSS_LIST": "SC011",
    "SSBSECT_TERM_CODE": "202330",
    "SUBJECT_CODE": "CS",
    "COURSE_NUMBER": "151",
    "COURSE_TITLE": "INTRO PROGRAMING WITH JAVA LAB",
    "SUBJ_DESC": "Computer Science",
    "SSBSECT_PTRM_CODE": "3",
    "PTRM_CODE": "Session 3",
    "SSBSECT_SCHD_CODE": "B",
    "SCHD_DESC": "Laboratory",
    "GMOD_CODE": "Not Gradable",
    "STVGMOD_DESC": "Not Gradable",
    "START_DATE": "2024-05-13",
    "END_DATE": "2024-08-02",
    "MEETING_DAYS": "W",
    "TIMES": "16:20 - 19:00",
    "BEGIN_TIME": "16:20",
    "END_TIME": "19:00",
    "BUILDING_CODES": "WC2,WC5,WC7",
    "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
    "ROOM_CODES": null,
    isTeaching: true
}

export const cctTeaching = [
        {
            "CRNS": "16673",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "697",
            "COURSE_TITLE": "Independent Study in Computer Science",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "E",
            "SCHD_DESC": "Independent Study",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "TBA",
            "BUILDING_DESCRIPTIONS": "To Be Announced",
            "ROOM_CODES": "TBA"
        },
        {
            "CRNS": "17138,21463,21464,13825,18441,21465",
            "CROSS_LIST": "SC159",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "462",
            "COURSE_TITLE": "Cybersecurity Fundamentals",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7,WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va,Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "17714,17715,17716",
            "CROSS_LIST": "SC166",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "170",
            "COURSE_TITLE": "Introduction to Computer Architecture I",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "18932,18933,18934,18936,18937,18938",
            "CROSS_LIST": "SC245",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "455",
            "COURSE_TITLE": "Introduction to Networks and Communications",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "TR",
            "TIMES": "11:00 - 12:15",
            "BEGIN_TIME": "11:00",
            "END_TIME": "12:15",
            "BUILDING_CODES": "WC2,WC5,WC7,WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia,Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "20104",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "TR",
            "TIMES": "09:30 - 10:45",
            "BEGIN_TIME": "09:30",
            "END_TIME": "10:45",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "1117"
        },
        {
            "CRNS": "20105",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "W",
            "TIMES": "15:00 - 17:30",
            "BEGIN_TIME": "15:00",
            "END_TIME": "17:30",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "20106",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "W",
            "TIMES": "18:00 - 20:30",
            "BEGIN_TIME": "18:00",
            "END_TIME": "20:30",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "20107",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "T",
            "TIMES": "15:00 - 17:30",
            "BEGIN_TIME": "15:00",
            "END_TIME": "17:30",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "20108",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "M",
            "TIMES": "13:00 - 13:50",
            "BEGIN_TIME": "13:00",
            "END_TIME": "13:50",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "20109",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "M",
            "TIMES": "14:00 - 14:50",
            "BEGIN_TIME": "14:00",
            "END_TIME": "14:50",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "20231,20232,20233",
            "CROSS_LIST": "SC013",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "20237,20238,20239",
            "CROSS_LIST": "SC021",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMING JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "M",
            "TIMES": "17:45 - 20:25",
            "BEGIN_TIME": "17:45",
            "END_TIME": "20:25",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "20240,20241,20242",
            "CROSS_LIST": "SC112",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMING JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "T",
            "TIMES": "17:45 - 20:25",
            "BEGIN_TIME": "17:45",
            "END_TIME": "20:25",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "20243,20244,20245",
            "CROSS_LIST": "SC154",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMING JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "F",
            "TIMES": "14:00 - 16:30",
            "BEGIN_TIME": "14:00",
            "END_TIME": "16:30",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "20246,20247,20248",
            "CROSS_LIST": "SC022",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMING JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "T",
            "TIMES": "18:10 - 19:00",
            "BEGIN_TIME": "18:10",
            "END_TIME": "19:00",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "20249,20250",
            "CROSS_LIST": "SC113",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMING JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "F",
            "TIMES": "18:00 - 18:50",
            "BEGIN_TIME": "18:00",
            "END_TIME": "18:50",
            "BUILDING_CODES": "WC2,WC5",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "20251",
            "CROSS_LIST": "SC113",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMING JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "T",
            "TIMES": "18:00 - 18:50",
            "BEGIN_TIME": "18:00",
            "END_TIME": "18:50",
            "BUILDING_CODES": "WC7",
            "BUILDING_DESCRIPTIONS": "Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "20252,20253,20254",
            "CROSS_LIST": "SC153",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMING JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "T",
            "TIMES": "11:00 - 11:50",
            "BEGIN_TIME": "11:00",
            "END_TIME": "11:50",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "21273,21274,21275,21276",
            "CROSS_LIST": "SC161",
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "462",
            "COURSE_TITLE": "Cybersecurity Fundamentals",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB2,WEB5",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web2 Online Hampton Roads,Web5 Online Students In Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "22616",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202410",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "695",
            "COURSE_TITLE": "TPCS:DIGITAL IMAGE PROC & APPL",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-08-24",
            "END_DATE": "2024-12-06",
            "MEETING_DAYS": "TR",
            "TIMES": "15:00 - 16:15",
            "BEGIN_TIME": "15:00",
            "END_TIME": "16:15",
            "BUILDING_CODES": "CONST",
            "BUILDING_DESCRIPTIONS": "Constant Hall",
            "ROOM_CODES": "1010"
        },
        {
            "CRNS": "22922,29782,29783,29784,25766,29785,29786,29787",
            "CROSS_LIST": "SC493",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "455",
            "COURSE_TITLE": "Introduction to Networks and Communications",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "TR",
            "TIMES": "09:30 - 10:45",
            "BEGIN_TIME": "09:30",
            "END_TIME": "10:45",
            "BUILDING_CODES": "OCNPS,WC2,WC5,WC7,OCNPS,WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Oceanography Bldg,Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia,Oceanography Bldg,Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": "0100,0100"
        },
        {
            "CRNS": "27866",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "697",
            "COURSE_TITLE": "Independent Study in Computer Science",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "E",
            "SCHD_DESC": "Independent Study",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "TBA",
            "BUILDING_DESCRIPTIONS": "To Be Announced",
            "ROOM_CODES": "TBA"
        },
        {
            "CRNS": "28657,28661,28662",
            "CROSS_LIST": "SC452",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "170",
            "COURSE_TITLE": "Introduction to Computer Architecture I",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "30653,30654,30655",
            "CROSS_LIST": "SC455",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "295",
            "COURSE_TITLE": "TPCS: DIGITAI IMAGE PROC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "32396",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "TR",
            "TIMES": "13:30 - 14:45",
            "BEGIN_TIME": "13:30",
            "END_TIME": "14:45",
            "BUILDING_CODES": "KAUF",
            "BUILDING_DESCRIPTIONS": "Kaufman Hall",
            "ROOM_CODES": "0100"
        },
        {
            "CRNS": "32398",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMNG WITH JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "W",
            "TIMES": "09:00 - 11:30",
            "BEGIN_TIME": "09:00",
            "END_TIME": "11:30",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "32399",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMNG WITH JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "W",
            "TIMES": "12:00 - 14:30",
            "BEGIN_TIME": "12:00",
            "END_TIME": "14:30",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "32400",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMMNG WITH JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "W",
            "TIMES": "15:00 - 17:30",
            "BEGIN_TIME": "15:00",
            "END_TIME": "17:30",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "32404",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMING WITH JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "M",
            "TIMES": "10:00 - 10:50",
            "BEGIN_TIME": "10:00",
            "END_TIME": "10:50",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "32405",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMING WITH JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "M",
            "TIMES": "14:00 - 14:50",
            "BEGIN_TIME": "14:00",
            "END_TIME": "14:50",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "32406",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMING WITH JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "M",
            "TIMES": "11:00 - 11:50",
            "BEGIN_TIME": "11:00",
            "END_TIME": "11:50",
            "BUILDING_CODES": "DRGS",
            "BUILDING_DESCRIPTIONS": "Dragas Hall",
            "ROOM_CODES": "2112"
        },
        {
            "CRNS": "32614,32615,32616",
            "CROSS_LIST": "SC052",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "32617,32618,32619",
            "CROSS_LIST": "SC053",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAM WITH JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "T",
            "TIMES": "19:25 - 20:15",
            "BEGIN_TIME": "19:25",
            "END_TIME": "20:15",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "32620,32621,32622",
            "CROSS_LIST": "SC054",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAM WITH JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "T",
            "TIMES": "16:30 - 19:10",
            "BEGIN_TIME": "16:30",
            "END_TIME": "19:10",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "32623,32624,32625",
            "CROSS_LIST": "SC126",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAM WITH JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "M",
            "TIMES": "16:30 - 19:10",
            "BEGIN_TIME": "16:30",
            "END_TIME": "19:10",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "32626,32627,32628",
            "CROSS_LIST": "SC127",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAM WITH JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "T",
            "TIMES": "15:00 - 15:50",
            "BEGIN_TIME": "15:00",
            "END_TIME": "15:50",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "32697,32698,32699",
            "CROSS_LIST": "SC468",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAM WITH JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "R",
            "TIMES": "16:30 - 19:10",
            "BEGIN_TIME": "16:30",
            "END_TIME": "19:10",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "32701,32702,32705",
            "CROSS_LIST": "SC473",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAM WITH JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "T",
            "TIMES": "20:10 - 21:00",
            "BEGIN_TIME": "20:10",
            "END_TIME": "21:00",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "32861,32862,32863,32864",
            "CROSS_LIST": "SC252",
            "SSBSECT_TERM_CODE": "202320",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "695",
            "COURSE_TITLE": "TPCS:DIGITAL IMAGE PROC & APPL",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "1",
            "PTRM_CODE": "Session 1",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-01-06",
            "END_DATE": "2024-04-22",
            "MEETING_DAYS": "MW",
            "TIMES": "09:30 - 10:45",
            "BEGIN_TIME": "09:30",
            "END_TIME": "10:45",
            "BUILDING_CODES": "DRGS,WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Dragas Hall,Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": "2114"
        },
        {
            "CRNS": "33077",
            "CROSS_LIST": "SC020",
            "SSBSECT_TERM_CODE": "202330",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "562",
            "COURSE_TITLE": "Cybersecurity Fundamentals",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "3",
            "PTRM_CODE": "Session 3",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-05-13",
            "END_DATE": "2024-08-02",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB7",
            "BUILDING_DESCRIPTIONS": "Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "34324,34325,34326,34327,34328,34329",
            "CROSS_LIST": "SC070",
            "SSBSECT_TERM_CODE": "202330",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "463",
            "COURSE_TITLE": "Cryptography for Cybersecurity",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "3",
            "PTRM_CODE": "Session 3",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-05-13",
            "END_DATE": "2024-08-02",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7,WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va,Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "34534,34535,34536",
            "CROSS_LIST": "SC247",
            "SSBSECT_TERM_CODE": "202330",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "170",
            "COURSE_TITLE": "Introduction to Computer Architecture I",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "3",
            "PTRM_CODE": "Session 3",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-05-13",
            "END_DATE": "2024-08-02",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "35178",
            "CROSS_LIST": null,
            "SSBSECT_TERM_CODE": "202330",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "697",
            "COURSE_TITLE": "Independent Study in Computer Science",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "3",
            "PTRM_CODE": "Session 3",
            "SSBSECT_SCHD_CODE": "E",
            "SCHD_DESC": "Independent Study",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-05-13",
            "END_DATE": "2024-08-02",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "TBA",
            "BUILDING_DESCRIPTIONS": "To Be Announced",
            "ROOM_CODES": "TBA"
        },
        {
            "CRNS": "36013,36014,36015",
            "CROSS_LIST": "SC013",
            "SSBSECT_TERM_CODE": "202330",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "Introduction to Programming with Java",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "3",
            "PTRM_CODE": "Session 3",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-05-13",
            "END_DATE": "2024-08-02",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        },
        {
            "CRNS": "36016,36017,36018",
            "CROSS_LIST": "SC011",
            "SSBSECT_TERM_CODE": "202330",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMING WITH JAVA LAB",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "3",
            "PTRM_CODE": "Session 3",
            "SSBSECT_SCHD_CODE": "B",
            "SCHD_DESC": "Laboratory",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-05-13",
            "END_DATE": "2024-08-02",
            "MEETING_DAYS": "W",
            "TIMES": "16:20 - 19:00",
            "BEGIN_TIME": "16:20",
            "END_TIME": "19:00",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "36019,36020,36021",
            "CROSS_LIST": "SC012",
            "SSBSECT_TERM_CODE": "202330",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "151",
            "COURSE_TITLE": "INTRO PROGRAMING WITH JAVA REC",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "3",
            "PTRM_CODE": "Session 3",
            "SSBSECT_SCHD_CODE": "C",
            "SCHD_DESC": "Recitation/Discussion",
            "GMOD_CODE": "Not Gradable",
            "STVGMOD_DESC": "Not Gradable",
            "START_DATE": "2024-05-13",
            "END_DATE": "2024-08-02",
            "MEETING_DAYS": "W",
            "TIMES": "19:10 - 20:00",
            "BEGIN_TIME": "19:10",
            "END_TIME": "20:00",
            "BUILDING_CODES": "WC2,WC5,WC7",
            "BUILDING_DESCRIPTIONS": "Wc2 Web Conf Hampton Roads,Wc5 Web Conf In Virginia,Wc7 Web Conf Outside Virginia",
            "ROOM_CODES": null
        },
        {
            "CRNS": "36049,36050,36051",
            "CROSS_LIST": "SC263",
            "SSBSECT_TERM_CODE": "202330",
            "SUBJECT_CODE": "CS",
            "COURSE_NUMBER": "390",
            "COURSE_TITLE": "Introduction to Theoretical Computer Science",
            "SUBJ_DESC": "Computer Science",
            "SSBSECT_PTRM_CODE": "3",
            "PTRM_CODE": "Session 3",
            "SSBSECT_SCHD_CODE": "A",
            "SCHD_DESC": "Lecture",
            "GMOD_CODE": "N",
            "STVGMOD_DESC": "Normal Letter Grade",
            "START_DATE": "2024-05-13",
            "END_DATE": "2024-08-02",
            "MEETING_DAYS": null,
            "TIMES": " - ",
            "BEGIN_TIME": null,
            "END_TIME": null,
            "BUILDING_CODES": "WEB2,WEB5,WEB7",
            "BUILDING_DESCRIPTIONS": "Web2 Online Hampton Roads,Web5 Online Students In Va,Web7 Online Stdnts Outside Va",
            "ROOM_CODES": null
        }
    ];

export const cctTaking =  [];

export const cctTerms = [
        {
            "code": "202320",
            "title": "SPRING 2024",
            "relativeTerm": "PREVIOUS_TERM"
        },
        {
            "code": "202330",
            "title": "SUMMER 2024",
            "relativeTerm": "CURRENT_TERM"
        },
        {
            "code": "202410",
            "title": "FALL 2024",
            "relativeTerm": "NEXT_TERM"
        }
    ];