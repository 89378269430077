
import * as React from 'react';
import { Container, Box, Typography, CssBaseline, Theme, useTheme, ThemeProvider } from '@mui/material';
import theme from '../../theme';
// import MultipleSelect from '../components/MultipleSelect';

export function BreakpointBoxes () {
  return (
	<ThemeProvider theme={theme}>
		{/* <Box display={{mobileSm: 'block', mobile: 'none',   xs: 'none', sm: 'none',  md:'none',  lg: 'none',  xl:'none',  xxl: 'none',   xxxl:'none',   xxxxl:'none'}}  sx={{backgroundColor:'secondary.dark',  color:'primary.contrastText'}}>mobile small</Box>
		<Box display={{mobileSm: 'none',  mobile: 'block',  xs: 'none', sm: 'none',  md:'none',  lg: 'none',  xl:'none',  xxl: 'none',   xxxl:'none',   xxxxl:'none'}}  sx={{backgroundColor:'secondary.dark',  color:'primary.contrastText'}}>mobile </Box> */}
		<Box display={{mobileSm: 'none',  mobile: 'none',  xs: 'block', sm: 'none',  md:'none',  lg: 'none',  xl:'none',  xxl: 'none',   xxxl:'none',   xxxxl:'none'}}  sx={{backgroundColor:'primary.dark',    color:'primary.contrastText'}}>xs </Box>
		<Box display={{mobileSm: 'none',  mobile: 'none',  xs: 'none',  sm: 'block', md:'none',  lg: 'none',  xl:'none',  xxl: 'none',   xxxl:'none',   xxxxl:'none'}}  sx={{backgroundColor:'primary.main',    color:'primary.contrastText'}}>sm </Box>
		<Box display={{mobileSm: 'none',  mobile: 'none',  xs: 'none',  sm: 'none',  md:'block', lg: 'none',  xl:'none',  xxl: 'none',   xxxl:'none',   xxxxl:'none'}}  sx={{backgroundColor:'primary.light' }}>md </Box>
		<Box display={{mobileSm: 'none',  mobile: 'none',  xs: 'none',  sm: 'none',  md:'none',  lg: 'block', xl:'none',  xxl: 'none',   xxxl:'none',   xxxxl:'none'}}  sx={{backgroundColor:'secondary.dark',  color:'primary.contrastText'}}>lg </Box>
		<Box display={{mobileSm: 'none',  mobile: 'none',  xs: 'none',  sm: 'none',  md:'none',  lg: 'none',  xl:'block', xxl: 'none',   xxxl:'none',   xxxxl:'none'}}  sx={{backgroundColor:'secondary.main',  color:'primary.contrastText'}}>xl </Box>
		<Box display={{mobileSm: 'none',  mobile: 'none',  xs: 'none',  sm: 'none',  md:'none',  lg: 'none',  xl:'none',  xxl: 'block',  xxxl:'none',   xxxxl:'none'}}  sx={{backgroundColor:'secondary.light', color:'secondary.contrastText'}}>2xl </Box>
		<Box display={{mobileSm: 'none',  mobile: 'none',  xs: 'none',  sm: 'none',  md:'none',  lg: 'none',  xl:'none',  xxl: 'none',   xxxl:'block',  xxxxl:'none'}}  sx={{backgroundColor:'tertiary.dark',   color:'primary.contrastText'}}>3xl </Box>
		<Box display={{mobileSm: 'none',  mobile: 'none',  xs: 'none',  sm: 'none',  md:'none',  lg: 'none',  xl:'none',  xxl: 'none',   xxxl:'none',   xxxxl:'block'}} sx={{backgroundColor:'tertiary.main',   color:'primary.contrastText'}}>4xl </Box>
	</ThemeProvider>
  );
}


