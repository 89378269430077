import React, {Component} from 'react';
import {Stack, Typography} from '@mui/material';
import {FormTextField} from '../../common/Form/FormTextField';

class ErrorLogDetails extends Component {
    render() {
        const {data} = this.props;
        const {description, firstName, lastName, email} = data || {};

        return (
            <Stack
                alignItems={'center'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}
            >
                <Typography component="h3" variant="h3">
                    Details
                </Typography>
                {[
                    {label: 'First Name', value: firstName},
                    {label: 'Last Name', value: lastName},
                    {label: 'Email', value: email},
                    {label: 'Description', value: description}
                ].map(field => (
                    <FormTextField
                        label={field.label}
                        value={field.value}
                        onChange={() => null}
                    />
                ))}
            </Stack>
        );
    }
}

export default ErrorLogDetails;
