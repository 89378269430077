import React, {Component} from 'react';
import {Stack, Box, Toolbar, Drawer, Button, Autocomplete, TextField} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MobileNavToggler from '../Navigation/Toggler/MobileNavToggler';
import DevButton from '../../DevButton';
import UserProfileButton from '../../UserProfileButton';
import Branding from '../Navigation/Branding';
import Navigation from '../Navigation';
import TogglePreviewButton from '../../common/TogglePreviewButton';
import Search from './Search';

class PortalAppBar extends Component {
    state = {
        isSearchOnFocus: false,
    };

    toggleFocus = () =>{
        this.setState({isSearchOnFocus: !this.state.isSearchOnFocus});
    };

    render() {
        return (
            <MuiAppBar
                className={
                    'myOdu__topNav ' +
                    (this.props.isSideBarOpen
                        ? 'sideBarVisible open '
                        : 'sidebarVisible closed ')
                }
                position="fixed"
                open={this.props.isSidebarOpen}
                elevation={0}
            >
                <div
                    className="myOdu__decorativeLine navbarArea"
                    style={{width: '100%'}}
                >
                    {' '}
                    &nbsp;{' '}
                </div>
                <Toolbar>
                    <Branding isPositionTopNav={true} isCrownVisible={false} isTitleVisible={!this.state.isSearchOnFocus} />
                    <Box sx={{flexGrow: 1}} />
                    <Search isMobile={this.props.isMobile} isSearchOnFocus={this.state.isSearchOnFocus} toggleFocus={this.toggleFocus} />

                    {/* //NOTE: this button hides with css on <= small screen */}
                    {this.props.isAdmin && <DevButton />}
                    
                    {/* <TogglePreviewButton /> */}

                    {/* //NOTE: this button hides with css on <= small screen */}
                    <UserProfileButton />

                    <MobileNavToggler
                        isMenuOpen={this.props.isSidebarOpen}
                        onToggle={this.props.onToggle}
                    />
                  
                </Toolbar>

                {this.props.isMobile && (
                    <Drawer
                        id="mobileNav__drawer"
                        variant="temporary"
                        className={
                            'myOdu__mobileNavDrawer d-block d-sm-none ' +
                            (this.props.isImpersonating ? ' impersonating' : '')
                        }
                        anchor={'top'}
                        open={this.props.isSidebarOpen}
                        onClose={this.props.onToggle}
                    >
                        <MobileNavToggler
                            isMenuOpen={this.props.isSidebarOpen}
                            onToggle={this.props.onToggle}
                        />
                        <Navigation
                            isMobile={true}
                            data={{isOpen: this.props.isSidebarOpen}}
                            onToggle={this.props.onToggle}
                        />
                    </Drawer>
                )}
            </MuiAppBar>
        );
    }
}

export default PortalAppBar;
