import React, {Component, useEffect, useState} from "react";
import { Col, Row } from "reactstrap";
import ApplicationCard from "../common/ApplicationCard";
import { ICON_PATH, MYAPPS_CENTER_PANEL, MYAPPS_LEFT_PANEL, MYAPPS_RIGHT_PANEL } from "../common/constants";
import { Box, Button, Grid } from "@mui/material";
import { repeat } from "lodash";
import {DndContext, DragOverlay, closestCenter} from '@dnd-kit/core';
import {SortableContext, rectSortingStrategy, arrayMove, arraySwap, rectSwappingStrategy} from '@dnd-kit/sortable';
import {restrictToWindowEdges, snapCenterToCursor} from '@dnd-kit/modifiers';
import Droppable from './Droppable';
import DNDContext from "./DNDContext";
import SortableItem from "./SortableItem";


class AppsPanel extends Component {

    state = {
        isDragging: false,
        selectedApp: {}
    }

    //Θ(1) Toggles isDragging
    toggleIsDragging = (isDragging = !this.state.isDragging) => {
        this.setState({
            isDragging
        })
    }

    //Θ(1) Sets the app being dragged
    setSelectedApp = (selectedApp = {}) => {
        this.setState({
            selectedApp
        })
    }

    //Θ(1) Sets the app being dragged and isdragging when dragging starts
    onDragStart = (result) => {
        const selectedApp = this.props.apps.filter(app => app.id === result.active.id)[0];
        this.toggleIsDragging(true);
        this.setSelectedApp(selectedApp);
    }

    //Θ(1) Resets the app being dragged and isdragging when dragging starts
    onDragEnd = (result) => {
        this.toggleIsDragging(false);
        this.setSelectedApp();
        this.props.onDragEnd(result)
    }

    render() {

        let apps = this.props.apps;

        //The DND requires the app being dragged in the list of items. Hence we add the app if we move to new panel where app is not already present
        if(!apps.some(app => app.id === this.state?.selectedApp?.id) && this.state.isDragging)
            apps = [this.state.selectedApp, ...apps]

        return <DNDContext 
            onDragEnd={this.onDragEnd} 
            onDragStart={this.onDragStart} 
            onDragOver={this.props.onDragUpdate}
            modifiers={[snapCenterToCursor]}
        >
            <Row
                style={{height: '100%'}}
                className={this.props.className}
            >
                {/* Left panel mover droppable */}
                {
                    this.state.isDragging && <Col xs={1} style={{padding: 0}}>
                        <Droppable id={MYAPPS_LEFT_PANEL}/>
                    </Col>
                }

                <Col className="px-xl-0 pb-0">
                    <Grid container>
                        <SortableContext items={apps.map(app => app.id)} id={MYAPPS_CENTER_PANEL}>
                            {
                                apps.map((app, idx) => {
                                    const config = app
                                    return <Grid item hidden={apps.length > this.props.apps.length && idx === 0} xs={4} className="mb-2 px-1">
                                        <SortableItem 
                                            key={app.id} 
                                            id={app.id} 
                                            app={app} 
                                            items = {apps} 
                                            isDragging = {this.state.isDragging} 
                                            selectedApp = {this.state.selectedApp}   
                                            isReorderApps = {this.props.isReorderApps}
                                        />
                                    </Grid>
                                })
                            }
                            
                        </SortableContext>
                    </Grid>
                </Col>

                {/* Right panel mover droppable */}
                {
                    this.state.isDragging && <Col xs={1} style={{padding: 0}}>
                        <Droppable id={MYAPPS_RIGHT_PANEL} onDragUpdate={this.props.onDragUpdate} />
                    </Col>
                }
            </Row>

            {/* Overlay that gets displayed when we drag an app */}
            <DragOverlay dropAnimation={{
                duration: 500,
                easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
            }}>
                <ApplicationCard 
                    widgetId="myApps" 
                    app={this.state.selectedApp} 
                    data={{icon: ICON_PATH + this.state.selectedApp?.icon}} 
                    isActive={true} 
                    showHeart={false} 
                    showInfo={false} 
                    showLinks={false} 
                    showReorder={this.props.isReorderApps}   
                    isMyApps={this.props.isMyApps} 
                />
            </DragOverlay>
        </DNDContext>
    }
}

export default AppsPanel;
