import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SharedDriveIcon() {
  return (
    <SvgIcon>
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.15 300.12" style={{width: 30}}>
        <defs>
          <style>
            {/* .cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;} */}
          </style>
        </defs>
        <path class="cls-1" fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth="5" d="M9,219.83l42.55-118.2c2.97-8.25,10.79-13.74,19.55-13.74h210.49c4.01,0,7.81,1.95,10.13,5.28,2.32,3.32,2.9,7.55,1.53,11.35l-36.54,101.57c-2.97,8.25-10.79,13.75-19.55,13.75H9V29.83c0-11.66,9.45-21.11,21.11-21.11h38.74c5.6,0,10.98,2.22,14.94,6.17l24.59,24.59c3.96,3.96,9.34,6.17,14.94,6.17h107.66c11.51,0,20.8,9.29,20.8,20.79v21.43"/>
        <rect class="cls-1" fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth="5" x="119.82" y="262.05" width="63.33" height="31.67"/>
        <line class="cls-1" fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth="5" x1="151.49" y1="262.05" x2="151.49" y2="219.83"/>
        <line class="cls-1" fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth="5" x1="183.15" y1="277.88" x2="293.98" y2="277.88"/>
        <line class="cls-1" fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth="5" x1="119.82" y1="277.88" x2="9" y2="277.88"/>
      </svg>
    </SvgIcon>
  );
}