import {SAVE_CHECKLISTS} from './actions';
import {SAVE_CHECKLISTS_IS_ERROR} from './actions';

const initialStateChecklists = {
    checklists: null,
    isError: false
};

// Persists checklists across pages
export const myChecklistsReducer = (state = initialStateChecklists, action) => {
    const {type, payload} = action;
    switch (type) {
        case SAVE_CHECKLISTS: {
            return {
                ...state,
                checklists: payload.checklists,
                isError: false
            };
        }
        case SAVE_CHECKLISTS_IS_ERROR: {
            return {...state, isError: payload.isError};
        }
        default:
            return state;
    }
};
