import React, {Component} from 'react';
import {DataGrid, GridOverlay} from '@mui/x-data-grid';
import MeetingActions from './MeetingActions';
import moment from 'moment';
import {connect} from 'react-redux';
import {Box, Grid, LinearProgress, Link, Typography} from '@mui/material';
import {toast} from 'react-toastify';
import {
    deleteData,
    getData,
    patchData
} from '../../../../../../DataAccessLayer';
import {
    addAltHostUpdate,
    cct,
    enableGuest,
    joinBeforeHostUpdate
} from '../../../../../../DataAccessLayer/services';
import {ZoomTabMapping} from '../config';
import {getColumns} from './columns';
import PortalModal from '../../../../../common/PortalModal';
import {FormTextField} from '../../../../../common/Form/FormTextField';
import PortalSnackbar from '../../../../../common/PortalSnackbar';
import AddAlternativeHosts from './AddAlternativeHosts';
import {FormCheckbox} from '../../../../../common/Form/FormCheckbox';
import {Time} from '../../../../../common/MomentTime';
import PortalTime from '../../../../../common/PortalTime';
import {saveZoomMeetings} from '../../../actions';
import {getUserEmail} from '../ScheduleMeeting/utils';
import {addHosts} from '../../../utils';

class Meetings extends Component {
    state = {
        columns: [],
        isAddAltHostDisplay: false,
        altHostEmails: '',
        meetingId: '',
        isDeletePopup: false,
        isDeleteShared: false,
        isDeleteSingle: false,
        meetingToDelete: {},
        snackbar: {
            message: '',
            success: '',
            isDisplay: false
        },
        altHosts: []
    };

    toggleIsDeletePopup = () => {
        this.setState({
            isDeletePopup: !this.state.isDeletePopup
        });
    };

    toggleIsDeleteSingle = () => {
        this.setState({
            isDeleteSingle: !this.state.isDeleteSingle
        });
    };

    //Θ(1) deletes all zoom meetings
    onDeleteAll = () => {
        this.props.toggleIsLoading(true);
        let crns = this.props.course.CRNS.split(',');
        let url =
            cct +
            '/' +
            crns[0] +
            '/' +
            this.props.course.SSBSECT_TERM_CODE +
            '?isDeleteShared=' +
            this.state.isDeleteShared;
        deleteData(url, true)
            .then(result => {
                if (!result) toast.error('Unable to Delete Meetings');
                else {
                    toast.success('Meetings Deleted!');
                    this.props.loadMeetings();
                }
                this.props.toggleIsLoading(false);
                this.toggleIsDeletePopup();
            })
            .catch(err => {
                this.props.toggleIsLoading(false);
                console.log(err);
            });
    };

    onDeleteMeeting = meeting => {
        this.setState({
            meetingToDelete: meeting,
            isDeleteSingle: true
        });
    };

    deleteMeeting = meeting => {
        this.props.toggleIsLoading(true);
        let crns = this.props.course.CRNS.split(',');
        let url =
            cct +
            '/' +
            crns[0] +
            '/' +
            this.props.course.SSBSECT_TERM_CODE +
            '/' +
            meeting.id;
        url =
            url +
            (meeting?.occurrence_id
                ? '?occurenceId=' + meeting.occurrence_id
                : '');
        deleteData(url, true)
            .then(result => {
                if (!result) toast.error('Unable to Delete Meeting');
                else {
                    toast.success('Meeting Deleted!');
                    this.props.loadMeetings();
                }
                this.props.toggleIsLoading(false);
                this.toggleIsDeleteSingle();
                this.setState({
                    meetingToDelete: {}
                });
            })
            .catch(err => {
                this.props.toggleIsLoading(false);
                console.log(err);
            });
    };

    //Θ(1) enables/disables guest speaker of the meeting
    onEnableGuestSpeaker = meetingId => {
        this.props.toggleIsLoading(true);

        let crns = this.props.course.CRNS.split(',');

        let body = {
            crn: crns[0],
            termCode: this.props.course.SSBSECT_TERM_CODE,
            meetingId
        };

        patchData(enableGuest, body, true)
            .then(result => {
                if (!result) toast.error('Enabling Guest Speaker Failed');
                else {
                    toast.success('Guest Speaker Enabled Successfully!');
                    this.props.loadMeetings();
                }
                this.props.toggleIsLoading(false);
            })
            .catch(err => {
                this.props.toggleIsLoading(false);
                toast.error('Enabling Guest Speaker Failed');
                console.log(err);
            });
    };

    //Θ(1) enables join before host of the meeting
    onJoinBeforeHost = (meetingId, joinBeforeHost) => {
        this.props.toggleIsLoading(true);

        let crns = this.props.course.CRNS.split(',');

        let body = {
            joinBeforeHost,
            crn: crns[0],
            termCode: this.props.course.SSBSECT_TERM_CODE,
            meetingId
        };

        patchData(joinBeforeHostUpdate, body, true)
            .then(result => {
                if (!result) toast.error('Unable to Update Join Before Host');
                else {
                    toast.success(
                        'Join before host ' +
                            (joinBeforeHost ? 'enabled!' : 'disabled!')
                    );
                    this.props.loadMeetings();
                }
                this.props.toggleIsLoading(false);
            })
            .catch(err => {
                this.props.toggleIsLoading(false);
                toast.error('Unable to Update Join Before Host');
                console.log(err);
            });
    };

    //Θ(1) Sets altHosts
    onChangeAltHosts = altHosts => {
        this.setState({
            altHosts
        });
    };

    //Θ(1) adds alternative host in the meeting
    addAltHost = (email, meetingDetails) => {
        this.props.toggleIsLoading(true);

        meetingDetails = meetingDetails || this.state.meetingDetails;

        const isEditMode =
            meetingDetails?.settings?.alternative_hosts?.length > 0;

        addHosts(email, meetingDetails, this.props.course)
            .then(result => {
                toast.success(
                    !isEditMode ? 'Alt Hosts Added!' : 'Alt Hosts Updated!'
                );
                this.props.loadMeetings();
                this.props.toggleIsLoading(false);
                this.toggleIsAddAltHostDisplay();
            })
            .catch(err => {
                this.props.toggleIsLoading(false);
                toast.error(
                    !isEditMode
                        ? 'Unable to Add Alternative Host(s)'
                        : 'Unable to Update Alternative Host(s)'
                );
                console.log(err);
            });
    };

    componentDidMount() {
        if (this.props.activeTab === ZoomTabMapping.currentMeeting)
            this.props.loadMeetings();

        let props = {
            activeTab: this.props.activeTab,
            onEnableGuestSpeaker: this.onEnableGuestSpeaker,
            onJoinBeforeHost: this.onJoinBeforeHost,
            onAddAltHost: this.onAddAltHost,
            openSnackbar: this.openSnackbar,
            onDeleteMeeting: this.onDeleteMeeting,
            course: this.props.course,
            isLastSemCourse: this.props.isLastSemCourse,
            getShortTopic: this.getShortTopic,
            email: getUserEmail(this.props.user, this.props.impersonation)
        };
        this.setState({
            columns: getColumns(
                props,
                this.props.activeTab === ZoomTabMapping.currentMeeting
            )
        });
    }

    toggleIsAddAltHostDisplay = () => {
        this.setState({
            isAddAltHostDisplay: !this.state.isAddAltHostDisplay
        });
    };

    //Θ(1) Sets the meeting for which alt host is to be added
    onAddAltHost = meetingDetails => {
        const altHosts = meetingDetails?.settings?.alternative_hosts
            ?.split(';')
            ?.filter(host => host !== '');
        this.toggleIsAddAltHostDisplay();
        this.setState({
            meetingDetails,
            altHosts
        });
    };

    //Θ(1) returns the row id for each meeting
    getRowId = row => {
        let id =
            row.id +
            (row?.queued !== undefined
                ? row.start_time
                : row.occurrence_id !== undefined
                ? row.occurrence_id
                : '');
        return id;
    };

    //Θ(1) updates the textfield value
    onTextFieldUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    //Θ(1) Opens Snackbar
    openSnackbar = (message, success) => {
        this.setState({
            snackbar: {
                isDisplay: true,
                message,
                success
            }
        });
    };

    //Θ(1) Closes snackbar
    onCloseSnackbar = () => {
        this.setState({
            snackbar: {
                isDisplay: false,
                message: '',
                success: ''
            }
        });
    };

    //Θ(N) where N is the length of the zoom meetings
    //Returns the matched meeting
    filterMeeting = meetingDetails => {
        let meeting = this.props?.meetings?.find(
            meeting => meeting?.id === meetingDetails?.id
        );
        return meeting;
    };

    onCheckBoxUpdate = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    //Θ(N) where N is the length of course topic
    //Splits the course topic and returns the topic to be displayed.
    getShortTopic = meeting => {
        if (!meeting?.topic || !meeting?.topic?.length) {
            return 'Meeting';
        }

        if (!meeting.isAdhoc && meeting?.topic) {
            const [_, subject, courseNumber] = meeting.topic.split('_');
            return subject + ' ' + courseNumber + ' Class Meeting';
        }

        let meetingTitle = meeting.topic.split('_').slice(4).join(' ');

        return meetingTitle.length ? meetingTitle : meeting.topic;
    };

    //Θ(1) Returns the Delete Message
    getDeleteSingleMeetingDetails = meeting => {
        const meetingTitle = this.getShortTopic(meeting);
        const startTime = Time.University(meeting.start_time).format('h:mm A');
        const endTime = Time.University(meeting.start_time)
            .add(meeting.duration, 'minutes')
            .format('h:mm A');
        const meetingDate = Time.University(meeting.start_time).format(
            'MMMM D'
        );

        return (
            'Are you sure you want to delete ' +
            meetingTitle +
            ' on ' +
            meetingDate +
            ' from'
        );
    };

    render() {
        const isSharedMeetings =
            this.props.meetings.filter(meeting => meeting.isShared).length > 0;
        const subjectCode = this.props.course.SUBJECT_CODE;
        const courseNumber = this.props.course.COURSE_NUMBER;
        const isTeaching = this.props.course.isTeaching;
        const isTaking = this.props.course.isTaking;
        const isClassScheduled = this.props?.course?.isClassScheduled;

        return (
            <React.Fragment>
                <PortalSnackbar
                    isOpen={this.state.snackbar.isDisplay}
                    onClose={this.onCloseSnackbar}
                    message={this.state.snackbar.message}
                    success={this.state.snackbar.success}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                />

                <PortalModal
                    maxWidth="md"
                    onPrimaryClick={this.onDeleteAll}
                    onClose={this.toggleIsDeletePopup}
                    isOpen={this.state.isDeletePopup}
                    title="Delete All Meetings"
                    id="addAltHostModal"
                    primaryButtonTitle="Yes"
                    isPrimaryButtonDisabled={this.props.isLoading}
                    isCloseable={true}
                    secondaryButtonTitle="No, Cancel"
                    onSecondaryClick={this.toggleIsDeletePopup}
                >
                    <Typography component="p">
                        Do you wish to delete all meetings for this course
                    </Typography>
                    {isSharedMeetings && (
                        <FormCheckbox
                            value={this.state.isDeleteShared}
                            onChange={this.onCheckBoxUpdate}
                            name="isDeleteShared"
                            label="Delete all the Shared meetings"
                            labelGridProps={{xs: 0}}
                        />
                    )}
                </PortalModal>

                <PortalModal
                    maxWidth="md"
                    onPrimaryClick={() => {
                        this.deleteMeeting(this.state.meetingToDelete);
                    }}
                    onClose={this.toggleIsDeleteSingle}
                    isOpen={this.state.isDeleteSingle}
                    title="Delete Meeting"
                    id="deleteSingleMeeting"
                    primaryButtonTitle="Yes"
                    isPrimaryButtonDisabled={this.props.isLoading}
                    isCloseable={true}
                    secondaryButtonTitle="No, Cancel"
                    onSecondaryClick={this.toggleIsDeleteSingle}
                >
                    <Typography component="p">
                        {
                            <Grid container direction={'row'}>
                                {this.getDeleteSingleMeetingDetails(
                                    this.state.meetingToDelete
                                )}
                                &nbsp;
                                <PortalTime
                                    time={Time.University(
                                        this.state.meetingToDelete.start_time
                                    ).format('h:mm A')}
                                    title={
                                        Time.University(
                                            this.state.meetingToDelete
                                                .start_time
                                        ).format('h:mm A') +
                                        ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'
                                    }
                                />
                                &nbsp; to &nbsp;
                                <PortalTime
                                    time={Time.University(
                                        this.state.meetingToDelete.start_time
                                    )
                                        .add(
                                            this.state.meetingToDelete.duration,
                                            'minutes'
                                        )
                                        .format('h:mm A')}
                                    title={
                                        Time.University(
                                            this.state.meetingToDelete
                                                .start_time
                                        )
                                            .add(
                                                this.state.meetingToDelete
                                                    .duration,
                                                'minutes'
                                            )
                                            .format('h:mm A') +
                                        ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'
                                    }
                                />
                                ?
                            </Grid>
                        }
                    </Typography>
                </PortalModal>

                {this.state?.meetingDetails && (
                    <AddAlternativeHosts
                        altHosts={this.state.altHosts}
                        addAltHost={this.addAltHost}
                        toggleIsAddAltHostDisplay={
                            this.toggleIsAddAltHostDisplay
                        }
                        isAddAltHostDisplay={this.state.isAddAltHostDisplay}
                        meeting={this.filterMeeting(this.state.meetingDetails)}
                        isLoading={this.props.isLoading}
                        onChangeAltHosts={this.onChangeAltHosts}
                    />
                )}
                <Box sx={{width: '100%'}}>
                    <DataGrid
                        sx={{mt: 4}}
                        style={{width: '100%'}}
                        columns={this.state.columns}
                        rows={this.props.meetings}
                        loading={this.props.isLoading}
                        initialState={{
                            pagination: {paginationModel: {pageSize: 10}}
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        slots={{
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () => {
                                return (
                                    <Box sx={{marginLeft: 1}}>
                                        {isTeaching &&
                                            !isTaking &&
                                            !this.props.isLastSemCourse &&
                                            !this.props.isPastMeetings && (
                                                <Typography
                                                    variant="p"
                                                    component="p"
                                                    style={{margin: 4}}
                                                >
                                                    {'There are no ' +
                                                        ((this.props
                                                            .isPastMeetings &&
                                                            'past ') ||
                                                            '') +
                                                        'meetings found. Schedule '}
                                                    {isClassScheduled &&
                                                        !this.props
                                                            .isClassMeetingsScheduled && (
                                                            <Link
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={
                                                                    this.props
                                                                        .openClassSchedule
                                                                }
                                                            >
                                                                {subjectCode +
                                                                    courseNumber +
                                                                    ' Class Meetings'}
                                                            </Link>
                                                        )}
                                                    {isClassScheduled &&
                                                        !this.props
                                                            .isClassMeetingsScheduled &&
                                                        ' or '}
                                                    <Link
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={
                                                            this.props
                                                                .openAdhocSchedule
                                                        }
                                                    >
                                                        {'Adhoc Meeting(s)'}
                                                    </Link>
                                                </Typography>
                                            )}
                                        {isTeaching &&
                                            !isTaking &&
                                            this.props.isLastSemCourse &&
                                            !this.props.isPastMeetings && (
                                                <Typography
                                                    variant="p"
                                                    component="p"
                                                    style={{margin: 4}}
                                                >
                                                    {
                                                        'There are no meetings found.'
                                                    }
                                                </Typography>
                                            )}
                                        {!isTeaching &&
                                            isTaking &&
                                            !this.props.isPastMeetings && (
                                                <Typography
                                                    variant="p"
                                                    component="p"
                                                    style={{margin: 4}}
                                                >
                                                    {
                                                        'There are no meetings found.'
                                                    }
                                                </Typography>
                                            )}
                                        {this.props.isPastMeetings && (
                                            <Typography
                                                variant="p"
                                                component="p"
                                                style={{margin: 4}}
                                            >
                                                {'No past meetings found.'}
                                            </Typography>
                                        )}
                                    </Box>
                                );
                            }
                        }}
                        getRowId={this.getRowId}
                        autoHeight
                    />
                </Box>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course,
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({
    saveZoomMeetings: meetings => dispatch(saveZoomMeetings(meetings))
});

export default connect(mapStateToProps, mapDispatchToProps)(Meetings);
