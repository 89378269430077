import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function TeamsIcon() {
  return (
    <SvgIcon>
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.15 300.12" style={{width: 30}}>
        <defs>
          <style>
            {/* .cls-1{fill:#000;stroke-width:0px;}.cls-2{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:4.94px;} */}
          </style>
        </defs>
        <path class="cls-2" fill='none' stroke='#000' strokeMiterlimit={10} strokeWidth={4.94} d="M282.87,122.47h-60.13c6.98.17,12.5,5.96,12.34,12.94v77.67c.27,11.69-2.12,22.83-6.61,32.83,6.06,2.85,12.83,4.45,19.97,4.45h.22c25.96,0,47-21.04,47-47v-68.09c0-7.06-5.73-12.79-12.79-12.79Z"/>
        <circle class="cls-2" fill='none' stroke='#000' strokeMiterlimit={10} strokeWidth={4.94} cx="257.98" cy="78.72" r="30.29"/>
        <path class="cls-2" fill='none' stroke='#000' strokeMiterlimit={10} strokeWidth={4.94} d="M154.31,94.43v13.4c3.24.77,6.62,1.18,10.1,1.18,24.16,0,43.75-19.59,43.75-43.75s-19.59-43.75-43.75-43.75-43.75,19.59-43.75,43.75c0,5.96,1.2,11.65,3.36,16.83h17.96c6.81,0,12.34,5.52,12.34,12.34Z"/>
        <path class="cls-2" fill='none' stroke='#000' strokeMiterlimit={10} strokeWidth={4.94} d="M208.11,122.47h-53.8v95.35c0,6.81-5.52,12.34-12.34,12.34h-53.42c6.94,33.86,36.47,59.7,72.49,60.58,30.19-.74,55.83-19.01,67.43-44.83"/>
        <path class="cls-2" fill='none' stroke='#000' strokeMiterlimit={10} strokeWidth={4.94} d="M228.46,245.91c4.49-10,6.88-21.14,6.61-32.83v-77.67c.16-6.98-5.36-12.77-12.34-12.94h-14.63"/>
        <path class="cls-2" fill='none' stroke='#000' strokeMiterlimit={10} strokeWidth={4.94} d="M124.01,82.09H18.57c-6.81,0-12.34,5.52-12.34,12.34v123.4c0,6.81,5.52,12.34,12.34,12.34h69.98"/>
        <path class="cls-2" fill='none' stroke='#000' strokeMiterlimit={10} strokeWidth={4.94} d="M154.31,122.47v-14.65"/>
        <polygon class="cls-1" fill='#000' strokeWidth={0} points="45.5 132.01 70.06 132.01 70.06 199.19 85.77 199.19 85.77 132.01 110.44 132.01 110.44 118.98 45.5 118.98 45.5 132.01"/>
      </svg>
    </SvgIcon>
  );
}