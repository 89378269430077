import React, {Component} from 'react';
import {Box, Typography, Paper, Grid} from '@mui/material';
import ErrorLogTable from './CognitoErrorLogTable';
import getColumns from './columns';
import {cognitoErrors} from '../../../DataAccessLayer/services';
import {getData} from '../../../DataAccessLayer';
import ErrorLogDetails from './CognitoErrorLogDetails';
import {GridToolbar} from '@mui/x-data-grid';

class ErrorLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorLogData: [],
            errorLogMap: {},
            isLoading: false,
            selectedErrorLog: null
        };
    }

    componentDidMount() {
        this.loadAllErrorLog();
    }

    loadAllErrorLog = async () => {
        this.setState({isLoading: true});
        try {
            const data = await getData(cognitoErrors, true);

            // Create a mapping of Error Log data for quick lookup
            const errorLogMap = data.reduce((acc, item) => {
                acc[item.STATE] = item;
                return acc;
            }, {});

            this.setState({errorLogData: data, errorLogMap});
        } catch (err) {
            console.error('Failed to load Error Logs:', err);
        } finally {
            this.setState({isLoading: false});
        }
    };

    getSpecificErrorLog = async ({row}) => {
        const {MIDAS: midas, CREATED_AT: createdAt, STATE: state} = row;

        const key = state;
        const data = this.state.errorLogMap[key];

        if (data) {
            this.setState({
                selectedErrorLog: {
                    midas: data.MIDAS,
                    createdAt: data.TIMESTAMP,
                    error: data.ERROR,
                    description: data.DESCRIPTION,
                    firstName: data.FIRST_NAME,
                    lastName: data.LAST_NAME,
                    email: data.EMAIL
                }
            });
        }
    };

    render() {
        const {errorLogData, isLoading, selectedErrorLog} = this.state;

        return (
            <div
                className="myOdu__container_maxWidth"
                style={{marginTop: '2rem'}}
            >
                <Typography component="h2" variant="h4" gutterBottom>
                    Error Log
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} xl={6}>
                        <ErrorLogTable
                            rows={errorLogData}
                            columns={getColumns()}
                            isLoading={isLoading}
                            onClick={this.getSpecificErrorLog}
                            slots={{toolbar: GridToolbar}}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    csvOptions: {disableToolbarButton: true},
                                    printOptions: {disableToolbarButton: true},
                                    quickFilterProps: {
                                        sx: {m: 1, width: '100%'},
                                        variant: 'outlined',
                                        size: 'small',
                                        placeholder: 'Search Error Log'
                                    }
                                }
                            }}
                            columnVisibilityModel={{
                                DESCRIPTION: false,
                                FIRST_NAME: false,
                                LAST_NAME: false,
                                EMAIL: false
                            }}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                        />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        <Paper
                            sx={{
                                width: '100%',
                                overflow: 'hidden',
                                height: '100%',
                                p: 4
                            }}
                        >
                            <ErrorLogDetails data={selectedErrorLog} />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default ErrorLog;
