import moment from "moment-timezone";
import { TIME_ZONE } from "./constants";

export const Time = {
    University: (time = undefined) => time && moment(time).tz(TIME_ZONE) || moment().tz(TIME_ZONE),
    Local: (time = undefined) => time && moment(time) || moment()
}

// export const UniversityTime = {
//     get: (time = undefined) => time && moment(time).tz(TIME_ZONE) || moment().tz(TIME_ZONE)
// }

// export const LocalTime = {
//     get: (time = undefined) => time && moment(time) || moment()
// }