import React, {Component} from 'react';
import {Tabs, Tab, Box} from '@mui/material';
import TabPanel from '../common/TabPanel';
import DetailsContent from './DetailsContent';
import {CHECKLIST_YES_INDICATOR} from '../common/constants';
import {FormDropDown} from '../common/Form/FormDropdown';
import {WithSmallScreen} from '../common/WithSmallScreen';

class DetailsTabs extends Component {
    state = {
        tabSelected: 0,
        requiredItems: [],
        optionalItems: []
    };

    componentDidMount() {
        const {requiredItems, optionalItems} = this.props.items.reduce(
            ({requiredItems, optionalItems}, item) => {
                if (item.itemRequiredInd === CHECKLIST_YES_INDICATOR) {
                    requiredItems.push(item);
                } else {
                    optionalItems.push(item);
                }
                return {requiredItems, optionalItems};
            },
            {requiredItems: [], optionalItems: []}
        );
        this.setState({
            requiredItems,
            optionalItems
        });
    }

    onChange = (event, value) => {
        this.setState({tabSelected: value});
    };

    render() {
        return (
            <>
                <Box sx={{width: '100%', pb: 1}}>
                    {this.props.isSmall ? (
                        <FormDropDown
                            options={['All Items', 'Required', 'Optional'].map(
                                (term, index) => ({
                                    value: index,
                                    label: term
                                })
                            )}
                            value={this.state.tabSelected}
                            onChange={e => this.onChange(null, e.target.value)}
                            ariaLabel={'Choose items'}
                            id="checklist__dropdown_mobilePickItems"
                        />
                    ) : (
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs
                                className="myOdu__tabs"
                                value={this.state.tabSelected}
                                onChange={this.onChange}
                                aria-label="Checklists Details Tabs"
                            >
                                <Tab
                                    label="All Items"
                                    id="checklists__tab_all_items"
                                    aria-controls={
                                        'checklists__tabpanel_all_items'
                                    }
                                />
                                <Tab
                                    label="Required"
                                    id="checklists__tab_required"
                                    aria-controls={
                                        'checklists__tabpanel_required'
                                    }
                                />
                                <Tab
                                    label="Optional"
                                    id="checklists__tab_optional"
                                    aria-controls={
                                        'checklists__tabpanel_optional'
                                    }
                                />
                            </Tabs>
                        </Box>
                    )}
                </Box>

                <TabPanel
                    value={this.state.tabSelected}
                    sx={{pt: 1, px: 0}}
                    index={0}
                    id="checklists__tabpanel_all_items"
                    lablledby="checklists__tab_all_items"
                >
                    <DetailsContent
                        items={this.props.items}
                        isLoading={this.props.isLoading}
                        checklistTitle={this.props.checklistTitle}
                        tabSelected={this.state.tabSelected}
                    />
                </TabPanel>

                <TabPanel
                    value={this.state.tabSelected}
                    sx={{pt: 1, px: 0}}
                    index={1}
                    id="checklists__tabpanel_required"
                    lablledby="checklists__tab_required"
                >
                    <DetailsContent
                        items={this.state.requiredItems}
                        isLoading={this.props.isLoading}
                        checklistTitle={this.props.checklistTitle}
                        tabSelected={this.state.tabSelected}
                    />
                </TabPanel>

                <TabPanel
                    value={this.state.tabSelected}
                    sx={{pt: 1, px: 0}}
                    index={2}
                    id="checklists__tabpanel_optional"
                    lablledby="checklists__tab_optional"
                >
                    <DetailsContent
                        items={this.state.optionalItems}
                        isLoading={this.props.isLoading}
                        checklistTitle={this.props.checklistTitle}
                        tabSelected={this.state.tabSelected}
                    />
                </TabPanel>
            </>
        );
    }
}

export default WithSmallScreen(DetailsTabs);
