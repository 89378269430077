import React from 'react';
import {Button, Typography, styled} from '@mui/material';

export const FormButton = ({id, label, onClick, disabled, ...props}) => {
  return (
    <Button
      variant="outlined"
      aria-label={label}
      onClick={onClick}
      id={id}
      disabled={disabled}
      {...props}
    >
      {label}
    </Button>
  );
};
