import { Box, Grid, Typography } from '@mui/material';
import React, { Component } from 'react';
import AppCard from './AppCard';
import ApplicationCard from '../../../common/ApplicationCard';
import { ICON_PATH, MYAPPS_ITEMS_COUNT_ROW } from '../../../common/constants';
import { Col, Row } from 'reactstrap';
import SortableApp from './SortableApp';

class AppsPanel extends Component {
    state ={

    }

    render() {
        return <React.Fragment>
            <Box className="appsPanel" >
                <Grid container justifyContent={'space-between'} direction={'column'} alignItems={'center'} gap={1} xs={12} style={{height: '100%'}}>
                    <Grid item container xs={2} width={'100%'} padding={1} justifyContent={'flex-start'} alignItems={'flex-start'}>
                        {
                            this.props.apps.map(app => {
                                return <Grid item className="p-0" xs={12/MYAPPS_ITEMS_COUNT_ROW}>
                                    <SortableApp app = {app} id = {app.id} key={app.id} moveApp = {this.props.moveApp} getSideAppsIndexes = {this.props.getSideAppsIndexes} isDNDSelected = {this.props.isDNDSelected} />
                                </Grid>
                            })
                        }
                    </Grid>

                    <Grid item >
                        <Typography variant="h6" sx={{ wordBreak: 'break-word', width: '100%'}} className="appCardTitle">
                            Panel {this.props.panelNo}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    }
}

export default AppsPanel;