import React, {Component} from 'react';
import {
    Box,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon
} from '@mui/material';
import {
    faUser,
    faFileUser,
    faGear,
    faPersonToDoor
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {logOut} from '../../AWS';
import {connect} from 'react-redux';
import { saveUser } from '../../AWS/actions';
import { saveImpersonation } from '../Impersonation/actions';
import WithRouter from '../Layout/Router/WithRouter';
import PortalTooltip from '../common/PortalTooltip';

class UserProfileButton extends Component {
    state = {
        anchor: false
    };

    dropAnchor = event => {
        this.setState({anchor: event.currentTarget});
    };

    pullAnchor = () => {
        this.setState({anchor: null})
    }

    logOut = () => {
        this.props.saveUser({})
        this.props.saveImpersonation({})
        logOut()
    }

    render() {
        return (
            <Box className={"d-none d-sm-block"}>
                <PortalTooltip title={ 'Logged in' + (this.props.user ? ' as ' + this.props.user?.firstName + ' ' + this.props.user?.lastName : '') } >
                    <IconButton
                        id="topNav__button_userProfile"
                        size=""
                        edge="end"
                        color="inherit"
                        sx={{p: 3}}
                        aria-label="profile menu"
                        aria-haspopup="true"
                        onClick={this.dropAnchor}
                        className="myOdu__iconButton userProfile"
                    >
                        <FontAwesomeIcon icon={faUser} size="sm" />
                    </IconButton>
                </PortalTooltip>

                <Menu
                    anchorEl={this.state.anchor}
                    disableScrollLock={true}
                    id="account-menu"
                    open={this.state.anchor}
                    onClose={this.pullAnchor}
                    onClick={this.pullAnchor}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                >
                    <MenuItem
                        onClick={() => {
                            this.pullAnchor;
                            this.props.navigate('/myInfo');
                        }}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon
                                icon={faFileUser}
                                size="lg"
                                fixedWidth
                            />
                        </ListItemIcon>
                        My Info
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            this.props.navigate('/settings');
                            this.pullAnchor;
                        }}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon
                                icon={faGear}
                                size="lg"
                                fixedWidth
                            />
                        </ListItemIcon>
                        Settings
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            this.logOut()
                            this.pullAnchor;
                        }}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon
                                icon={faPersonToDoor}
                                size="lg"
                                fixedWidth
                            />
                        </ListItemIcon>
                        Sign Out
                    </MenuItem>
                </Menu>
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveUser: (user) => dispatch(saveUser(user)),
    saveImpersonation: (impersonation) => dispatch(saveImpersonation(impersonation))
  });
  

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(UserProfileButton));
