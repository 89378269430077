import {Typography, Link} from '@mui/material';
import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {getData, postData} from '../../../../../DataAccessLayer';
import {gisDrive} from '../../../../../DataAccessLayer/services';
import Loader from '../../../../common/Loader';
import {getCourseMetadata} from '../utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleExclamation, faLink} from '@fortawesome/pro-light-svg-icons';
import PortalSnackbar from '../../../../common/PortalSnackbar';
import {Container, Row, Col} from 'reactstrap';

class SharedDrive extends Component {
    state = {
        drive: {},
        isLoading: false,
        snackBar: {}
    };

    componentDidMount() {
        this.loadDrive();
    }

    loadDrive = async () => {
        const {termCode, crn} = getCourseMetadata(this.props.course);
        this.setState({isLoading: true});

        try {
            const data = await getData(gisDrive.get({crn, termCode}), true);

            this.setState({drive: data});
        } catch (err) {
            this.setState({drive: {}});
        } finally {
            this.setState({isLoading: false});
        }
    };
    createDrive = async () => {
        const {termCode, crn, subjectCode, courseNumber} = getCourseMetadata(
            this.props.course
        );

        const body = {
            termCode,
            subjectCode,
            courseNumber,
            crn
        };

        try {
            this.setState({isLoading: true});

            await postData(gisDrive.create, body, true);

            toast.success('Drive Created!');
        } catch (err) {
            if (err.timeout) {
                toast.error(
                    'Pending Creating Drive. Please check back in 15 minutes'
                );
            } else {
                toast.error('Error Creating Drive');
            }
        } finally {
            this.setState({isLoading: false});
            this.loadDrive();
        }
    };

    onCopyText = text => {
        navigator.clipboard.writeText(text);
        this.setState({
            snackBar: {
                isOpen: true,
                message: 'Link Copied',
                success: true
            }
        });
    };

    render() {
        const {drive, isLoading, snackBar} = this.state;

        return (
            <React.Fragment>
                <Container className="p-3">
                    <Loader isOpen={isLoading} />
                    <PortalSnackbar
                        isOpen={snackBar.isOpen}
                        message={snackBar.message}
                        success={snackBar.success}
                        onClose={() => this.setState({snackBar: {}})}
                    />
                    <Loader isOpen={isLoading} />
                    <Typography variant="h3">Shared Drive</Typography>
                    <Row
                        className={`justify-content-center align-items-center ${
                            _.isEmpty(drive) ? 'flex-column' : 'flex-row'
                        }`}
                    >
                        <Col
                            xs="12"
                            sm="6"
                            md="6"
                            lg="3"
                            className={`d-flex ${
                                _.isEmpty(drive)
                                    ? 'justify-content-center'
                                    : 'justify-content-end'
                            } align-items-center`}
                            style={{height: 200, width: 200}}
                        >
                            <img
                                src={
                                    _.isEmpty(drive)
                                        ? '/img/cct_sharedDriveSearch.svg'
                                        : '/img/cct_sharedDrive.svg'
                                }
                                alt="Shared Drive"
                                style={{width: '100%', height: 'auto'}}
                            />
                        </Col>

                        {_.isEmpty(drive) ? (
                            <Row className="w-100">
                                <Col className="text-center p-2">
                                    <Typography>
                                        There is no Shared Drive for this class.{' '}
                                        <Link
                                            sx={{cursor: 'pointer'}}
                                            onClick={this.createDrive}
                                        >
                                            Create a Shared Drive
                                        </Link>
                                    </Typography>
                                </Col>
                            </Row>
                        ) : (
                            <Col xs="12" lg="7">
                                <Typography>
                                    <FontAwesomeIcon
                                        icon={faCircleExclamation}
                                    />{' '}
                                    Creation of shared drive{' '}
                                    <strong>{drive.path.split('/')[3]}</strong>{' '}
                                    {drive.status.toLowerCase()}
                                </Typography>
                                <Typography>
                                    <strong>Drive Location</strong> {drive.path}{' '}
                                    <FontAwesomeIcon
                                        icon={faLink}
                                        onClick={() =>
                                            this.onCopyText(drive.path)
                                        }
                                        style={{cursor: 'pointer'}}
                                    />
                                </Typography>
                            </Col>
                        )}
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course
    };
};

export default connect(mapStateToProps)(SharedDrive);
