import { Fade, Grow, Paper, Slide, Zoom, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import React, { Component } from 'react';

class PortalPageLoader extends Component {
    state = {
        isIn: true,
        timeout: 2*1000
    } 

    icon = (
          <img src='/img/oduCrown_blue.svg' height={100} width={100} alt="ODU Logo"/>
      );

    componentDidMount(){
        setInterval(this.toggleIn, this.state.timeout)
    }

    toggleIn = () => {
        this.setState({isIn: !this.state.isIn})
    }

    render() { 
        return <React.Fragment>
            <Box className="myOdu__portalPageLoader">
                <Grid container sx={{height: '100vh'}} alignItems={"center"} justifyContent={"center"}>
                    <Box position="relative" display="inline-flex">
                        <CircularProgress 
                            variant="determinate" 
                            value={100} 
                            size={'10rem'} 
                            thickness={2}
                            className="loaderCircle"
                        />
                        <Box top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Fade in={this.state.isIn} timeout={this.state.timeout}>{this.icon}</Fade>
                            {/* <Grow in={this.state.isIn} timeout={this.state.timeout}>{this.icon}</Grow> */}
                            {/* <Slide in={this.state.isIn} timeout={this.state.timeout}>{this.icon}</Slide> */}
                            {/* <Zoom in={this.state.isIn} timeout={this.state.timeout}>{this.icon}</Zoom> */}
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </React.Fragment>
    }
}
 
export default PortalPageLoader;