import React, {Component} from 'react';
import {List, ListItem, Box, Typography} from '@mui/material';
import Item from './Item';

class DetailsList extends Component {
    getEmptyListMessage = () => {
        switch (this.props.tabSelected) {
            case 1: // Required
                return `No ${this.props.checklistTitle ?? ''} required checklist items found.`;
            case 2: // Optional
                return `No ${this.props.checklistTitle ?? ''} optional checklist items found.`;
            case 0: // All Items
            default:
                return `No ${this.props.checklistTitle ?? ''} checklist items found.`;
        }
    };

    render() {
        return (
            <Box>
                {!this.props.isLoading && this.props.items?.length > 0 && (
                    <List sx={{p: 0}}>
                        {this.props.items?.map((item, index) => {
                            return (
                                <ListItem sx={{px:0, py:2}} key={item.itemTimeCode} divider>
                                    <Item
                                        item={item}
                                        isExpand={
                                            this.props.itemsExpansionStatus[
                                                index
                                            ]
                                        }
                                        toggleItemExpansionStatus={
                                            this.props.toggleItemExpansionStatus
                                        }
                                        index={index}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                )}
                {!this.props.isLoading && this.props.items?.length === 0 && (
                    <Typography component="p">
                        {this.getEmptyListMessage()}
                    </Typography>
                )}
            </Box>
        );
    }
}

export default DetailsList;
