import React from 'react';

import {Typography, Container, Box} from '@mui/material';
import {militaryAffiliationOptions} from '../common/Form/FormOptions';
import {FormControlledRadioButton} from '../common/Form/FormRadioButton';

export const MilitaryAffiliationSection = ({control}) => {
  return (
    <Box className="militaryAffiliation">
      <Typography component="h3">
        Military Affiliation
      </Typography>

      <Typography>
        Are you a current or former member of the US Military?
      </Typography>

      <Box sx={{flexGrow: 1, mt: 1, mb: 1}}>
        <FormControlledRadioButton
          name="militaryAffiliated"
          control={control}
          options={militaryAffiliationOptions}
        />
      </Box>
    </Box>
  );
};
