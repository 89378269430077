export const campusLocations = [
    {label: 'ODUGlobal', value: '001'},
    {label: 'Blue Ridge Community College', value: '565'},
    {label: 'Central Virginia Community College', value: '586'},
    {label: 'Dabney S. Lancaster CC', value: '563'},
    {label: 'Danville Community College', value: '566'},
    {label: 'Eastern Shore Community College', value: '562'},
    {label: 'Everett Naval Station', value: '7W3'},
    {label: 'Fort Belvoir', value: '556'},
    {label: 'Fort Eustis', value: '254'},
    {label: 'Fort Lee', value: '553'},
    {label: 'Fort Myer', value: '557'},
    {label: 'Germanna CC - Fredericksburg Campus', value: '5GF'},
    {label: 'J. Sargeant Reynolds CC', value: '576'},
    {label: 'John Tyler Community College', value: '567'},
    {label: 'Joint Expeditionary Base LC/FS', value: '25A'},
    {label: 'Langley Air Force Base', value: '257'},
    {label: 'Lord Fairfax CC - Fauquier Campus', value: '595'},
    {label: 'Lord Fairfax CC - Middletown Campus', value: '583'},
    {label: 'Mountain Empire Community College', value: '590'},
    {label: 'Naval Base Kitsap - NBK Bangor WA', value: '7W2'},
    {label: 'Naval Station Kitsap - Bremerton WA', value: '7W5'},
    {label: 'Naval Station Norfolk (NSN)', value: '25C'},
    {label: 'New River Community College', value: '584'},
    {label: 'Oceana Naval Air Station', value: '25F'},
    {label: 'ODU - Main Campus (Norfolk)', value: '100'},
    {label: 'ODU Dahlgren', value: '552'},
    {label: 'Olympic College', value: '7W1'},
    {label: 'Patrick Henry Community College', value: '574'},
    {label: 'Paul D. Camp CC', value: '561'},
    {label: 'Peninsula Higher Ed Center', value: '221'},
    {label: 'Piedmont Virginia Community College', value: '580'},
    {label: 'Quantico Marine Corps Base', value: '551'},
    {label: 'Rappahannock CC - Glenns Campus', value: '575'},
    {label: 'Roanoke Higher Education Center', value: '5R1'},
    {
        label: 'Southern Virginia Higher Education Center',
        value: '5B1'
    },
    {
        label: 'Southside Virginia CC - Christanna Campus',
        value: '585'
    },
    {
        label: 'Southside Virginia CC - J.H. Daniel Campus',
        value: '588'
    },
    {label: 'Southwest Virginia Community College', value: '581'},
    {
        label: 'Southwest Virginia Higher Education Center',
        value: '596'
    },
    {label: 'The Pentagon Army Ed Center', value: '5PA'},
    {label: 'Tri-Cities Higher Ed Center', value: '282'},
    {label: 'Virginia Beach Higher Ed Center', value: '223'},
    {label: 'Virginia Western Community College', value: '577'},
    {label: 'Wytheville Community College', value: '582'},
    {label: 'Yavapai College - Prescott Campus', value: '7A1'},
    {label: 'Yavapai College - Verde Campus', value: '7A2'}
];

export const countryCodes = [
    {label: 'USA/Canada (+1)', value: 1},
    {label: 'Algeria (+213)', value: 213},
    {label: 'Andorra (+376)', value: 376},
    {label: 'Angola (+244)', value: 244},
    {label: 'Anguilla (+1264)', value: 1264},
    {label: 'Antigua & Barbuda (+1268)', value: 1268},
    {label: 'Argentina (+54)', value: 54},
    {label: 'Armenia (+374)', value: 374},
    {label: 'Aruba (+297)', value: 297},
    {label: 'Australia (+61)', value: 61},
    {label: 'Austria (+43)', value: 43},
    {label: 'Azerbaijan (+994)', value: 994},
    {label: 'Bahamas (+1242)', value: 1242},
    {label: 'Bahrain (+973)', value: 973},
    {label: 'Bangladesh (+880)', value: 880},
    {label: 'Barbados (+1246)', value: 1246},
    {label: 'Belarus (+375)', value: 375},
    {label: 'Belgium (+32)', value: 32},
    {label: 'Belize (+501)', value: 501},
    {label: 'Benin (+229)', value: 229},
    {label: 'Bermuda (+1441)', value: 1441},
    {label: 'Bhutan (+975)', value: 975},
    {label: 'Bolivia (+591)', value: 591},
    {label: 'Bosnia Herzegovina (+387)', value: 387},
    {label: 'Botswana (+267)', value: 267},
    {label: 'Brazil (+55)', value: 55},
    {label: 'Brunei (+673)', value: 673},
    {label: 'Bulgaria (+359)', value: 359},
    {label: 'Burkina Faso (+226)', value: 226},
    {label: 'Burundi (+257)', value: 257},
    {label: 'Cambodia (+855)', value: 855},
    {label: 'Cameroon (+237)', value: 237},
    {label: 'Cape Verde Islands (+238)', value: 238},
    {label: 'Cayman Islands (+1345)', value: 1345},
    {label: 'Central African Republic (+236)', value: 236},
    {label: 'Chile (+56)', value: 56},
    {label: 'China (+86)', value: 86},
    {label: 'Colombia (+57)', value: 57},
    {label: 'Comoros (+269)', value: 269},
    {label: 'Congo (+242)', value: 242},
    {label: 'Cook Islands (+682)', value: 682},
    {label: 'Costa Rica (+506)', value: 506},
    {label: 'Croatia (+385)', value: 385},
    {label: 'Cuba (+53)', value: 53},
    {label: 'Cyprus North (+90392)', value: 90392},
    {label: 'Cyprus South (+357)', value: 357},
    {label: 'Czech Republic (+42)', value: 42},
    {label: 'Denmark (+45)', value: 45},
    {label: 'Djibouti (+253)', value: 253},
    {label: 'Dominica (+1809)', value: 1809},
    {label: 'Dominican Republic (+1809)', value: 1809},
    {label: 'Ecuador (+593)', value: 593},
    {label: 'Egypt (+20)', value: 20},
    {label: 'El Salvador (+503)', value: 503},
    {label: 'Equatorial Guinea (+240)', value: 240},
    {label: 'Eritrea (+291)', value: 291},
    {label: 'Estonia (+372)', value: 372},
    {label: 'Ethiopia (+251)', value: 251},
    {label: 'Falkland Islands (+500)', value: 500},
    {label: 'Faroe Islands (+298)', value: 298},
    {label: 'Fiji (+679)', value: 679},
    {label: 'Finland (+358)', value: 358},
    {label: 'France (+33)', value: 33},
    {label: 'French Guiana (+594)', value: 594},
    {label: 'French Polynesia (+689)', value: 689},
    {label: 'Gabon (+241)', value: 241},
    {label: 'Gambia (+220)', value: 220},
    {label: 'Georgia (+7880)', value: 7880},
    {label: 'Germany (+49)', value: 49},
    {label: 'Ghana (+233)', value: 233},
    {label: 'Gibraltar (+350)', value: 350},
    {label: 'Greece (+30)', value: 30},
    {label: 'Greenland (+299)', value: 299},
    {label: 'Grenada (+1473)', value: 1473},
    {label: 'Guadeloupe (+590)', value: 590},
    {label: 'Guam (+671)', value: 671},
    {label: 'Guatemala (+502)', value: 502},
    {label: 'Guinea (+224)', value: 224},
    {label: 'Guinea - Bissau (+245)', value: 245},
    {label: 'Guyana (+592)', value: 592},
    {label: 'Haiti (+509)', value: 509},
    {label: 'Honduras (+504)', value: 504},
    {label: 'Hong Kong (+852)', value: 852},
    {label: 'Hungary (+36)', value: 36},
    {label: 'Iceland (+354)', value: 354},
    {label: 'India (+91)', value: 91},
    {label: 'Indonesia (+62)', value: 62},
    {label: 'Iran (+98)', value: 98},
    {label: 'Iraq (+964)', value: 964},
    {label: 'Ireland (+353)', value: 353},
    {label: 'Israel (+972)', value: 972},
    {label: 'Italy (+39)', value: 39},
    {label: 'Jamaica (+1876)', value: 1876},
    {label: 'Japan (+81)', value: 81},
    {label: 'Jordan (+962)', value: 962},
    {label: 'Kazakhstan (+7)', value: 7},
    {label: 'Kenya (+254)', value: 254},
    {label: 'Kiribati (+686)', value: 686},
    {label: 'Korea North (+850)', value: 850},
    {label: 'Korea South (+82)', value: 82},
    {label: 'Kuwait (+965)', value: 965},
    {label: 'Kyrgyzstan (+996)', value: 996},
    {label: 'Laos (+856)', value: 856},
    {label: 'Latvia (+371)', value: 371},
    {label: 'Lebanon (+961)', value: 961},
    {label: 'Lesotho (+266)', value: 266},
    {label: 'Liberia (+231)', value: 231},
    {label: 'Libya (+218)', value: 218},
    {label: 'Liechtenstein (+417)', value: 417},
    {label: 'Lithuania (+370)', value: 370},
    {label: 'Luxembourg (+352)', value: 352},
    {label: 'Macao (+853)', value: 853},
    {label: 'Macedonia (+389)', value: 389},
    {label: 'Madagascar (+261)', value: 261},
    {label: 'Malawi (+265)', value: 265},
    {label: 'Malaysia (+60)', value: 60},
    {label: 'Maldives (+960)', value: 960},
    {label: 'Mali (+223)', value: 223},
    {label: 'Malta (+356)', value: 356},
    {label: 'Marshall Islands (+692)', value: 692},
    {label: 'Martinique (+596)', value: 596},
    {label: 'Mauritania (+222)', value: 222},
    {label: 'Mayotte (+269)', value: 269},
    {label: 'Mexico (+52)', value: 52},
    {label: 'Micronesia (+691)', value: 691},
    {label: 'Moldova (+373)', value: 373},
    {label: 'Monaco (+377)', value: 377},
    {label: 'Mongolia (+976)', value: 976},
    {label: 'Montserrat (+1664)', value: 1664},
    {label: 'Morocco (+212)', value: 212},
    {label: 'Mozambique (+258)', value: 258},
    {label: 'Myanmar (+95)', value: 95},
    {label: 'Namibia (+264)', value: 264},
    {label: 'Nauru (+674)', value: 674},
    {label: 'Nepal (+977)', value: 977},
    {label: 'Netherlands (+31)', value: 31},
    {label: 'New Caledonia (+687)', value: 687},
    {label: 'New Zealand (+64)', value: 64},
    {label: 'Nicaragua (+505)', value: 505},
    {label: 'Niger (+227)', value: 227},
    {label: 'Nigeria (+234)', value: 234},
    {label: 'Niue (+683)', value: 683},
    {label: 'Norfolk Islands (+672)', value: 672},
    {label: 'Northern Marianas (+670)', value: 670},
    {label: 'Norway (+47)', value: 47},
    {label: 'Oman (+968)', value: 968},
    {label: 'Palau (+680)', value: 680},
    {label: 'Panama (+507)', value: 507},
    {label: 'Papua New Guinea (+675)', value: 675},
    {label: 'Paraguay (+595)', value: 595},
    {label: 'Peru (+51)', value: 51},
    {label: 'Philippines (+63)', value: 63},
    {label: 'Poland (+48)', value: 48},
    {label: 'Portugal (+351)', value: 351},
    {label: 'Puerto Rico (+1787)', value: 1787},
    {label: 'Qatar (+974)', value: 974},
    {label: 'Reunion (+262)', value: 262},
    {label: 'Romania (+40)', value: 40},
    {label: 'Russia (+7)', value: 7},
    {label: 'Rwanda (+250)', value: 250},
    {label: 'San Marino (+378)', value: 378},
    {label: 'Sao Tome & Principe (+239)', value: 239},
    {label: 'Saudi Arabia (+966)', value: 966},
    {label: 'Senegal (+221)', value: 221},
    {label: 'Serbia (+381)', value: 381},
    {label: 'Seychelles (+248)', value: 248},
    {label: 'Sierra Leone (+232)', value: 232},
    {label: 'Singapore (+65)', value: 65},
    {label: 'Slovak Republic (+421)', value: 421},
    {label: 'Slovenia (+386)', value: 386},
    {label: 'Solomon Islands (+677)', value: 677},
    {label: 'Somalia (+252)', value: 252},
    {label: 'South Africa (+27)', value: 27},
    {label: 'Spain (+34)', value: 34},
    {label: 'Sri Lanka (+94)', value: 94},
    {label: 'St. Helena (+290)', value: 290},
    {label: 'St. Kitts (+1869)', value: 1869},
    {label: 'St. Lucia (+1758)', value: 1758},
    {label: 'Sudan (+249)', value: 249},
    {label: 'Suriname (+597)', value: 597},
    {label: 'Swaziland (+268)', value: 268},
    {label: 'Sweden (+46)', value: 46},
    {label: 'Switzerland (+41)', value: 41},
    {label: 'Syria (+963)', value: 963},
    {label: 'Taiwan (+886)', value: 886},
    {label: 'Tajikstan (+7)', value: 7},
    {label: 'Thailand (+66)', value: 66},
    {label: 'Togo (+228)', value: 228},
    {label: 'Tonga (+676)', value: 676},
    {label: 'Trinidad & Tobago (+1868)', value: 1868},
    {label: 'Tunisia (+216)', value: 216},
    {label: 'Turkey (+90)', value: 90},
    {label: 'Turkmenistan (+7)', value: 7},
    {label: 'Turkmenistan (+993)', value: 993},
    {label: 'Turks & Caicos Islands (+1649)', value: 1649},
    {label: 'Tuvalu (+688)', value: 688},
    {label: 'Uganda (+256)', value: 256},
    {label: 'UK (+44)', value: 44},
    {label: 'Ukraine (+380)', value: 380},
    {label: 'United Arab Emirates (+971)', value: 971},
    {label: 'Uruguay (+598)', value: 598},
    {label: 'Uzbekistan (+7)', value: 7},
    {label: 'Vanuatu (+678)', value: 678},
    {label: 'Vatican City (+379)', value: 379},
    {label: 'Venezuela (+58)', value: 58},
    {label: 'Vietnam (+84)', value: 84},
    {label: 'Virgin Islands - British (+1284)', value: 1284},
    {label: 'Virgin Islands - US (+1340)', value: 1340},
    {label: 'Wallis & Futuna (+681)', value: 681},
    {label: 'Yemen (North)(+969)', value: 969},
    {label: 'Yemen (South)(+967)', value: 967},
    {label: 'Zambia (+260)', value: 260},
    {label: 'Zimbabwe (+263)', value: 263}
];

export const countryCodesAlpha = [
    {label: 'United States of America', value: '157'},
    {label: 'Afghanistan', value: '7AF'},
    {label: 'Albania', value: '6AL'},
    {label: 'Algeria', value: '8AG'},
    {label: 'American Samoa', value: '4AQ'},
    {label: 'Andorra', value: '6AN'},
    {label: 'Angola', value: '5AO'},
    {label: 'Anguilla', value: '3AV'},
    {label: 'Antarctica', value: '9AY'},
    {label: 'Antigua And Barbuda', value: '3AC'},
    {label: 'Argentina', value: '3AR'},
    {label: 'Armenia', value: '6AM'},
    {label: 'Aruba', value: '3AA'},
    {label: 'Ashmore And Cartier Islands', value: '4AT'},
    {label: 'Australia', value: '4AS'},
    {label: 'Austria', value: '6AU'},
    {label: 'Azerbaijan', value: '6AJ'},
    {label: 'Bahamas', value: '3BF'},
    {label: 'Bahrain', value: '8BA'},
    {label: 'Baker Island', value: '4FQ'},
    {label: 'Bangladesh', value: '7BG'},
    {label: 'Barbados', value: '3BB'},
    {label: 'Bassas Da India', value: '5BS'},
    {label: 'Belarus', value: '6BO'},
    {label: 'Belgium', value: '6BE'},
    {label: 'Belize', value: '3BH'},
    {label: 'Benin', value: '5BN'},
    {label: 'Bermuda', value: '3BD'},
    {label: 'Bhutan', value: '7BT'},
    {label: 'Bolivia', value: '3BL'},
    {label: 'Bosnia And Herzegovina', value: '6BK'},
    {label: 'Botswana', value: '5BC'},
    {label: 'Bouvet Island', value: '5BV'},
    {label: 'Brazil', value: '3BR'},
    {label: 'British Indian Ocean Terr', value: '7IO'},
    {label: 'British Virgin Islands', value: '3VI'},
    {label: 'Brunei', value: '7BX'},
    {label: 'Bulgaria', value: '6BU'},
    {label: 'Burkina Faso', value: '5UV'},
    {label: 'Burma (Myanmar)', value: '7BM'},
    {label: 'Burundi', value: '5BY'},
    {label: 'Cambodia', value: '7CB'},
    {label: 'Cameroon', value: '5CM'},
    {label: 'Canada', value: '2CA'},
    {label: 'Canal Zone', value: '3PQ'},
    {label: 'Canton & Enderbury', value: '4EQ'},
    {label: 'Cape Verde', value: '5CV'},
    {label: 'Cayman Islands', value: '3CJ'},
    {label: 'Central African Republic', value: '5CT'},
    {label: 'Central and S Line Islands', value: '4CL'},
    {label: 'Chad', value: '5CD'},
    {label: 'Chile', value: '3CI'},
    {label: 'China', value: '7CH'},
    {label: 'Christmas Island', value: '4KT'},
    {label: 'Clipperton Island', value: '4IP'},
    {label: 'Cocos (Keeling) Islands', value: '4CK'},
    {label: 'Colombia', value: '3CO'},
    {label: 'Comoros', value: '5CN'},
    {label: 'Congo', value: '5CF'},
    {label: 'Congo-Dem. Rep. of', value: '5CG'},
    {label: 'Cook Islands', value: '4CW'},
    {label: 'Coral Sea Islands', value: '4CR'},
    {label: 'Costa Rica', value: '3CS'},
    {label: "Cote d'Ivoire", value: '5IV'},
    {label: 'Croatia', value: '6HR'},
    {label: 'Cuba', value: '3CU'},
    {label: 'Cyprus', value: '6CY'},
    {label: 'Czech Republic', value: '6EZ'},
    {label: 'Denmark', value: '6DA'},
    {label: 'Djibouti', value: '5DJ'},
    {label: 'Dominica', value: '3DO'},
    {label: 'Dominican Republic', value: '3DR'},
    {label: 'Ecuador', value: '3EC'},
    {label: 'Egypt', value: '8EG'},
    {label: 'El Salvador', value: '3ES'},
    {label: 'Equatorial Guinea', value: '5EK'},
    {label: 'Eritrea', value: '5ER'},
    {label: 'Estonia', value: '6EN'},
    {label: 'Ethiopia', value: '5ET'},
    {label: 'Europa Island', value: '5EU'},
    {label: 'Falkland Islands', value: '3FK'},
    {label: 'Faroe Islands', value: '4FO'},
    {label: 'Fiji', value: '4FJ'},
    {label: 'Finland', value: '6FI'},
    {label: 'France', value: '6FR'},
    {label: 'French Guiana', value: '3FG'},
    {label: 'French Polynesia', value: '4FP'},
    {label: 'French South & Antarctic Lands', value: '9FS'},
    {label: 'French Terr of Afars & Issas', value: '5FT'},
    {label: 'Gabon', value: '5GB'},
    {label: 'Gambia', value: '5GA'},
    {label: 'Gaza Strip', value: '8GZ'},
    {label: 'Georgia', value: '6GG'},
    {label: 'Germany', value: '6GM'},
    {label: 'Ghana', value: '5GH'},
    {label: 'Gibraltar', value: '6GI'},
    {label: 'Gilbert & Ellice Islands', value: '4GN'},
    {label: 'Gilbert Islands', value: '4GS'},
    {label: 'Glorioso Islands', value: '6GO'},
    {label: 'Greece', value: '6GR'},
    {label: 'Greenland', value: '6GL'},
    {label: 'Grenada', value: '3GJ'},
    {label: 'Guadeloupe', value: '3GP'},
    {label: 'Guam', value: '4GQ'},
    {label: 'Guatemala', value: '3GT'},
    {label: 'Guernsey', value: '6GK'},
    {label: 'Guinea', value: '5GV'},
    {label: 'Guinea-Bissau', value: '5PU'},
    {label: 'Guyana', value: '3GY'},
    {label: 'Haiti', value: '3HA'},
    {label: 'Heard And Mcdonald Islands', value: '4HM'},
    {label: 'Honduras', value: '3HO'},
    {label: 'Hong Kong', value: '7HK'},
    {label: 'Howland Island', value: '4HQ'},
    {label: 'Hungary', value: '6HU'},
    {label: 'Iceland', value: '6IC'},
    {label: 'India', value: '7IN'},
    {label: 'Indonesia', value: '7ID'},
    {label: 'Iran', value: '8IR'},
    {label: 'Iraq', value: '8IZ'},
    {label: 'Ireland', value: '6EI'},
    {label: 'Isle Of Man', value: '6IM'},
    {label: 'Israel', value: '8IS'},
    {label: 'Israel-Jordan Demilitarized Zn', value: '8IW'},
    {label: 'Israel-Saudi Arabia Neutral Zn', value: '8IY'},
    {label: 'Israel-Syria Demilitarized Zn', value: '8IU'},
    {label: 'Italy', value: '6IT'},
    {label: 'Jamaica', value: '3JM'},
    {label: 'Jan Mayen', value: '7JN'},
    {label: 'Japan', value: '7JA'},
    {label: 'Jarvis Island', value: '4DQ'},
    {label: 'Jersey', value: '6JE'},
    {label: 'Johnston Atoll', value: '4JQ'},
    {label: 'Jordan', value: '8JO'},
    {label: 'Juan De Nova Island', value: '5JU'},
    {label: 'Kazakhstan', value: '6KZ'},
    {label: 'Kenya', value: '5KE'},
    {label: 'Kingman Reef', value: '4KQ'},
    {label: 'Kiribati', value: '4KR'},
    {label: 'Kosovo', value: '6KV'},
    {label: 'Kuwait', value: '8KU'},
    {label: 'Kyrgyzstan', value: '6KG'},
    {label: 'Laos', value: '7LA'},
    {label: 'Latvia', value: '6LG'},
    {label: 'Lebanon', value: '8LE'},
    {label: 'Lesotho', value: '5LT'},
    {label: 'Liberia', value: '5LI'},
    {label: 'Libya', value: '8LY'},
    {label: 'Liechtenstein', value: '6LS'},
    {label: 'Lithuania', value: '6LH'},
    {label: 'Luxembourg', value: '6LU'},
    {label: 'Macao', value: '7MC'},
    {label: 'Macedonia', value: '6MK'},
    {label: 'Madagascar', value: '5MA'},
    {label: 'Malawi', value: '5MI'},
    {label: 'Malaysia', value: '7MY'},
    {label: 'Maldives', value: '7MV'},
    {label: 'Mali', value: '5ML'},
    {label: 'Malta', value: '6MT'},
    {label: 'Marshall Islands', value: '4RM'},
    {label: 'Martinique', value: '3MB'},
    {label: 'Mauritania', value: '5MR'},
    {label: 'Mauritius', value: '5MP'},
    {label: 'Mayotte', value: '3MF'},
    {label: 'Mexico', value: '3MX'},
    {label: 'Micronesia-Federated States of', value: '4FM'},
    {label: 'Midway Islands', value: '4MQ'},
    {label: 'Moldova', value: '6MD'},
    {label: 'Monaco', value: '6MN'},
    {label: 'Mongolia', value: '7MG'},
    {label: 'Montenegro', value: '6MW'},
    {label: 'Montserrat', value: '3MH'},
    {label: 'Morocco', value: '8MO'},
    {label: 'Mozambique', value: '5MZ'},
    {label: 'Namibia', value: '5WA'},
    {label: 'Nauru', value: '4NR'},
    {label: 'Navassa Island', value: '3BQ'},
    {label: 'Nepal', value: '7NP'},
    {label: 'Netherlands', value: '6NL'},
    {label: 'Netherlands Antilles', value: '3NT'},
    {label: 'Neutral Zone', value: 'OU2'},
    {label: 'New Caledonia', value: '4NC'},
    {label: 'New Zealand', value: '4NZ'},
    {label: 'Nicaragua', value: '3NU'},
    {label: 'Niger', value: '5NG'},
    {label: 'Nigeria', value: '5NI'},
    {label: 'Niue', value: '4NE'},
    {label: 'Norfolk Island', value: '4NF'},
    {label: 'North Korea', value: '7KN'},
    {label: 'Northern Mariana Islands', value: '4CQ'},
    {label: 'Norway', value: '6NO'},
    {label: 'Oman', value: '8MU'},
    {label: 'Pakistan', value: '7PK'},
    {label: 'Palau', value: '4PS'},
    {label: 'Palmyra Atoll', value: '4LQ'},
    {label: 'Panama', value: '3PM'},
    {label: 'Papua New Guinea', value: '4PP'},
    {label: 'Paracel Islands', value: '7PF'},
    {label: 'Paraguay', value: '3PA'},
    {label: 'Peru', value: '3PE'},
    {label: 'Philippines', value: '7RP'},
    {label: 'Pitcairn Islands', value: '4PC'},
    {label: 'Poland', value: '6PL'},
    {label: 'Portugal', value: '6PO'},
    {label: 'Portuguese Timor', value: '7PT'},
    {label: 'Puerto Rico', value: '3RQ'},
    {label: 'Qatar', value: '8QA'},
    {label: 'Reunion', value: '5RE'},
    {label: 'Romania', value: '6RO'},
    {label: 'Russia', value: '6RS'},
    {label: 'Rwanda', value: '5RW'},
    {label: 'S Georgia/The S Sandwich Is', value: '3SX'},
    {label: 'Saint Barthelemy', value: '3TB'},
    {label: 'Saint Helena', value: '5SH'},
    {label: 'Saint Martin (French)', value: '3RN'},
    {label: 'Samoa', value: '4WS'},
    {label: 'San Marino', value: '6SM'},
    {label: 'Sao Tome and Principe', value: '5TP'},
    {label: 'Saudi Arabia', value: '8SA'},
    {label: 'Senegal', value: '5SG'},
    {label: 'Serbia', value: '6RI'},
    {label: 'Serbia and Montenegro', value: '6YI'},
    {label: 'Seychelles', value: '5SE'},
    {label: 'Sierra Leone', value: '5SL'},
    {label: 'Sikkim', value: '7SK'},
    {label: 'Singapore', value: '7SN'},
    {label: 'Slovakia', value: '6LO'},
    {label: 'Slovenia', value: '6SI'},
    {label: 'Solomon Islands', value: '4BP'},
    {label: 'Somalia', value: '5SO'},
    {label: 'South Africa', value: '5SF'},
    {label: 'South Korea', value: '7KS'},
    {label: 'Southern Rhodesia', value: '5RH'},
    {label: 'Southern Ryukyu Islands', value: '7YQ'},
    {label: 'Spain', value: '6SP'},
    {label: 'Spanish North Africa', value: '5ME'},
    {label: 'Spanish Sahara', value: '5SS'},
    {label: 'Spratley Islands', value: '7PG'},
    {label: 'Sri Lanka', value: '7CE'},
    {label: 'St Kitts and Nevis', value: '3SC'},
    {label: 'St Lucia', value: '3ST'},
    {label: 'St Pierre and Miquelon', value: '5SB'},
    {label: 'St Vincent & the Grenadines', value: '3VC'},
    {label: 'Stateless', value: 'OU3'},
    {label: 'Sudan', value: '5SU'},
    {label: 'Suriname', value: '3NS'},
    {label: 'Svalbard', value: '6SV'},
    {label: 'Swan Islands', value: '3SQ'},
    {label: 'Swaziland', value: '5WZ'},
    {label: 'Sweden', value: '6SW'},
    {label: 'Switzerland', value: '6SZ'},
    {label: 'Syria', value: '3SY'},
    {label: 'Taiwan', value: '7TW'},
    {label: 'Tajikistan', value: '4TI'},
    {label: 'Tanzania', value: '5TZ'},
    {label: 'Thailand', value: '7TH'},
    {label: 'Timor-Leste', value: '7TL'},
    {label: 'Togo', value: '5TO'},
    {label: 'Tokelau', value: '4TK'},
    {label: 'Tonga', value: '4TN'},
    {label: 'Trinidad and Tobago', value: '3TD'},
    {label: 'Tromelin Island', value: '5TE'},
    {label: 'Trust Terr of Pacific Is', value: '4TT'},
    {label: 'Tunisia', value: '8TS'},
    {label: 'Turkey', value: '6TU'},
    {label: 'Turkmenistan', value: '6TX'},
    {label: 'Turks and Caicos Islands', value: '3TK'},
    {label: 'Tuvalu', value: '4TV'},
    {label: 'Uganda', value: '5UG'},
    {label: 'Ukraine', value: '6UP'},
    {label: 'United Arab Emirates', value: '8AE'},
    {label: 'United Kingdom', value: '6UK'},
    {label: 'Uruguay', value: '3UY'},
    {label: 'Uzbekistan', value: '6UZ'},
    {label: 'Vanuatu', value: '4NH'},
    {label: 'Vatican City', value: '6VT'},
    {label: 'Venezuela', value: '3VE'},
    {label: 'Vietnam', value: '7VM'},
    {label: 'Virgin Islands of the US', value: '3VQ'},
    {label: 'Wake Island', value: '4WQ'},
    {label: 'Wallis and Futuna', value: '4WF'},
    {label: 'West Bank', value: '8WE'},
    {label: 'Western Sahara', value: '8WI'},
    {label: 'Yemen', value: '8YM'},
    {label: 'Yugoslavia', value: '6YF'},
    {label: 'Zaire', value: '5ZR'},
    {label: 'Zambia', value: '5ZA'},
    {label: 'Zimbabwe', value: '5ZI'}
];

export const stateCodes = [
    {label: 'Military - Americas', value: 'AA'},
    {label: 'Military - Europe', value: 'AE'},
    {label: 'Alaska', value: 'AK'},
    {label: 'Alabama', value: 'AL'},
    {label: 'Military - Pacific', value: 'AP'},
    {label: 'Arkansas', value: 'AR'},
    {label: 'American Samoa', value: 'AS'},
    {label: 'Arizona', value: 'AZ'},
    {label: 'California', value: 'CA'},
    {label: 'Colorado', value: 'CO'},
    {label: 'Connecticut', value: 'CT'},
    {label: 'District of Columbia', value: 'DC'},
    {label: 'Delaware', value: 'DE'},
    {label: 'Florida', value: 'FL'},
    {label: 'Federated States Of Micronesia', value: 'FM'},
    {label: 'Foreign State', value: 'FR'},
    {label: 'Georgia', value: 'GA'},
    {label: 'Guam', value: 'GU'},
    {label: 'Hawaii', value: 'HI'},
    {label: 'Iowa', value: 'IA'},
    {label: 'Idaho', value: 'ID'},
    {label: 'Illinois', value: 'IL'},
    {label: 'Indiana', value: 'IN'},
    {label: 'Kansas', value: 'KS'},
    {label: 'Kentucky', value: 'KY'},
    {label: 'Louisiana', value: 'LA'},
    {label: 'Massachusetts', value: 'MA'},
    {label: 'Maryland', value: 'MD'},
    {label: 'Maine', value: 'ME'},
    {label: 'Marshall Islands', value: 'MH'},
    {label: 'Michigan', value: 'MI'},
    {label: 'Minnesota', value: 'MN'},
    {label: 'Missouri', value: 'MO'},
    {label: 'Northern Mariana Islands', value: 'MP'},
    {label: 'Mississippi', value: 'MS'},
    {label: 'Montana', value: 'MT'},
    {label: 'North Carolina', value: 'NC'},
    {label: 'North Dakota', value: 'ND'},
    {label: 'Nebraska', value: 'NE'},
    {label: 'New Hampshire', value: 'NH'},
    {label: 'New Jersey', value: 'NJ'},
    {label: 'New Mexico', value: 'NM'},
    {label: 'Nevada', value: 'NV'},
    {label: 'New York', value: 'NY'},
    {label: 'Ohio', value: 'OH'},
    {label: 'Oklahoma', value: 'OK'},
    {label: 'Oregon', value: 'OR'},
    {label: 'Pennsylvania', value: 'PA'},
    {label: 'Puerto Rico', value: 'PR'},
    {label: 'Palau', value: 'PW'},
    {label: 'Rhode Island', value: 'RI'},
    {label: 'South Carolina', value: 'SC'},
    {label: 'South Dakota', value: 'SD'},
    {label: 'Tennessee', value: 'TN'},
    {label: 'Trust Territories, U.S.', value: 'TT'},
    {label: 'Texas', value: 'TX'},
    {label: 'Utah', value: 'UT'},
    {label: 'Virginia', value: 'VA'},
    {label: 'Virgin Islands', value: 'VI'},
    {label: 'Vermont', value: 'VT'},
    {label: 'Washington', value: 'WA'},
    {label: 'Wisconsin', value: 'WI'},
    {label: 'West Virginia', value: 'WV'},
    {label: 'Wyoming', value: 'WY'}
];

// export const mobileTypes = [
//   { value: "mobile", label: "Mobile" },
//   { value: "non-mobile", label: "Non-Mobile" },
// ];

export const mobileTypes = [
    {value: 'false', label: 'Mobile'},
    {value: 'true', label: 'Non-Mobile'}
];

export const relationshipOptions = [
    {label: 'Father 1', value: 'F'},
    {label: 'Mother 1', value: 'M'},
    {label: 'Legal Guardian 1', value: 'U'},
    {label: 'Father 2', value: '1'},
    {label: 'Mother 2', value: '2'},
    {label: 'Legal Guardian 2', value: '3'}
];

export const militaryAffiliationOptions = [
    {label: 'No', value: false},
    {label: 'Yes', value: true}
];
