import React, {Component} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';

class Loader extends Component {
  state = {};

  render() {
    return (
      <Backdrop
          id={this.props.id+"__backdrop_loader"}
        sx={{
          zIndex: theme => Math.max.apply(Math, Object.values(theme.zIndex)) + 1 // Displays loader above all the Dialog components
        }}
        open={this.props.isOpen}
      >
        <CircularProgress id={this.props.id+"__circularProgress_loader"} sx={{color: 'primary.dark'}} />
      </Backdrop>
    );
  }
}

export default Loader;
