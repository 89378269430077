import { Slide } from "@mui/material";
import React, { Component } from "react";
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faExclamationCircle } from "@fortawesome/pro-light-svg-icons";


class PortalToast extends Component {
    render() {
        return <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                theme="light"
                className={"myOdu__toast"}
                icon={({ type }) => {
                    if (type === "success") return <FontAwesomeIcon icon={faThumbsUp} size="xl" />;
                    if (type === "error") return <FontAwesomeIcon icon={faExclamationCircle} size="xl" />;
                    else return "";
                }}
                />
                
        </React.Fragment>
    }
}

export default PortalToast;