import React, { Component } from 'react';
import { Typography, Box } from '@mui/material';
import CreditDetails from "../Details/CreditDetails";

class Credits extends Component {
    render() {
        return <Box className="itemBox">
                <Typography variant="h6" component="h4" className="text-center" >
                        Credits
                </Typography>
                <CreditDetails
                    data={this.props.currentSem?.TOTAL_CREDITS_EARNED || 0}
                    text="Hours Completed"
                />
                <CreditDetails
                    data={this.props.totalTransferredCredits || 0}
                    text="Hours Transferred"
                />
                <CreditDetails
                    data={
                        this.props.currentSem?.ACADEMIC_PERIOD_5 ===
                        this.props.terms.CURRENT_TERM
                            ? this.props.currentSem.CURR_TERM_HOURS_REG
                            : 0
                    }
                    text="Hours In Progress"
                />
                <CreditDetails
                    data={this.props.nextSem?.CURR_TERM_HOURS_REG || 0}
                    text={
                        (this.props.nextSem?.ACADEMIC_PERIOD_DESC_5 &&
                            'Registered for ' +
                                this.props.nextSem
                                    .ACADEMIC_PERIOD_DESC_5) ||
                        'Hours Registered'
                    }
                />
            </Box>
    }
}

export default Credits;