import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import TutoringDetails from "./TutoringDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-light-svg-icons";

class AddTutoringDialog extends Component {

    state = {
        newTutoring: {
            SUBJECT: '',
            COURSE_NUMBER: '',
            COURSE_TITLE: '',
            locations: []
        },
    };

    //O(1) Updates the course selected in dropdown
    onCourseUpdate = (selectedCourse) => {
        if(!selectedCourse) {
            selectedCourse = {
                ...this.state.newTutoring,
                SUBJECT: '',
                COURSE_NUMBER: '',
                COURSE_TITLE: '',
            }
        }
        this.setState({
            newTutoring: {
                ...this.state.newTutoring,
                SUBJECT: selectedCourse.SUBJECT,
                COURSE_NUMBER: selectedCourse.COURSE_NUMBER,
                COURSE_TITLE: selectedCourse.COURSE_TITLE
            }
        });
    }

    //O(1) Updates the locations selected in dropdown
    onLocationsUpdate = event => {
        this.setState({
            newTutoring: {
                ...this.state.newTutoring,
                locations: event.target.value
            }
        })
    }

    //O(1) Adds the Tutoring
    onAddTutoring = event => {
        this.props.addTutoring(this.state.newTutoring);
    }

    render() {
        return <React.Fragment>
            <Dialog id={this.props.id + "__dialog_addTutoring"} open fullWidth maxWidth='xl' className="myOdu__wizard" onClose={this.props.toggleIsAddTutoring}>
                    <DialogTitle id={this.props.id+"__dialogTitle_addTutoring"} sx={{backgroundColor: '#003057', color: 'white', borderBottom: "3px solid #2188A2"}}>
                        <Row id={this.props.id+"__row_addTutoring_titleWrapper"}>
                            <Col id={this.props.id+"__row_addTutoring_titleWrapper"}>
                                <Typography id={this.props.id+"__text_addTutoring_title"} variant="h4" component="h2">Add Course Tutoring</Typography>
                            </Col>
                            <Col id={this.props.id+"__col_closeButtonWrapper"} xs={1} style={{display:'flex', justifyContent: 'right'}}>
                                <IconButton id={this.props.id+"__iconButton_addTutoringCloseButton"} size="small" onClick={this.props.toggleIsAddTutoring}><FontAwesomeIcon id={this.props.id+"__icon_addTutoringCloseIcon"} icon={faX} style={{color: "#ffffff"}} /></IconButton>
                            </Col>
                        </Row>
                    </DialogTitle>
                    <DialogContent id={this.props.id+"__dialogContent_tutoringDetailsWrapper"}>
                        <TutoringDetails
                            id={this.props.id+"__tutoringDetails"}
                            selectedTutoring = {this.state.newTutoring}
                            locations = {this.props.locations}
                            courses = {this.props.courses}
                            onCourseUpdate = {this.onCourseUpdate}
                            onLocationsUpdate = {this.onLocationsUpdate}
                            onSubmit = {this.onAddTutoring}
                            disableOption = {this.props.disableOption}
                            isDetails
                            isLoading = {this.props.isLoading}
                            buttonText = 'Add Course'
                            compareCourses = {this.props.compareCourses}
                        />
                    </DialogContent>
                </Dialog>
        </React.Fragment>
    }

}

export default AddTutoringDialog;