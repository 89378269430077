import {SAVE_STATUS_ALERTS, SAVE_STATUS_ALERTS_IS_ERROR} from './actions';

const initialStateStatusAlerts = {
    statusAlerts: null,
    count: 0,
    isError: false
};

// Persists status alerts across pages
export const myStatusAlertsReducer = (
    state = initialStateStatusAlerts,
    action
) => {
    const {type, payload} = action;
    switch (type) {
        case SAVE_STATUS_ALERTS: {
            const alerts = payload.statusAlerts;
            const alertCount =
                (alerts.balance?.length ?? 0) +
                (alerts.holds?.length ?? 0) +
                (alerts.upcomingHolds?.length ?? 0) +
                (alerts.financialAid?.length ?? 0);
            return {
                ...state,
                statusAlerts: alerts,
                count: alertCount,
                isError: false
            };
        }
        case SAVE_STATUS_ALERTS_IS_ERROR: {
            return {...state, isError: payload.isError};
        }
        default:
            return state;
    }
};
