/**
 * A wrapper component that just exists to immediately do Cognito auth.
 *
 */
import React, {Component} from 'react';
import {logIn} from '../../AWS';
import Layout from '../Layout';
import Feedback from '../common/Feedback';

class AuthRedirect extends Component {
    constructor(props) {
        super(props);
        if (!props.isLoggedIn) {
            logIn(props?.path); // Pass the path the user wishes to get redirected to after the successful login
        }
    }

    render() {
        return this.props.isLoggedIn ? (
            <Layout>
                <Feedback />
                {this.props.element}
            </Layout>
        ) : (
            <></>
        );
    }
}

export default AuthRedirect;
