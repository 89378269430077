import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PortalModal from '../common/PortalModal';
import { saveContactsResults, saveContactsSearch, saveContactsUpdate, toggleContactsManager } from './actions';
import Results from './Results';
import Search from './Search';
import List from './List';
import { Box, Grid, Dialog, DialogContent, Stack, Typography, IconButton } from '@mui/material';
import {WithSmallScreen} from '../common/WithSmallScreen';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-light-svg-icons';

class ContactsManager extends Component {
    state = {
        //TODO: move to css
        colStyle: {maxHeight: '40rem', overflowY: 'auto'},
    }

    //Θ(1) reset search form and results and close manager
    onClose = () => {
        this.props.saveContactsSearch()
        this.props.saveContactsResults()
        this.props.toggleContactsManager()
    }

    render() { 
        return <React.Fragment>

            <PortalModal
                id={this.props.id + "__modal_editFavContactsModal"}
                isOpen={this.props.isContactsmanager}
                maxWidth="xl"
                fullWidth
                isFullScreen={this.props.isSmall}
                className="myOdu__contactManager p-0"
                onClose={this.onClose}
                sx={{overflowX: "hidden"}}
                title="Contact Manager"
                isCloseable
            >
                <Row id={this.props.id + "__row_addContactFormWrapper"} className="pb-3">
                    <Col id={this.props.id + "__col_addContactFormWrapper"} className="addContact" style={{...this.state.colStyle, opacity: this.props.isDragging ? 0.5 : 1, padding: 0, overflow: 'hidden'}} xs={12} md={6}>
                        <Box id={this.props.id + "__box_contactFormWrapper"} sx={{pl: '12px', pr: '12px'}}>
                            <Search id={this.props.id + "__searchContacts"} className="searchForm" onSearch={this.props.onSearch} isSearching = {this.props.isSearching} toggleIsSearching = {this.props.toggleIsSearching}/>
                            {/* //TODO: show results only if a search took place in the first place */}
                            {/* {this.props.didSearch &&  */}
                                <Results id={this.props.id + "__resultsContacts"}  className="searchResults" onUpdate={this.props.onUpdate} isSearching = {this.props.isSearching} toggleIsSearching = {this.props.toggleIsSearching}/>
                            {/* } */}
                        </Box>
                    </Col>
                    <Col id={this.props.id + "__col_contactListWrapper"} className="contactList" style={{...this.state.colStyle}}>
                        <List id={this.props.id + "__contactList"} />
                    </Col>
                </Row>

            </PortalModal>
        </React.Fragment>
    }
}
 
const mapStateToProps = (state) => {
    return {
        isContactsmanager: state.myContactsReducer.isContactsmanager,
        isDragging: state.myContactsReducer.isDragging
    }
  }
  
  const mapDispatchToProps = (dispatch) => ({
    toggleContactsManager: () => dispatch(toggleContactsManager()),
    saveContactsSearch: (search) => dispatch(saveContactsSearch(search)),
    saveContactsResults: (results) => dispatch(saveContactsResults(results)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(WithSmallScreen(ContactsManager));