import React, {useEffect, useState} from 'react';
import {Box, Typography, Paper, Grid} from '@mui/material';
import {FrontendErrorLogTable} from './FrontendErrorLogTable';
import getColumns from './columns';
import {errors} from '../../../DataAccessLayer/services';
import {getData} from '../../../DataAccessLayer';
import {FrontendErrorLogDetails} from './FrontendErrorLogDetails';
import {GridToolbar} from '@mui/x-data-grid';

export const FrontEndErrorLog = () => {
    const [errorLogData, setErrorLogData] = useState([]);
    const [errorLogMap, setErrorLogMap] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedErrorLog, setSelectedErrorLog] = useState(null);

    useEffect(() => {
        loadAllErrorLog();
    }, []);

    const loadAllErrorLog = async () => {
        setIsLoading(true);
        try {
            const data = await getData(errors, true);

            // Create a mapping of Error Log data for quick lookup
            const errorLogMap = data.reduce((acc, item) => {
                acc[item.MIDAS + item.CREATED_AT] = item;
                return acc;
            }, {});

            setErrorLogData(data);
            setErrorLogMap(errorLogMap);
        } catch (err) {
            console.error('Failed to load Error Logs:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const getSelectedError = async ({row}) => {
        const {CREATED_AT: createdAt, MIDAS: midas, ERROR: error} = row;
        const data = errorLogMap[midas + createdAt];

        if (data) {
            setSelectedErrorLog({
                midas,
                createdAt,
                error: JSON.stringify(error)
            });
        }
    };

    return (
        <div className="myOdu__container_maxWidth" style={{marginTop: '2rem'}}>
            <Typography component="h2" variant="h4" gutterBottom>
                Frontend Error Log
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} xl={6}>
                    <FrontendErrorLogTable
                        rows={errorLogData}
                        columns={getColumns()}
                        isLoading={isLoading}
                        onClick={getSelectedError}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: {disableToolbarButton: true},
                                printOptions: {disableToolbarButton: true},
                                quickFilterProps: {
                                    sx: {m: 1, width: '100%'},
                                    variant: 'outlined',
                                    size: 'small',
                                    placeholder: 'Search Error Log'
                                }
                            }
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                    />
                </Grid>
                <Grid item xs={12} xl={6}>
                    <Paper
                        sx={{
                            width: '100%',
                            overflow: 'hidden',
                            height: '100%',
                            p: 4
                        }}
                    >
                        <FrontendErrorLogDetails data={selectedErrorLog} />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};
