import {Tab, Tabs} from '@mui/material';
import {Box} from '@mui/system';
import React, {Component} from 'react';
import GoogleDrive from './GoogleDrive';
import Teams from './Teams';
import SharedDrive from './SharedDrive';
import ScheduleMeeting from './Zoom/ScheduleMeeting';
import ZoomIcon from '../../../common/icons/ZoomIcon';
import GoogleDriveIcon from '../../../common/icons/GoogleDriveIcon';
import TeamsIcon from '../../../common/icons/TeamsIcon';
import SharedDriveIcon from '../../../common/icons/SharedDriveIcon';
import {connect} from 'react-redux';
import Zoom from './Zoom';
import {saveZoomMeetings} from '../actions';

class Body extends Component {
    state = {
        activeTab: 1,
        isLoading: false,
        currentMeetings: [],
        pastMeetings: [],
        isScheduleMeeting: false,
        isOpenClassSchedule: false,
        isOpenAdhocSchedule: false
    }

    //Θ(1) Sets active tab
    onTabChange = (e, activeTab) => {
        this.setState({activeTab});
    };

    toggleIsLoading = (isLoading = !this.state.isLoading) => {
        this.setState({
            isLoading
        });
    };

    toggleIsScheduleMeeting = (
        isScheduleMeeting = !this.state.isScheduleMeeting
    ) => {
        this.setState({
            isScheduleMeeting,
            isOpenClassSchedule: false,
            isOpenAdhocSchedule: false
        });
    };

    //Θ(1) Sets Schedule class meeting panel to be opened by default
    openClassSchedule = () => {
        this.setState({
            isScheduleMeeting: true,
            isOpenClassSchedule: true
        });
    };

    //Θ(1) Sets Schedule adhoc meeting panel to be opened by default
    openAdhocSchedule = () => {
        this.setState({
            isScheduleMeeting: true,
            isOpenAdhocSchedule: true
        });
    };

    render() {
        const {course} = this.props;

        const isTeaching = course?.isTeaching;

        return (
            <React.Fragment>
                <Box
                    sx={{
                        width: '100%'
                    }}
                >
                    {!this.state.isScheduleMeeting && (
                        <div>
                            <Tabs
                                value={this.state.activeTab}
                                onChange={this.onTabChange}
                                aria-label="Collaboration Tools"
                                variant="fullWidth"
                                className="myOdu__tabs secondary"
                            >
                                {/* //TODO: get teams Zoom and Teams logo */}
                                <Tab
                                    value={1}
                                    iconPosition="start"
                                    icon={
                                        <Box>
                                            <ZoomIcon />
                                        </Box>
                                    }
                                    label="Zoom"
                                />
                                <Tab
                                    value={2}
                                    iconPosition="start"
                                    icon={
                                        <Box>
                                            <GoogleDriveIcon />
                                        </Box>
                                    }
                                    label="Google Drive"
                                />
                                <Tab
                                    value={3}
                                    iconPosition="start"
                                    icon={
                                        <Box>
                                            <TeamsIcon />
                                        </Box>
                                    }
                                    label="Teams"
                                />
                                {isTeaching && (
                                    <Tab
                                        value={4}
                                        iconPosition="start"
                                        icon={
                                            <Box>
                                                <SharedDriveIcon />
                                            </Box>
                                        }
                                        label="Shared Drive"
                                    />
                                )}
                            </Tabs>
                            <Box sx={{width: '100%', p: 3, pt: 2}}>
                                {this.state.activeTab == 1 && (
                                    <Zoom
                                        onTabChange={this.onTabChange}
                                        toggleIsScheduleMeeting={
                                            this.toggleIsScheduleMeeting
                                        }
                                        openClassSchedule={
                                            this.openClassSchedule
                                        }
                                        openAdhocSchedule={
                                            this.openAdhocSchedule
                                        }
                                    />
                                )}
                                {this.state.activeTab == 2 && <GoogleDrive />}
                                {this.state.activeTab == 3 && <Teams />}
                                {this.state.activeTab == 4 && <SharedDrive />}
                            </Box>
                        </div>
                    )}
                    {this.state.isScheduleMeeting && isTeaching && (
                        <Box sx={{width: '100%', p: 3, pt: 2}}>
                            <ScheduleMeeting
                                toggleIsScheduleMeeting={
                                    this.toggleIsScheduleMeeting
                                }
                                onTabChange={this.onTabChange}
                                isOpenClassSchedule={
                                    this.state.isOpenClassSchedule
                                }
                                isOpenAdhocSchedule={
                                    this.state.isOpenAdhocSchedule
                                }
                            />
                        </Box>
                    )}
                </Box>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isCCT: state.CCTReducer.isCCT,
        course: state.CCTReducer.course,
        currentMeetings: state.CCTReducer.meetings.currentMeetings,
        pastMeetings: state.CCTReducer.meetings.pastMeetings
    };
};

const mapDispatchToProps = dispatch => ({
    saveZoomMeetings: meetings => dispatch(saveZoomMeetings(meetings))
});

export default connect(mapStateToProps, mapDispatchToProps)(Body);
