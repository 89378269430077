import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export default function Droppable(props) {
  const {isOver, setNodeRef, over, active} = useDroppable({
    id: props.id
  });

  return (
    <div ref={setNodeRef} className={"myOdu__droppableArea " + (isOver ? 'droppable' : 'notDroppable')}>
      {
        props.children
      }
    </div>
  );
}