import React from 'react';
import {Tooltip, Typography} from '@mui/material';
//pass time via child or prop
{
    /* <PortalTime time="8:00 AM" id="example1"/>
<PortalTime id="example2">11:00 PM using children</PortalTime>
<PortalTime time="7:37 AM" title="using time and title props" id="example3" /> */
}

class PortalTime extends React.Component {
    //TODO: update to display edt and local time zone if different
    //Ex: "6:00 AM PST, 9:00 AM EDT"
    render() {
        return (
            <span>
                <Tooltip
                    id={this.props.id + '_tooltip'}
                    title={this.props.title ?? 'Eastern Daylight Time'}
                    enterDelay={750}
                    leaveDelay={0}
                    enterNextDelay={750}
                    arrow
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -10]
                                    }
                                }
                            ]
                        }
                    }}
                >
                    <Typography
                        variant={this.props?.variant}
                        id={this.props.id}
                        className={
                            this.props?.className + ' myOdu__universityTime edt'
                        }
                    >
                        {this.props.time ?? this.props.children}
                    </Typography>
                </Tooltip>
            </span>
        );
    }
}

export default PortalTime;
