export const SAVE_CONTACTS = 'SAVE_CONTACTS';
export const SAVE_CONTACTS_UPDATE = 'SAVE_CONTACTS_UPDATE';
export const SAVE_CONTACTS_SEARCH = 'SAVE_CONTACTS_SEARCH';
export const SAVE_CONTACTS_RESULTS = 'SAVE_CONTACTS_RESULTS';
export const TOGGLE_CONTACTS_MANAGER = 'TOGGLE_CONTACTS_MANAGER';
export const TOGGLE_CONTACTS_LOADING = 'TOGGLE_CONTACTS_LOADING';
export const TOGGLE_CONTACTS_DRAGGING = 'TOGGLE_CONTACTS_DRAGGING';

export const saveContacts = (contacts) => ({
    type: SAVE_CONTACTS,
    payload: {contacts}
});

export const saveContactsUpdate = (contacts) => ({
    type: SAVE_CONTACTS_UPDATE,
    payload: {contacts}
});

export const saveContactsSearch = (search) => ({
    type: SAVE_CONTACTS_SEARCH,
    payload: {search}
});

export const saveContactsResults = (results) => ({
    type: SAVE_CONTACTS_RESULTS,
    payload: {results}
});

export const toggleContactsManager = () => ({
    type: TOGGLE_CONTACTS_MANAGER,
    payload: {}
});

export const toggleContactsLoading = () => ({
    type: TOGGLE_CONTACTS_LOADING,
    payload: {}
});

export const toggleContactsDragging = () => ({
    type: TOGGLE_CONTACTS_DRAGGING,
    payload: {}
});