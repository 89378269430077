import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Alarm() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
          <defs>
              {/* <style>.cls-5,.cls-2{stroke-miterlimit:10;}.cls-2{stroke:#c23335;stroke-width:9.27px;}</style> */}
          </defs>
          <rect fill="#fff" x="-.35" y="-1.78" width="301.78" height="301.78"/>
          <path fill="#1a1a1a" d="m147.73,81.21c-43.78,4.56-75.75,43.32-71.27,86.4,4.49,43.08,43.76,74.42,87.54,69.85s75.75-43.32,71.27-86.4c-4.49-43.08-43.76-74.41-87.54-69.85Zm15.56,149.39c-40,4.17-75.88-24.41-79.97-63.71-4.09-39.3,25.12-74.66,65.12-78.82,40-4.17,75.88,24.41,79.97,63.71,4.09,39.3-25.12,74.66-65.12,78.82Z"/>
          <path fill="#1a1a1a" d="m220.89,80.48c-21.34-17.01-48.16-24.81-75.52-21.96-27.36,2.85-52,16.01-69.37,37.06-17.39,21.06-25.42,47.5-22.61,74.44,2.28,21.85,11.46,41.91,26.25,57.79-1.98,3.2-7.27,11.76-10.25,16.59-3.61,5.87-2.78,12.64,2.03,16.48,2.45,1.95,5.44,2.75,8.46,2.44,2.87-.3,5.75-1.6,8.21-3.87,3.47-3.2,11.41-10.52,14.36-13.23,19.08,11.37,41.31,16.29,63.92,13.94,22.46-2.34,43.07-11.63,59.35-26.51,3.71,2.21,12.48,7.43,16.4,9.76,2.87,1.71,5.96,2.39,8.83,2.09,3.02-.31,5.78-1.71,7.78-4.13,3.92-4.75,3.34-11.55-1.41-16.54-3.72-3.92-10.19-10.7-13.04-13.7,11.41-18.7,16.38-40.41,14.08-62.46-2.81-26.94-16.11-51.15-37.47-68.18Z"/>
          <path fill="#c23335" d="m83.41,254.38c-2.09,1.93-5.29,3.03-7.69,1.11-2.51-2-1.82-5.27-.46-7.47,2.62-4.25,7.01-11.36,9.38-15.2,1.99,1.86,4.06,3.65,6.21,5.37,1.83,1.46,3.7,2.84,5.61,4.17-3.55,3.27-10.01,9.22-13.05,12.02Z"/>
          <ellipse fill="#fff" cx="155.22" cy="158.3" rx="92.51" ry="90.9" transform="translate(-15.56 16.93) rotate(-5.95)"/>
          <path fill="#c23335" d="m252.32,229.57c1.78,1.87,3.13,4.93,1.09,7.41-1.96,2.37-5.31,1.96-7.75.5-3.44-2.05-10.62-6.32-14.83-8.83,1.7-1.78,3.35-3.64,4.93-5.55,1.63-1.97,3.18-4,4.64-6.06,3.18,3.34,8.63,9.06,11.93,12.53Z"/>
          <path d="m50.55,116.64l41.88-50.8c.59-.71.87-1.63.77-2.55-.1-.92-.56-1.76-1.28-2.34-15.66-12.47-38.77-10.05-51.5,5.39-6.19,7.5-9.04,16.92-8.03,26.52,1.01,9.6,5.75,18.22,13.36,24.28.74.59,1.64.82,2.51.73s1.7-.51,2.3-1.24Z"/>
          <path fill="#c23335" d="m39.24,92.15c-.81-7.74,1.5-15.35,6.5-21.41,5.18-6.29,12.48-9.92,20.07-10.71,6.47-.67,13.16.72,18.94,4.3l-37.24,45.17c-4.67-4.68-7.57-10.72-8.26-17.34Z"/>
          <path fill="#1a1a1a" d="m248.97,44.63c-15.65-12.49-38.75-10.1-51.51,5.34-.59.71-.87,1.63-.77,2.55.1.92.56,1.76,1.28,2.34l51.45,41.07c.74.59,1.64.83,2.51.73s1.7-.51,2.3-1.23c6.2-7.5,9.06-16.91,8.07-26.51-.99-9.6-5.73-18.23-13.33-24.29Z"/>
          <path fill="#c23335" d="m250.93,88.3l-45.75-36.52c10.68-10.2,27.7-11.18,39.49-1.77,6.14,4.9,9.97,11.87,10.77,19.62.69,6.62-.91,13.14-4.51,18.67Z"/>
          <path fill="#1a1a1a" d="m189.57,112.26l-29.57,35.82c-1.66-.59-3.49-.84-5.36-.64-2.06.21-3.95.93-5.56,2.02l-35.64-28.41c-1.49-1.19-3.66-.94-4.85.55-1.19,1.49-.94,3.66.55,4.84l35.55,28.35c-.75,1.78-1.07,3.76-.86,5.81.68,6.56,6.64,11.33,13.28,10.64,6.64-.69,11.49-6.59,10.8-13.15-.23-2.23-1.07-4.25-2.35-5.92l29.31-35.51c1.21-1.47,1-3.64-.46-4.85-1.47-1.21-3.64-1-4.85.46Zm-33.17,52.12c-2.86.3-5.42-1.72-5.71-4.5-.29-2.78,1.8-5.28,4.66-5.58,2.86-.3,5.42,1.72,5.71,4.5.29,2.78-1.8,5.28-4.66,5.58Z"/>
          <ellipse fill="none" stroke="#000" strokeWidth="6.18px" cx="155.22" cy="158.3" rx="84.46" ry="82.99" transform="translate(-15.56 16.93) rotate(-5.95)"/>
          <path fill="#1a1a1a" d="m33.74,177.93c-1.21-7.82-1.62-15.75-1.22-23.58.1-1.97-1.42-3.66-3.39-3.76-1.97-.1-3.66,1.42-3.76,3.39-.43,8.32,0,16.75,1.29,25.05,1.48,9.56,4.08,18.92,7.73,27.8.62,1.5,2.14,2.36,3.67,2.2.33-.03.67-.12,1-.25,1.83-.75,2.7-2.84,1.95-4.67-3.44-8.36-5.89-17.17-7.28-26.17Z"/>
          <path fill="#1a1a1a" d="m14.11,180.94c-1.91-12.32-2.09-24.78-.54-37.04.25-1.96-1.14-3.75-3.1-4-1.96-.25-3.75,1.14-4,3.1-1.63,12.92-1.44,26.05.57,39.03,2.28,14.69,6.81,28.8,13.48,41.96.69,1.36,2.13,2.09,3.56,1.94.42-.04.85-.16,1.25-.37,1.76-.89,2.47-3.05,1.57-4.81-6.33-12.49-10.63-25.88-12.79-39.82Z"/>
          <path fill="#1a1a1a" d="m267.97,126.07c-1.91.51-3.05,2.47-2.54,4.38,2.02,7.58,3.26,15.42,3.69,23.33.5,9.1-.07,18.22-1.7,27.11-.36,1.94.93,3.81,2.87,4.17.34.06.68.07,1.01.04,1.54-.16,2.86-1.31,3.15-2.92,1.73-9.44,2.34-19.13,1.81-28.8-.46-8.39-1.78-16.72-3.92-24.77-.51-1.91-2.47-3.05-4.38-2.54Z"/>
          <path fill="#1a1a1a" d="m288.57,113.96c-.65-1.87-2.69-2.86-4.56-2.21-1.87.65-2.86,2.69-2.21,4.56,4.05,11.67,6.45,23.9,7.14,36.35.77,14.08-.66,28.08-4.27,41.61-.51,1.91.63,3.87,2.54,4.38.43.12.87.15,1.29.1,1.44-.15,2.7-1.16,3.09-2.64,3.8-14.25,5.32-29.01,4.5-43.84-.72-13.11-3.25-26-7.52-38.3Z"/>
      </svg>
    </SvgIcon>
  );
}