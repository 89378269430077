import moment from "moment"
import { Time } from "../../common/MomentTime"
import { meetingStartsWithin } from "../../common/constants"
import { addAltHostUpdate } from "../../../DataAccessLayer/services"
import { patchData } from "../../../DataAccessLayer"
import { toast } from "react-toastify"

//Θ(1) Check if the meeting starts within next 30 mins
export const isMeetingReadyToStart = (meeting) => {
    return meeting?.start_url && moment.duration(Time.University(meeting.start_time).diff(new Time.University())).asMinutes() <= meetingStartsWithin
}

//Θ(1) Checks if the user is already a host or alternative host
const isUserHost = (user, meetingDetails) => {
    const isHost = user === meetingDetails.host_email;

    const altHosts = meetingDetails.settings.alternative_hosts.split(',');

    const isAltHost = altHosts.includes(user);

    return isHost || isAltHost;
}

//Θ(1) Starts meetings if teaching and join if taking
export const startMeeting = (email, meetingDetails, course) => {
    const isHost = isUserHost(email, meetingDetails);
    if(course.isTeaching && !isHost) {
        addHosts(email, meetingDetails, course)
        .catch(err => {
            toast.error('Unable to join meeting as host, joined as guest instead.')
        })
        .finally(_ => {
            window.open(meetingDetails?.start_url, "_blank");
        })
    } 
    else if(course.isTeaching && isHost) {
        window.open(meetingDetails?.start_url, "_blank");
    }
    else {
        window.open(meetingDetails?.join_url, "_blank");
    }
}

//Θ(1) adds alternative host in the meeting
export const addHosts = (email, meetingDetails, course) => {
    return new Promise((resolve, reject) => {
        meetingDetails = meetingDetails;

        let crns = course.CRNS.split(',');
        let body = {
            meetingId: meetingDetails.id,
            email,
            altHosts: meetingDetails.settings.alternative_hosts,
            crn: crns[0],
            termCode: course.SSBSECT_TERM_CODE
        }

        patchData(addAltHostUpdate, body, true)
        .then(result => {
            resolve(result)
        })
        .catch(err => {
            reject(err)
            console.log(err);
        })
    })
}