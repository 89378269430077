import React, {Component} from 'react';
import {Card, CardActionArea, Typography} from '@mui/material';
import WithRouter from '../Layout/Router/WithRouter';

class PageNavigationCard extends Component {
    render() {
        const {page, title} = this.props;
        return (
            <Card
                id={`adminPanel__card_${page}`}
                sx={{height: '5rem', textAlign: 'center'}}
            >
                <CardActionArea
                    id={`adminPanel__buttonCard_navigate${page}`}
                    onClick={() => this.props.navigate(`/admin/${page}`)}
                >
                    <Typography id={`adminPanel__text_${page}`} sx={{mt: 2}}>
                        {title}
                    </Typography>
                </CardActionArea>
            </Card>
        );
    }
}

export default WithRouter(PageNavigationCard);
