import React, { Component } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { FormButton } from '../../../../../common/Form/FormButton';
import { connect } from 'react-redux';

class Menu extends Component {
    state = {  } 

    getTermTitle = (terms) => {
        const termDetails = terms?.filter(termDetails => {
            return termDetails.code === this.props.course.SSBSECT_TERM_CODE
        });

        let termTitle;
        if(termDetails.length) {
            termTitle = termDetails[0]?.title;
            termTitle = termTitle?.toLowerCase();
            termTitle = termTitle?.charAt(0)?.toUpperCase() + termTitle.slice(1);
        }

        return termTitle ?? ''
    }

    render() {
        return <React.Fragment>
            {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8 }}> */}
                <Grid container gap={2} alignItems='start' justifyContent='center' padding={{xs: 4}}>
                    <Grid item>
                        <Card sx={{ maxWidth: 345 }} elevation={0}>
                            <CardActionArea className="myOdu__graphicButton mb-2" onClick={this.props.onToggleClassMeetings} disabled={!this.props.course.isClassScheduled || this.props.isClassMeetingsScheduled}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image="/img/class.svg"
                                    alt="Person infront of a calendar with a backpack"
                                    sx={{objectFit: 'fill'}}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h3" textAlign={'center'}>
                                        Class Meeting
                                    </Typography>
                                    <Typography variant="smaller">
                                        Create meeting(s) for the class according to the class schedule for the entire semester.
                                    </Typography>
                                    
                                </CardContent>
                            </CardActionArea>
                            {
                                this.props.isClassMeetingsScheduled && this.props.course.isClassScheduled &&
                                <Typography sx={{marginTop: 2}} className='myOdu__info' variant="smaller" color="">
                                    {
                                        'Class meetings for '
                                    }
                                    <strong>
                                        {
                                            this.props.course.SUBJECT_CODE + ' ' +
                                            this.props.course.COURSE_NUMBER
                                        }
                                    </strong>
                                    {
                                        ' have already been scheduled for ' +
                                        this.getTermTitle(this.props.terms) + '.'
                                    }
                                </Typography>
                            }
                            {
                                !this.props.course.isClassScheduled &&
                                <Typography sx={{marginTop: 2}} className='myOdu__info' variant="smaller" color="">
                                    {
                                        'Class meetings are only available for classes with scheduled meeting times.'
                                    }
                                </Typography>
                            }
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card sx={{ maxWidth: 345 }} elevation={0}>
                            <CardActionArea onClick={this.props.onToggleAdhoc}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image="/img/adhoc.svg"
                                    alt="Person infront of a calendar with the date circled"
                                    sx={{objectFit: 'fill'}}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h3" textAlign={'center'}>
                                        Adhoc Meeting(s)
                                    </Typography>
                                    <Typography variant="smaller">
                                        Create custom meeting(s) which can repeat daily, weekly or monthly.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} textAlign={'end'}>
                        <FormButton 
                            label={"Cancel"}
                            id = "cancelScheduleMeeting"
                            onClick={() => this.props.toggleIsScheduleMeeting(false)}
                            className = "scheduleMeeting"
                        />
                    </Grid>
                </Grid>
            {/* </Box> */}
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        course: state.CCTReducer.course,
        terms: state.termsReducer.terms,
        isClassMeetingsScheduled: state.CCTReducer.meetings.isClassMeetingsScheduled
    }
}
 
export default connect(mapStateToProps)(Menu);