import React, {Component} from 'react';
import {List, ListItem, Stack, Typography} from '@mui/material';

class AlertProhibits extends Component {
  render() {
    return (
      <Stack id={this.props.id+"__textStack"} direction="column">
        <Typography
          id={this.props.id+"__text_prohibits"}
          sx={{
            color: 'primary.main',
            fontWeight: '600'
          }}
          variant="small"
          component="h4"
        >
          Prohibits
        </Typography>
        <List id={this.props.id+"__list"} sx={{pt: 0, pl: 2, listStyleType: 'disc'}}>
          {this.props.prohibits.map((prohibit, index) => {
            return (
              <ListItem
                id={this.props.id+ '__prohibitItem_' + index}
                key={this.props.id+ '__prohibitItem_' + index}
                sx={{p: 0, display: 'list-item'}}
              >
                <Typography variant="small" id={this.props.id+ '__text_prohibit_' + index}>{prohibit}</Typography>
              </ListItem>
            );
          })}
        </List>
      </Stack>
    );
  }
}

export default AlertProhibits;
