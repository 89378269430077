import React, {Component} from 'react';
import {
    List,
    ListItem,
    Box,
    Link,
    Stack,
    Typography,
    IconButton
} from '@mui/material';
import PlaceMap from './PlaceMap';
import PortalTooltip from './PortalTooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleInfo} from '@fortawesome/pro-light-svg-icons';
import {connect} from 'react-redux';

class PlaceDetails extends Component {
    render() {
        return (
            <Box
                className="placeDetails"
                sx={{p: 0, m: 0}}
                id={this.props.id + '__box_wrapper_placeDetails'}
            >
                <Stack
                    id={
                        this.props.id +
                        '__stack_' +
                        this.props.data.location.id +
                        '_mapAndMoreInfo'
                    }
                >
                    {this.props.data.location.mapIframeEmbedUrl &&
                        this.props.areMapsDisplayed && (
                            <PlaceMap
                                id={this.props.id + '__placeMap'}
                                mapId={this.props.data.location.mapId ?? ''}
                                mapIframeEmbedUrl={
                                    this.props.data.location.mapIframeEmbedUrl
                                }
                            />
                        )}

                    <Stack
                        direction="column"
                        justifyContent="space-between"
                        sx={{mt: 1, px: 1}}
                        id={this.props.id + '__stack_wrapper_moreInfo'}
                    >
                        {this.props.data.location.url ? (
                            <Stack
                                direction={'row'}
                                alignContent={'center'}
                                id={this.props.id + '__stack_moreInfo'}
                            >
                                <PortalTooltip
                                    id={
                                        this.props.id +
                                        '__tooltip__moreInfo_link'
                                    }
                                    title={
                                        'Open ' +
                                        this.props.data.location.title +
                                        ' webpage in new window'
                                    }
                                >
                                    <Link
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '0.85rem'
                                        }}
                                        href={this.props.data.location.url}
                                        underline={'hover'}
                                        target="_blank"
                                        id={this.props.id + '__link_moreInfo'}
                                    >
                                        {this.props.data.location.title}
                                    </Link>
                                </PortalTooltip>
                                <PortalTooltip
                                    title={
                                        'Open ' +
                                        this.props.data.location.title +
                                        ' webpage in new window'
                                    }
                                    id={
                                        this.props.id +
                                        '__tooltip_moreInfo_iconButton'
                                    }
                                >
                                    <IconButton
                                        sx={{ml: 1, p: 0}}
                                        component={Link}
                                        href={this.props.data.location.url}
                                        target="_blank"
                                        size="small"
                                        id={
                                            this.props.id +
                                            '__iconButton_moreInfo'
                                        }
                                    >
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faCircleInfo}
                                            size="sm"
                                            id={
                                                this.props.id +
                                                '__icon__moreInfo'
                                            }
                                        />
                                    </IconButton>
                                </PortalTooltip>
                            </Stack>
                        ) : (
                            <Typography
                                sx={{fontWeight: 600, fontSize: '0.85rem'}}
                                id={this.props.id + '__title_moreInfo'}
                            >
                                {this.props.data.location.title}
                            </Typography>
                        )}
                        {this.props.data.location.mapUrl ? (
                            <Link
                                variant="smallest"
                                href={this.props.data.location.mapUrl}
                                sx={{fontStyle: 'italic'}}
                                underline="hover"
                                title="Opens in new window"
                                target="_blank"
                                id={this.props.id + '__link_address'}
                            >
                                {this.props.data.location.address}
                            </Link>
                        ) : (
                            <Typography
                                variant="smallest"
                                sx={{fontStyle: 'italic}'}}
                                id={this.props.id + '__text_address'}
                            >
                                {this.props.data.location.address}
                            </Typography>
                        )}
                    </Stack>

                    {this.props.data.location.hours && (
                        <List
                            className="operatingHours"
                            id={this.props.id + '__list_hours'}
                        >
                            {this.props.data.location.hours.map(
                                (item, index) => (
                                    <ListItem
                                        key={
                                            this.props.id +
                                            '__listItem_hours_' +
                                            index
                                        }
                                        id={
                                            this.props.id +
                                            '__listItem_hours_' +
                                            index
                                        }
                                    >
                                        <Stack
                                            direction="row"
                                            justifyContent={'space-between'}
                                            spacing={2}
                                            sx={{width: '100%', px: 1}}
                                            id={
                                                this.props.id +
                                                '__listItem_hours_stack_wrapper_' +
                                                index
                                            }
                                        >
                                            <Typography
                                                variant="smallest"
                                                id={
                                                    this.props.id +
                                                    '__listItem_hours_daysTitle_' +
                                                    index
                                                }
                                            >
                                                {item.title}
                                                {item.isOnline && (
                                                    <Typography
                                                        sx={{
                                                            fontStyle: 'italic'
                                                        }}
                                                        variant="smallest"
                                                        id={
                                                            this.props.id +
                                                            '__listItem_hours_title_isOnline_' +
                                                            index
                                                        }
                                                    >
                                                        {' '}
                                                        (Online){' '}
                                                    </Typography>
                                                )}
                                            </Typography>
                                            <Typography
                                                variant="smallest"
                                                id={
                                                    this.props.id +
                                                    'listItemHours_title_times_' +
                                                    index
                                                }
                                            >
                                                {item.times}
                                            </Typography>
                                        </Stack>
                                    </ListItem>
                                )
                            )}
                        </List>
                    )}
                </Stack>
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    areMapsDisplayed:
        state.preferencesReducer.preferences.Settings?.widget__placesAndHours
            ?.areMapsDisplayed ?? true
});

export default connect(mapStateToProps)(PlaceDetails);
