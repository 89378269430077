import React, {Component} from 'react';
import PortalTooltip from '../PortalTooltip';
import {Box, Link, Typography, Stack, Grid, IconButton} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart as faHeart_solid} from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faHeart, faLoader } from '@fortawesome/pro-light-svg-icons';

class App extends Component {
    state = {

    }

    render() {
        const app = this.props.app;
        return <React.Fragment>
            {/* TODO: deal with mobile app links, icons later. */}
            <Grid container>
                {
                    this.props.showHeart &&
                    <Grid item xs={'auto'}>
                        <PortalTooltip title={ this.props.isSelected ? 'Remove ' + this.props.app?.name + ' from favorite apps' : 'Add to favorite apps' } >
                            {/* //TODO: call string cleaner here */}
                            {
                                <IconButton
                                    onClick={() => {this.props.updateAppSelection(this.props.app)}}
                                    size="small"
                                    id={this.props.id + '__applicationCard__button_favorite_' + this.props.app?.name.replace( ' ', '' )}
                                >
                                    {!this.props?.loadingApps[app.id] ? (
                                        <FontAwesomeIcon icon={this.props.isSelected ? faHeart_solid : faHeart} className={this.props.isSelected ? "myOdu__favorite" : ""} size="small" />
                                    ) : (
                                        <FontAwesomeIcon icon={faLoader} className="myOdu__rotate" size="small" />
                                    )}
                                </IconButton>
                            }
                        </PortalTooltip>
                    </Grid>
                }
                <Grid item>
                    <PortalTooltip
                        title={
                            <Stack
                                direction="row"
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                            >
                                {app.icon && (
                                    <Box
                                        className="text-center"
                                        sx={{
                                            border: '1px solid black',
                                            backgroundColor: 'white',
                                            width: '3rem',
                                            minWidth: '3rem',
                                            maxWidth: '3rem',
                                            padding: '.25rem',
                                            mr: 1
                                        }}
                                    >
                                        <img
                                            src={'/img/logos/' + app.icon}
                                            alt={app.name}
                                        />
                                    </Box>
                                )}
                                <Box>
                                    <Typography
                                        variant="h6"
                                        component="h4"
                                        sx={{mt: 0, pt: 0}}
                                    >
                                        {app?.name}
                                    </Typography>
                                    <Typography component="p" variant="smaller">
                                        {app?.desc}
                                    </Typography>
                                    <Typography
                                        component="p"
                                        variant="smaller"
                                        sx={{mt: 2}}
                                    >
                                        {' '}
                                        Link opens in new window.
                                    </Typography>
                                </Box>
                            </Stack>
                        }
                    >
                        {app?.link ? (
                            <Link
                                href={app?.link}
                                variant="small"
                                target="_blank"
                                underline={'always'}
                            >
                                {app.name}
                            </Link>
                        ) : (
                            <Typography variant="small">app.name</Typography>
                        )}
                    </PortalTooltip>
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

export default App;