import React, {Component} from 'react';
import {Box, Typography, Stack, Container, Link} from '@mui/material';
import {FormButton} from '../common/Form/FormButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFaceAnxiousSweat,
    faCopy,
    faFaceSmileRelaxed
} from '@fortawesome/pro-light-svg-icons';
import {FormTextField} from '../common/Form/FormTextField';
import {patchDataPublic} from '../../DataAccessLayer/index';
import {cognitoErrors} from '../../DataAccessLayer/services';
import PortalModal from '../common/PortalModal';
import PortalSnackbar from '../common/PortalSnackbar';

const emailRegex = /^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+)$/i;
const nameRegex = /^[a-zA-Z '-]+$/;

class CognitoError extends Component {
    state = {
        hasMidas: true,
        midas: '',
        firstName: '',
        lastName: '',
        email: '',
        errors: {},
        cognitoError: false,
        isSubmitted: false,
        snackBar: {}
    };

    handleChange = (field, value) => {
        this.setState({[field]: value}, this.validateFields);
    };

    componentDidMount() {
        this.setState({cognitoError: this.props.error});
    }

    onCopyText = text => {
        navigator.clipboard.writeText(text);
        this.setState({
            snackBar: {
                isOpen: true,
                message: 'Issue Copied',
                success: true
            }
        });
    };

    validateFields = () => {
        const {midas, firstName, lastName, email, hasMidas} = this.state;
        const errors = {};

        if (hasMidas) {
            if (!midas) {
                errors.midas = 'MIDAS is required';
            } else if (midas.length > 8) {
                errors.midas = 'Max length is 8';
            }
        } else {
            if (!firstName) {
                errors.firstName = 'First Name is required';
            } else if (!nameRegex.test(firstName)) {
                errors.firstName = 'Invalid first name';
            }

            if (!lastName) {
                errors.lastName = 'Last Name is required';
            } else if (!nameRegex.test(lastName)) {
                errors.lastName = 'Invalid last name';
            }

            if (!email) {
                errors.email = 'Email is required';
            } else if (!emailRegex.test(email)) {
                errors.email = 'Invalid email address';
            }
        }

        this.setState({errors});
        return errors;
    };

    handleSubmit = async event => {
        event.preventDefault();

        const errors = this.validateFields();
        if (Object.keys(errors).length > 0) {
            this.setState({errors});
            return;
        }

        const {midas, firstName, lastName, email} = this.state;

        try {
            await patchDataPublic(cognitoErrors, {
                state: this.state.cognitoError.state,
                midas,
                firstName,
                lastName,
                email
            });
            this.setState({isSubmitted: true});
        } catch (err) {
            console.error(err);
        }
    };

    handleClose = () => {
        this.setState({cognitoError: false});
    };

    render() {
        const {
            hasMidas,
            midas,
            firstName,
            lastName,
            email,
            errors,
            cognitoError,
            isSubmitted,
            snackBar
        } = this.state;

        return (
            <PortalModal
                isOpen={cognitoError}
                maxWidth="sm"
                onClose={this.handleClose}
                isCloseable={true}
                title="Report Issue"
            >
                <Container sx={{padding: 1}}>
                    <PortalSnackbar
                        isOpen={snackBar.isOpen}
                        message={snackBar.message}
                        success={snackBar.success}
                        onClose={() => this.setState({snackBar: {}})}
                    />
                    <Stack alignItems="center" spacing={2}>
                        {isSubmitted ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faFaceSmileRelaxed}
                                    fixedWidth
                                    size="6x"
                                />

                                <Typography
                                    component="h2"
                                    className="myOdu__errorCode"
                                >
                                    Thank You!
                                </Typography>
                                <Typography
                                    component="p"
                                    className="myOdu__errorCode"
                                >
                                    To ensure your issue is promptly addressed,
                                    please submit a{' '}
                                    <Link
                                        href="https://oduprod.service-now.com/now/nav/ui/classic/params/target/incident.do"
                                        target="_blank"
                                    >
                                        help desk ticket.
                                    </Link>{' '}
                                    Copy and paste this text into the
                                    description of the ticket:
                                </Typography>

                                <Stack direction="row" width="100%" spacing={1}>
                                    <FormTextField
                                        value={cognitoError.description}
                                        onChange={e => null}
                                        inputGridProps={{xl: 12}}
                                    />
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        onClick={() =>
                                            this.onCopyText(
                                                JSON.stringify(cognitoError)
                                            )
                                        }
                                        style={{cursor: 'pointer'}}
                                    />
                                </Stack>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon
                                    icon={faFaceAnxiousSweat}
                                    fixedWidth
                                    size="6x"
                                />
                                <Typography
                                    component="h2"
                                    className="myOdu__errorCode"
                                >
                                    Oh No!
                                </Typography>
                                <Typography
                                    component="h3"
                                    className="myOdu__errorCode"
                                >
                                    Something went wrong when you tried to log
                                    in!
                                </Typography>
                                <Typography
                                    component="p"
                                    className="myOdu__errorCode"
                                >
                                    Let us know who you are so we can help.
                                </Typography>
                                <form
                                    onSubmit={this.handleSubmit}
                                    style={{width: '100%'}}
                                >
                                    <Stack spacing={2}>
                                        {hasMidas ? (
                                            <FormTextField
                                                label="MIDAS"
                                                value={midas}
                                                onChange={e =>
                                                    this.handleChange(
                                                        'midas',
                                                        e
                                                    )
                                                }
                                                error={!!errors.midas}
                                                errorText={errors.midas}
                                                required
                                            />
                                        ) : (
                                            <>
                                                <FormTextField
                                                    label="First Name"
                                                    value={firstName}
                                                    onChange={e =>
                                                        this.handleChange(
                                                            'firstName',
                                                            e
                                                        )
                                                    }
                                                    error={!!errors.firstName}
                                                    errorText={errors.firstName}
                                                    required
                                                />
                                                <FormTextField
                                                    label="Last Name"
                                                    value={lastName}
                                                    onChange={e =>
                                                        this.handleChange(
                                                            'lastName',
                                                            e
                                                        )
                                                    }
                                                    error={!!errors.lastName}
                                                    errorText={errors.lastName}
                                                    required
                                                />
                                                <FormTextField
                                                    label="Email"
                                                    value={email}
                                                    onChange={e =>
                                                        this.handleChange(
                                                            'email',
                                                            e
                                                        )
                                                    }
                                                    error={!!errors.email}
                                                    errorText={errors.email}
                                                    required
                                                />
                                            </>
                                        )}

                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            width="100%"
                                        >
                                            <Link
                                                component="button"
                                                type="button"
                                                onClick={() =>
                                                    this.setState(
                                                        {hasMidas: !hasMidas},
                                                        this.validateFields
                                                    )
                                                }
                                            >
                                                {hasMidas
                                                    ? `I don't have MIDAS ID`
                                                    : `Wait! I do have a MIDAS ID after all`}
                                            </Link>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            width="100%"
                                        >
                                            <FormButton
                                                sx={{mt: 2}}
                                                label="Send Info"
                                                type="submit"
                                                disabled={
                                                    hasMidas
                                                        ? !!errors.midas
                                                        : !!errors.firstName ||
                                                          !!errors.lastName ||
                                                          !!errors.email
                                                }
                                            />
                                        </Box>
                                    </Stack>
                                </form>
                            </>
                        )}
                    </Stack>
                </Container>
            </PortalModal>
        );
    }
}

export default CognitoError;
