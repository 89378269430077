import { Box, Typography, Button,	DialogTitle, DialogContent, Dialog, DialogActions, } from '@mui/material';
import React, {Component} from 'react';
import PortalModal from '../../common/PortalModal';
import {toast} from 'react-toastify';
import { set } from 'lodash';



class PortalModalTests extends Component {
    state = {
        isModalOpen: false,
        severity: '',
        isImperative: false,
        isNoHeader: false,
        isCloseable: true,
        isFullScreen: false,
    };

    toggleIsModalOpen = () => {
        console.log("toggleIsModalOpen");
        this.setState({isModalOpen: !this.state.isModalOpen});
    };

    onClickPrimary = () => {
        console.log('primary button clicked');
    };

    onClickSecondary = () => {
        console.log('secondary button clicked');
    };

    setSeverity = (severity) => {
        console.log("severity");
        this.setState({severity: severity});
    }

    setIsImperative = (isImperative) => {
        console.log("isImperative");
        this.setState({isImperative: isImperative});
    }

    setIsNoHeader = (isNoHeader) => {
        console.log("setIsNoHeader");
        this.setState({isNoHeader: isNoHeader});
    }

    setIsFullScreen = (isFullScreen) => {
        console.log("setIsFullScreen" + isFullScreen);
        this.setState({isFullScreen: isFullScreen});
    }


    onClickRegular = () => {
        console.log("clickedRegular");
        this.setSeverity(""); //none
        this.setIsImperative(false);
        this.setIsNoHeader(false);
        this.setIsFullScreen(false);
        this.toggleIsModalOpen();
    }

    onClickNoHeader = () => {
        console.log("clickedNoHeader");
        this.setSeverity(""); //none
        this.setIsImperative(false);
        this.setIsNoHeader(true);
        this.setIsFullScreen(false);
        this.toggleIsModalOpen();
    }

    onClickFullScreen = () => {
        console.log("clickedFullScreen");
        this.setSeverity(""); //none
        this.setIsImperative(false);
        this.setIsNoHeader(false);
        this.setIsFullScreen(true);
        this.toggleIsModalOpen();
    }

    render() {
        return (
            <React.Fragment>    
                <Button className="myOdu__button primary me-1" onClick={this.onClickRegular}>Regular</Button>
                <Button className="myOdu__button primary me-1"  onClick={this.onClickNoHeader}>NoHeader</Button>
                <Button className="myOdu__button primary me-1"  onClick={this.onClickFullScreen}>FullScreen</Button>
                {/* <Button className="myOdu__button primary me-1" onClick={this.onClickRegular}>Severity="success"</Button>
                <Button className="myOdu__button primary me-1"  onClick={this.onClickRegular}>Severity="success imperative"</Button>
                <Button className="myOdu__button primary me-1"  onClick={this.onClickRegular}>Severity="error"</Button>
                <Button className="myOdu__button primary me-1" onClick={this.onClickRegular}>Severity="error imperative"</Button> */}


                <PortalModal
                    // severity="success imperative"  //no idea why someone would use this
                    // severity="error imperative"  //ONLY in extreme cases, run this by UX dev before using
                    severity={this.state.severity + (this.state.isImperative? " imperative" : "")}
                    noHeader={this.state.isNoHeader}
                    maxWidth="sm"
                    onClose={this.toggleIsModalOpen}
                    onPrimaryClick={this.onClickPrimary}
                    onSecondaryClick={this.onClickSecondary}
                    isOpen={this.state.isModalOpen}
                    title="Test Modal Title"
                    // subTitle="Test Modal Subtitle"
                    id="testModalButton"
                    primaryButtonTitle="Add"
                    secondaryButtonTitle="Secondary Button with very long title to test wrapping and layout design"
                    isCloseable={this.state.isCloseable}
                    isFullScreen={this.state.isFullScreen}
                    // noHeader
                >
                    <Typography variant="h5" component="h3">
                        H3 Nothing within a modal should be bigger than an
                        H3.
                    </Typography>
                    Exercitation amet amet esse ut elit. Sint occaecat
                    pariatur proident exercitation veniam cillum ea.
                </PortalModal>
            </React.Fragment>
        );
    }
}

export default  PortalModalTests;
