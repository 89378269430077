import { faX } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Snackbar } from "@mui/material";
import React, { Component } from "react";

class PortalSnackbar extends Component {
    state ={

    }

    render() {
        return (
            <React.Fragment>
                <Snackbar
                    id={this.props.id+"__snackbar_notification"}
                    anchorOrigin={this.props.anchorOrigin ? this.props.anchorOrigin : { vertical: 'top', horizontal: 'right' }}
                    open={this.props.isOpen}
                    onClose={this.props.onClose}
                    message={this.props.message}
                    action={
                        <React.Fragment >
                            <IconButton
                                id={this.props.id+"__portalSnackbar_button_close"}
                                size="small"
                                onClick={this.props.onClose}
                            >
                                <FontAwesomeIcon id={this.props.id+"__portalSnackbar_icon_close"} icon={faX} color="white" />
                            </IconButton>
                        </React.Fragment>
                    }
                    className={this.props.success ? 'myOdu__snackbar_success' : 'myOdu__snackbar_error'}
                />
            </React.Fragment>
        );
    }
}

export default PortalSnackbar;
