import React from 'react';

import {Controller} from 'react-hook-form';
import {Grid, FormControlLabel, Checkbox, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquare, faSquareCheck} from '@fortawesome/pro-light-svg-icons';

export const FormControlledCheckbox = ({
  name,
  control,
  label,
  rules,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, formState: {errors}}) => {
        return (
          <FormCheckbox
            {...field}
            label={label}
            error={!!_.get(errors, name)}
            errorText={_.get(errors, name)?.message}
            required={rules}
            {...props}
          />
        );
      }}
    />
  );
};

export const FormCheckbox = ({
  label,
  error,
  helperText,
  errorText,
  required,
  labelGridProps = {
    xs: 0,
    md: 3,
    lg: 4,
    xl: 3
  },
  inputGridProps = {xs: 12, md: 9, lg: 8, xl: 9},
  ...props
}) => {
  return (
    <Grid container alignItems={'flex-start'}>
      <Grid {...labelGridProps} textAlign="end"></Grid>
      <Grid item {...inputGridProps} alignItems={'flex-start'}>
        <FormControlLabel
          aria-label={props.ariaLabel || label}
          sx={{ml: 0, mr:0, pt:0}}
          control={
            <Checkbox
              {...props}
              checked={!!props.value}
              size="small"
              icon={<FontAwesomeIcon icon={faSquare} size="lg" />}
              checkedIcon={<FontAwesomeIcon icon={faSquareCheck} size="lg" />}
            />
          }
          label={label}
          labelPlacement="end"
        />

        {error ? (
          <Typography
            display="flex"
            justifyContent="flex-start"
            variant="caption"
            className="myOdu__error smaller"
          >
            {errorText}
          </Typography>
        ) : (
          <Typography
            display="flex"
            justifyContent="flex-start"
            variant="caption"
            className="myOdu__helper smaller"
          >
            {helperText}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
