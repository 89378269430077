import {Amplify, Auth} from 'aws-amplify';
import config from './config';

Amplify.configure(config);

//log in through shibboleth
export const logIn = async targetLocation => {
    Auth.federatedSignIn({
        provider: 'Shibboleth',
        customState:
            typeof targetLocation === 'string' ? targetLocation : undefined // Sometimes 'targetLocation' is received as an object of type 'SyntheticBaseEvent' when no parameter is passed to this method. To avoid passing that to the 'customState', we are checking for its type and passing it to 'customState' only if its of type 'string'. If its of type 'string', then that means it contains the path the user wishes to get redirected to after the successful login.
    }).catch(err => {
        console.log('error sign in ', err);

        return false;
    });
};

//log out
export const logOut = async () => {
    Auth.signOut().catch(err => {
        console.log('error sign out ', err);
        return false;
    });
};

// get jwt token for the logged-in user to authorize requests to API gateway
export const authorize = async () => {
    return new Promise((resolve, reject) => {
        Auth.currentSession()
            .then(session => {
                // console.log(session, session?.idToken, session?.idToken?.jwtToken)
                try {
                    resolve(session?.idToken?.jwtToken);
                } catch (err) {
                    reject('AWS Cognito token not found');
                }
            })
            .catch(err => {
                reject('invalid AWS Cognito session');
            });
    });
};

//test funtion to identify the logged in user
export const identifyUser = async () => {
    // authorize()
    const user = await Auth.currentUserInfo();
    return user ? parseUser(user) : {};
};

//parse the user object into an easy-to-use structure
export const parseUser = user => {
    return {
        midas: user?.attributes?.['custom:EPPN'],
        displayName: user?.attributes?.['custom:DISPLAY_NAME'],
        firstName: user?.attributes?.['custom:GIVEN_NAME'],
        lastName: user?.attributes?.['custom:FAMILY_NAME'],
        email: user?.attributes?.['email'],
        roles: user?.attributes?.['custom:ODU_ROLES']
            ?.slice(1, user?.attributes?.['custom:ODU_ROLES'].length - 1)
            .split(',')
            .reduce(
                (collection, item) => ({...collection, [item.trim()]: true}),
                {}
            ),
        groups: user?.attributes?.['custom:EDUPERSONENTITLEMENT']
            ?.replaceAll('[', '')
            .replaceAll(']', '')
            .split(',')
            .reduce(
                (collection, item) => ({...collection, [item.trim()]: true}),
                {}
            )
    };
};
