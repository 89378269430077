import { retry } from '@aws-amplify/core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControlLabel, Menu, MenuItem, Switch } from '@mui/material';
import React, { Component } from 'react';

class Categories extends Component {
    state = {

    }

    render() {
        return <React.Fragment>
            <Button
                variant='text'
                endIcon={<FontAwesomeIcon icon={faCaretDown} />}
                onClick={this.props.dropAnchor}
            >
                Categories
            </Button>
            <Menu
                anchorEl={this.props.anchor}
                disableScrollLock
                open={this.props.isOpen}
                onClose={this.props.pullAnchor}
                onClick={this.props.onMenuClick}
            >
                <MenuItem>
                    <FormControlLabel
                        label={'ODU'}
                        control={<Switch checked = {this.props.isShowODU} onChange={this.props.onCategoryChange} name='isShowODU' size='small' />}
                    />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel
                        label={'VHS'}
                        control={<Switch checked = {this.props.isShowEVMS} onChange={this.props.onCategoryChange} name='isShowEVMS' size='small' />}
                    />
                </MenuItem>
            </Menu>
        </React.Fragment>
    }
}

export default Categories;