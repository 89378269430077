export const SAVE_CHECKLISTS = 'SAVE_CHECKLISTS';
export const SAVE_CHECKLISTS_IS_ERROR = 'SAVE_CHECKLISTS_IS_ERROR';

export const saveChecklists = checklists => ({
    type: SAVE_CHECKLISTS,
    payload: {checklists}
});

export const saveChecklistsIsError = isError => ({
    type: SAVE_CHECKLISTS_IS_ERROR,
    payload: {isError}
});
