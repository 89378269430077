import React, {Component} from 'react';
import {Typography, Breadcrumbs, Link, Skeleton, Box} from '@mui/material';
import {checklistDetails} from '../../DataAccessLayer/services';
import {getData} from '../../DataAccessLayer';
import {Container} from 'reactstrap';
import DetailsTabs from './DetailsTabs';
import PortalAlert from '../common/PortalAlert';
import ApiErrorMessage from '../common/ApiErrorMessage';
import WithPropsRouter from '../Layout/Router/WithPropsRouter';

class Details extends Component {
    state = {
        isLoading: false,
        isError: false,
        details: null,
        code: window.location.pathname.split('/')[2]
    };

    componentDidMount() {
        this.loadChecklistDetails(this.state.code);
    }

    toggleIsLoading = isLoading => {
        this.setState({isLoading});
    };

    toggleIsError = value => {
        this.setState({isError: value});
    };

    /**
     * Fetches the checklist details
     * @param {string} code The id of the checklist
     */
    loadChecklistDetails = code => {
        this.toggleIsLoading(true);
        this.toggleIsError(false);
        getData(checklistDetails(code), true)
            .then(details => {
                this.setState({details});
            })
            .catch(err => {
                console.log(err);
                this.toggleIsError(true);
            })
            .finally(() => this.toggleIsLoading(false));
    };

    render() {
        const name =
            this.props.location?.state?.name ||
            this.state.details?.checklistDesc;
        return (
            <div className="myOdu__checklists">
                <Container 
                    id={"myChecklistsDetails__container_pageWrapper"}
                    className="myOdu__container secondaryPage"
                >
                    {name && (
                        <Box>
                            <Typography
                                id={"myChecklistsDetails__text_checklistTitle"}
                                component="h2"
                            >
                                {name}
                            </Typography>

                            <Breadcrumbs className="myOdu__breadcrumbs" id={"myChecklistsDetails__breadCrumbs"} aria-label="breadcrumb">
                                <Link
                                    href="/"
                                    id="checklist__link_dashboard"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    href="/checklists"
                                    id="checklist__link_checklistIndex"
                                >
                                    Checklists
                                </Link>
                                <Typography>
                                    {name}
                                </Typography>
                            </Breadcrumbs>
                        </Box>
                    )}

                    <Box className="wrapper">
                        {this.state.isLoading && <Skeleton variant="rectangular" />}

                        {!this.state.isLoading && this.state.isError && (
                            <PortalAlert severity="error" type="checklist">
                                <ApiErrorMessage
                                    reload={() => {
                                        this.loadChecklistDetails(this.state.code);
                                    }}
                                    widgetName={name ?? 'checklist'}
                                    isPage={true}
                                />
                            </PortalAlert>
                        )}

                        {!this.state.isLoading &&
                            !this.state.isError &&
                            this.state.details && (
                                <DetailsTabs
                                    items={this.state.details.itemsList ?? []}
                                    isLoading={this.state.isLoading}
                                    checklistTitle={
                                        this.state.details.checklistDesc
                                    }
                                />
                            )}
                    </Box>
                </Container>
            </div>
        );
    }
}

export default WithPropsRouter(Details);
