import * as React from 'react';
import { Card, Typography, Link, Box, IconButton, Grid, Stack, Chip} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faCircleInfo, faLoader } from '@fortawesome/pro-light-svg-icons';
import { faGripDots, faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import {faHeart as faHeart_solid} from '@fortawesome/pro-solid-svg-icons/faHeart';
import {isMobile} from 'react-device-detect';
import PortalTooltip from '../../../common/PortalTooltip';

export class AppCard extends React.Component {

    render(){
        return (   
            <Card sx={{ p:1, height: '100%', width: '100%', borderLeft: 'none', borderRight: 'none', borderTop: 'none'}} className={'myOdu__appCard active'} elevation={0}>
                <Grid container direction='row' gap={1} justifyContent={'flex-start'} alignItems={isMobile ? 'flex-start' : 'center'} wrap='nowrap'>
                    <Grid item xs={'auto'}>
                        <PortalTooltip title={ this.props.isSelected ? 'Remove ' + this.props.app?.name + ' from favorite apps' : 'Add to favorite apps' } >
                            {/* //TODO: call string cleaner here */}
                            <IconButton
                                onClick={() => {
                                    this.props.onUpdateAppSelection(this.props.app)
                                }} 
                                size="small"
                                id={ this.props.id + '__applicationCard__button_favorite_' + this.props.app?.name.replace( ' ', '' ) }
                            >
                                {!this.props?.loadingApps[this.props.app.id] ? (
                                    <FontAwesomeIcon icon={this.props.isSelected ? faHeart_solid : faHeart} className={this.props.isSelected ? "myOdu__favorite" : ""} size="small" />
                                ) : (
                                    <FontAwesomeIcon icon={faLoader} className="myOdu__rotate" size="small" />
                                )}
                            </IconButton>
                        </PortalTooltip>
                    </Grid>

                    <Grid item sx={{}} xs={'auto'} >
                        <Box className="wrapperBox"><Box sx={{ width: '40px'}}  className={'iconImage'}>
                            <img src={this.props.data?.icon ?? 'img/logos/genericApp.svg'} alt={this.props.app?.name ?? "application icon"} />
                        </Box></Box>
                    </Grid>

                    <Grid item container xs={10} alignItems={'flex-start'}>
                        <Stack>
                            <Typography component="h4" className="appCardTitle">
                                {this.props.app?.name}
                            </Typography>
                            <Typography component="p">
                                {this.props.app?.desc}
                            </Typography>
                            <Typography component="p">
                                Categories: {this.props.app.isODU && 'ODU'}{this.props.app.isODU && this.props.app.isEVMS && ', '} {this.props.app.isEVMS && 'VHS'}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Card>
        );
    }
}

export default AppCard;

