import React, { Component } from 'react';
import Adhoc from './Adhoc';
import ClassMeetings from './ClassMeetings';
import Menu from './Menu';
import { connect } from 'react-redux';

class ScheduleMeeting extends Component {
    state = {
        isAdhoc: this.props.isOpenAdhocSchedule,
        isClassMeeetings: this.props.isOpenClassSchedule,
        adhocPanelDetails: {}
    }

    onToggleAdhoc = () => {
        this.setState({isAdhoc: !this.state.isAdhoc})
    }

    onToggleClassMeetings = () => {
        this.setState({isClassMeeetings: !this.state.isClassMeeetings})
    }

    //Θ(1) Saves adhoc panel details to save the state when user revisits
    saveAdhocPanelDetails = (adhocPanelDetails) => {
        this.setState({
            adhocPanelDetails
        })
    }

    render() { 
        return <React.Fragment>
            {!this.state.isAdhoc && !this.state.isClassMeeetings && <Menu toggleIsScheduleMeeting = {this.props.toggleIsScheduleMeeting} onToggleAdhoc={this.onToggleAdhoc} onToggleClassMeetings={this.onToggleClassMeetings}/>}
            {(this.state.isAdhoc) && <Adhoc saveAdhocPanelDetails = {this.saveAdhocPanelDetails} adhocPanelDetails = {this.state.adhocPanelDetails} toggleIsScheduleMeeting = {this.props.toggleIsScheduleMeeting} onToggle={this.onToggleAdhoc} onTabChange = {this.props.onTabChange}/>}
            {(this.state.isClassMeeetings) && <ClassMeetings toggleIsScheduleMeeting = {this.props.toggleIsScheduleMeeting} onToggle={this.onToggleClassMeetings} onTabChange = {this.props.onTabChange}/>}
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        course: state.CCTReducer.course
    }
}
 
export default connect(mapStateToProps)(ScheduleMeeting);