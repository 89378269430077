import React, {Component} from 'react';
import {
  Box,
  Avatar,
  Link,
  Stack,
  Typography,
  Tooltip,
  Card,
  IconButton,
  Grid
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone, faEnvelope, faTrash, faCheck, faArrowUp, faArrowDown} from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import PortalTooltip from './PortalTooltip';
import { isProfileUrlValid } from '../Layout/Navigation';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { MOVE_CONTACT_DOWN, MOVE_CONTACT_UP, MOVE_WIDGET_DOWN, MOVE_WIDGET_UP } from './constants';

//data - firstName, lastName, midas, directorylink, img, email, phonenumber, department
class ContactCard extends Component {

	getTitle = (firstName, lastName, direction) => {
		return 'Move ' + firstName + ' ' + lastName + ' ' + (direction === MOVE_CONTACT_UP ? 'up' : 'down.')
	}

  render() {
    let {data} = this.props;
	if(!isProfileUrlValid(data.img) && data.firstName && data.lastName) {
		let fN = data?.firstName;
		fN = fN?.charAt(0).toUpperCase();

		let lN = data?.lastName;
		lN = lN?.charAt(0).toUpperCase();

		data.initials = fN+lN;
	}
	const firstName = data?.firstName?.toUpperCase() ?? '';
	const lastName = data?.lastName?.toUpperCase() ?? '';

    return (
		<Box
			id={this.props.id + "__contactCard_card_dataWrapper_" + this.props.midas}
			className={'myOdu__contactCard'}
		>
			<Grid container direction={'row'}>
				{
					this.props.showDrag &&
					<PortalTooltip title={'Reorder ' + firstName + ' ' + lastName + ' by Dragging'}>
						<Grid item xs={'auto'} {...this.props.listeners}>
							<IconButton className='myOdu__iconButton grippyDots' disabled = {!this.props.showDrag} style={{cursor: this.props.isDragging ? 'grabbing' : 'grab' }}>
								<FontAwesomeIcon icon={faGripDotsVertical} size='sm' />
							</IconButton>
						</Grid>
					</PortalTooltip>
				}
				{
					this.props.showDragButtons &&
					<Grid item container xs={'auto'}>
						<Grid item visibility = {this.props.hideUp ? 'hidden' : 'visible'}>
							<PortalTooltip title={this.getTitle(firstName, lastName, MOVE_CONTACT_UP)}>
								<IconButton
									className='myOdu__iconButton reorderArrow up'
									onClick={() => {
										this.props.onMoveContact(this.props.midas, MOVE_CONTACT_UP)
									}}
									disabled = {this.props.hideUp}
								>
									<FontAwesomeIcon icon={faArrowUp} size='sm' />
								</IconButton>
							</PortalTooltip>
						</Grid>
						<Grid item visibility = {this.props.hideDown ? 'hidden' : 'visible'}>
							<PortalTooltip title={this.getTitle(firstName, lastName, MOVE_CONTACT_DOWN)}>
								<IconButton
									className='myOdu__iconButton reorderArrow down'
									onClick={() => {
										this.props.onMoveContact(this.props.midas, MOVE_CONTACT_DOWN)
									}}
									disabled = {this.props.hideDown}
								>
									<FontAwesomeIcon icon={faArrowDown} size='sm' />
								</IconButton>
							</PortalTooltip>
						</Grid>
					</Grid>
				}
				<Grid item xs>
					<Stack id={this.props.id + "__contactCard__stack_contactCard_wrapper_" +this.props.midas} direction="row" justifyContent="space-between" alignItems="center">
						
						<Stack id={this.props.id + "__contactCard__stack_contactDetails_wrapper_" +this.props.midas} direction="row">
							{data?.directoryLink ? (
								<div id={this.props.id + "__contactCard__div_avatarWrapper_"+this.props.midas}>
									<PortalTooltip id={this.props.id + "__contactCard_tooltip_avatarLink_"+this.props.midas} title={`Open ODU Directory page for ${data.name}`}>
										<Link id={this.props.id + "__contactCard__avatarLink_" +this.props.midas} href={data.directoryLink} target="_blank" underline="none" >
											<Avatar id={this.props.id + "__contactCard__avatar_initials_"+this.props.midas}  {...(isProfileUrlValid(data.img) ? {alt:data.name, src: data.img} : {})} className="myOdu__logoZoom avatar">
												{data.initials}
											</Avatar>
										</Link>
									</PortalTooltip>
								</div>
							) : (
									<Avatar id={this.props.id + "__contactCard__avatar_profileImgOrInitials_"+this.props.midas} {...(isProfileUrlValid(data.img) ? {alt:data.name, src: data.img} : {})} className="myOdu__logoZoom avatar">
										{data.initials}
									</Avatar>
							)}

							
								<Stack id={this.props.id + "__contactCard__stack_contactData_wrapper_"+this.props.midas} spacing={0}>
									{data.directoryLink ? (
										<PortalTooltip id={this.props.id + "__contactCard_tooltip_textLink_"+this.props.midas} title={`Open ODU Directory page for ${data.name}`}>
											<Link href={data.directoryLink} target="_blank" underline="hover" id={this.props.id + "__contactCard__link_name_"+this.props.midas} >
												<Typography id={this.props.id + "__contactCard__text_name_"+this.props.midas} className="title">
													{lastName + ', ' + firstName}
												</Typography>
											</Link>
										</PortalTooltip>
									) : (
										<Typography id={this.props.id + "__contactCard__name_"+this.props.midas } className="title">
											{lastName + ', ' + firstName}
										</Typography>
									)}

									<Typography id={this.props.id + "__contactCard__text_departmentName_"+this.props.midas } variant="smallest" sx={{textTransform: 'uppercase'}}>
										{data.department}
									</Typography>

									{data.phoneNumber && (
										<Link id={this.props.id + "__contactCard__link_phone_"+data.phoneNumber.replace(/-/g, '')+"_" +this.props.midas} href={'tel:' + data.phoneNumber} variant="smaller" underline={'hover'} >
											<PortalTooltip id={this.props.id + '__contactCard_tooltip_phoneLink'+data.phoneNumber.replace(/-/g, '')+"_"+this.props.midas } title={`Call  ${lastName + ', ' + firstName}`} >
												<FontAwesomeIcon id={this.props.id + "__contactCard_iconButton_phone_"+data.phoneNumber.replace(/-/g, '')+"_"+this.props.midas} icon={faPhone} style={{marginRight: '0.25rem'}}/>
												{data.phoneNumber}
											</PortalTooltip>
										</Link>
									)}

									{data.email && (
										<Link id={this.props.id + "__contactCard__link_email_" +this.props.midas} href={'mailTo:' + data.email} variant="smaller" underline={'hover'} >
											<PortalTooltip id={this.props.id + "__contactCard_tooltip_emailLink"+this.props.midas} title={`Send email to ${lastName + ', ' + firstName}`} >
													<FontAwesomeIcon id={this.props.id + "__contactCard_icon_email_"+this.props.midas} icon={faEnvelope} style={{marginRight: '0.25rem'}} />
													{data.email}
											</PortalTooltip>
										</Link>
									)}
								</Stack>
							
						</Stack>

						<Box id={this.props.id + "__contactCard__box_iconBox_wrapper_"+this.props.midas}>
							{this.props.isAdd && <PortalTooltip id={this.props.id + "__contactCard_tooltip_add_"+this.props.midas} title={'Add ' + lastName + ', ' + firstName + ' in saved contacts'}>
								<IconButton id={this.props.id + "__contactCard__button_add_"+this.props.midas } aria-label="add" onClick={() => this.props.onAdd(data)} disabled={this.props.isDisabled} ><FontAwesomeIcon id={this.props.id + "__contactCard__fontAwesomeIcon_plus_"+this.props.midas } icon={faCirclePlus} /></IconButton>
							</PortalTooltip>}
							{this.props.isDelete && <PortalTooltip id={this.props.id + "__contactCard_tooltip_delete_"+this.props.midas} title={'Delete ' + lastName + ', ' + firstName + ' from saved contacts'}>
								<IconButton id={this.props.id + "__contactCard__button_delete_"+this.props.midas }aria-label="delete" onClick={() => this.props.onDelete(data)} disabled={this.props.isDisabled}><FontAwesomeIcon id={this.props.id + "__contactCard__fontAwesomeIcon_trash_"+this.props.midas } icon={faTrash} size='xs' /></IconButton>
								</PortalTooltip>}
							{this.props.isCheck && <IconButton id={this.props.id + "__contactCard__iconButton_check_"+this.props.midas } aria-label="" disabled ><FontAwesomeIcon id={this.props.id + "__contactCard__fontAwesomeIcon_check_"+this.props.midas } className='myOdu__good' icon={faCheck} /></IconButton>}
						</Box>
					</Stack>
				</Grid>
			</Grid>
      </Box>
    );
  }
}

export default ContactCard;
