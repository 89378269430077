import React, {Component} from 'react';
import {Stack, Box, Toolbar, Drawer, Button, Autocomplete, TextField, IconButton} from '@mui/material';
import SearchAppsAutocomplete from '../../common/SearchAppsAutocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from '../../common/PortalTooltip';

class Search extends Component {
    render() {
        return (<>
            <SearchAppsAutocomplete 
                isSearchOnFocus={this.props.isSearchOnFocus} 
                isMobile={this.props.isMobile} 
                isTopNav={true} 
                toggleFocus={this.props.toggleFocus} 
            />
        </>    
        );
    }
}

export default Search;
