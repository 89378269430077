import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function GoogleDriveIcon() {
  return (
    <SvgIcon>
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.15 300.12" style={{width: 30}}>
        <defs>
          <style>
            {/* .cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:bevel;stroke-width:5px;} */}
          </style>
        </defs>
        <path class="cls-1" fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='bevel' strokeWidth={5} d="M51.61,278.43l48.58-84.11h194.19l-48.58,84.11H51.61Z"/>
        <path class="cls-1" fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='bevel' strokeWidth={5} d="M197.29,194.32h97.09L197.29,26.03h-97.09l97.09,168.22v.07Z"/>
        <path class="cls-1" fill='none' stroke='#000' strokeLinecap='round' strokeLinejoin='bevel' strokeWidth={5} d="M3.03,194.32l48.58,84.11,97.09-168.22L100.12,26.03,3.03,194.32Z"/>
      </svg>
    </SvgIcon>
  );
}