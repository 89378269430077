import { Card, Typography,  Box } from "@mui/material";
import React, {Component} from "react";
import WidgetCard from "./WidgetCard";
import { getDraggableWidgets, getFixedWidgets, getWidgets } from "../../../Dashboard/utils";
import DNDContext from "../../../Dashboard/DNDContext";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";

class Section extends Component {
    state = {
        fixedWidgetsLength: 0,
        draggableWidgetsLength: 0
    }

    componentDidMount() {
        this.getLengths();
    }

    //θ(N) where N is the number of widgets in a section
    //Calculates the total number of draggable and fixed widgets in the section
    getLengths = () => {
        const { fixedWidgetsLength, draggableWidgetsLength } = this.props.widgetsOrder.reduce((lengths, widget) => {
            if(widget.isNoDrag) {
                lengths.fixedWidgetsLength += 1
            } else {
                lengths.draggableWidgetsLength += 1
            }

            return lengths;
        }, {
            fixedWidgetsLength: 0,
            draggableWidgetsLength: 0
        });

        this.setState({
            fixedWidgetsLength, draggableWidgetsLength
        })
    }

    render() {

        const widgets = this.props.widgetsOrder;

        //Moving it to componentDidUpdate would not make much difference as we will have to update it for every reordering.
        //The only place where we would not update is when we change the dnd - keyboard toggle
        const displayWidgets = widgets.filter(widget => widget.isDisplay);

        return <React.Fragment>
            <div style={ { height: '100%', ...(this.props.isDroppable ? {opacity: 0.5, cursor: 'no-drop'} : {})}}>
                <Box className="widgetSection" sx={{ p:1, height: '100%'}}>
                    {/* We are getting the section index from props. Using that we are deriving the alphabet using ASCII code */}
                    <Typography component='h3'>Section {String.fromCharCode('A'.charCodeAt(0) + this.props.sectionNumber)}</Typography>
                    <DNDContext
                        onDragEnd = {this.props.onDragEnd}
                        onDragStart = {this.props.onDragStart}
                        modifiers = {[restrictToVerticalAxis, restrictToParentElement]}
                    >
                        {/* isNoDrag filter cannot be removed again as it will again update for reordering as well. */}
                        <SortableContext items = {displayWidgets.filter(widget => !widget.isNoDrag).map(widget => widget.id)}>
                            {
                                displayWidgets.map((widget, idx) => {
                                    return <SortableItem
                                        widget = {widget}
                                        id = {widget.id}
                                        sectionNumber = {this.props.sectionNumber}
                                        isDNDSelected = {this.props.isDNDSelected}
                                        key = {widget.id}
                                        moveWidget = {this.props.moveWidget}
                                        hideUp = {widgets?.length - idx === this.state.draggableWidgetsLength || widget.isNoDrag } 
                                        hideDown = {idx === displayWidgets.length -1 || widget.isNoDrag}
                                        index={idx - this.state.fixedWidgetsLength}
                                    />
                                })
                            }
                        </SortableContext>

                    </DNDContext>
                </Box>
            </div>
        </React.Fragment>
    }
}

export default Section;