import {faCheck, faX} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, IconButton} from '@mui/material';
import {Box} from '@mui/system';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {FormTextField} from '../common/Form/FormTextField';

const boxStyle = {
    xs: 0.5,
    xxl: 2
};

class AddBookmark extends Component {
    state = {
        //title of bookmark
        title: '',

        //url of bookmark
        url: '',

        isDuplicateTitle: false,

        isEmptyTitle: false
    };

    componentDidMount() {
        if (this.props.isEdit) this.setup();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isReset !== this.props.isReset && this.props.isReset) {
            this.reset();
            this.props.toggleIsReset();
        }
    }

    //Θ(1) setup component for edit
    setup = () => {
        this.setState({
            title: this.props.bookmark.TITLE,
            url: this.props.bookmark.URL
        });
    };

    //Θ(1) reset the form
    reset = () => {
        this.setState({
            title: '',
            url: ''
        });
    };

    //Θ(1) handle title change
    onChangeTitle = value => {
        this.setState({title: value});
        this.validateTitle(value);
    };

    //Θ(1) handle url change
    onChangeURL = value => {
        this.setState({url: value});
    };

    //Θ(1) check of the url is valid
    isValidURL = () => {
        try {
            new URL(this.state.url);
            return true;
        } catch (ex) {
            return false;
        }
    };

    //Θ(1) validate the title field
    validateTitle = title => {
        title?.trim()
            ? this.setState({
                  isDuplicateTitle: this.props.isDuplicate(
                      title.trim(),
                      this.props.bookmark?.CREATED_AT
                  ),
                  isEmptyTitle: false
              })
            : title?.length
            ? this.setState({
                  isDuplicateTitle: false,
                  isEmptyTitle: true
              })
            : this.setState({
                  isDuplicateTitle: false,
                  isEmptyTitle: true
              });
    };

    //Θ(1) check if the title is valid
    isValidTitle = () =>
        !this.state.isDuplicateTitle && !this.state.isEmptyTitle;

    //Θ(1) check if the bookmark to be added is valid
    isValidAdd = () => {
        return this.isValidURL() && this.isValidTitle();
    };

    //Θ(1) check if the edited bookmark is valid
    isValidEdit = () => {
        return (
            (this.state.title?.trim() != this.props.bookmark.TITLE ||
                this.state.url?.trim() != this.props.bookmark.URL) &&
            this.isValidAdd()
        );
    };

    errorMessage = () => {
        if (this.state.isDuplicateTitle) {
            return 'A bookmark with this title already exists; choose a different title.';
        } else if (this.state.isEmptyTitle) {
            return 'Title cannot be empty. Add a title.';
        }
    };

    //Θ(1) trigger save bookmark and reset component
    onAdd = () => {
        this.props.onAdd(this.state.title?.trim(), this.state.url?.trim());
    };

    render() {
        return (
            <React.Fragment>
                <Row id={'addBookmark__row_formWrapper'}>
                    <Col
                        md={this.props.isEdit ? 11 : 12}
                        id={'addBookmark__column_formWrapper'}
                    >
                        <Row id={'addBookmark__row_fieldWrapper_title'}>
                            <Col id={'addBookmark__col_fieldWrapper_title'}>
                                <Box
                                    py={boxStyle}
                                    id={'addBookmark__box_fieldWrapper_title'}
                                >
                                    <FormTextField
                                        id="addBookmark__inputText_title"
                                        label="Title"
                                        required
                                        value={this.state.title}
                                        placeholder="Title"
                                        onChange={this.onChangeTitle}
                                        onBlur={() =>
                                            this.validateTitle(this.state.title)
                                        }
                                        maxChars={100}
                                        charCountEnabled
                                        textAlign={{xs: 'start'}}
                                        labelGridProps={{xs: 12, md: 2}}
                                        inputGridProps={{xs: 12, md: 10}}
                                        isTextArea
                                        error={!this.isValidTitle()}
                                        errorText={this.errorMessage()}
                                    />
                                </Box>
                            </Col>
                        </Row>
                        <Row id={'addBookmark__row_fieldWrapper_url'}>
                            <Col id={'addBookmark__col_fieldWrapper_title'}>
                                <Box id={'addBookmark__box_fieldWrapper_url'}>
                                    <FormTextField
                                        id="addBookmark__inputText_url"
                                        label="URL"
                                        required
                                        value={this.state.url}
                                        onChange={this.onChangeURL}
                                        placeholder="http:// or https://"
                                        error={
                                            !this.isValidURL() &&
                                            this.state.url.length > 0
                                        }
                                        errorText="URL must start with http:// or https://"
                                        maxChars={300}
                                        charCountEnabled
                                        helperText="URL must start with http:// or https://"
                                        textAlign={{xs: 'start'}}
                                        labelGridProps={{xs: 12, md: 2}}
                                        inputGridProps={{xs: 12, md: 10}}
                                        isTextArea
                                    />
                                </Box>
                            </Col>
                        </Row>
                        {!this.props.isEdit && (
                            <Row className="text-end">
                                <Col
                                    className="p-3"
                                    id={
                                        'addBookmark__col_buttonWrapper_addBookmark'
                                    }
                                >
                                    <Button
                                        id={'addBookmark__button_addBookmark'}
                                        variant="outlined"
                                        sx={{mt: 1}}
                                        onClick={this.onAdd}
                                        disabled={!this.isValidAdd()}
                                        className="myOdu__button primary bookmarkManagerButton"
                                    >
                                        {'Add Bookmark'}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Col>
                    {this.props.isEdit && (
                        <Col md={1}>
                            <Box
                                id={'addBookmark__box_iconsWrapper_iconButtons'}
                            >
                                <IconButton
                                    id={'addBookmark__iconButton_edit'}
                                    aria-label="check"
                                    onClick={() =>
                                        this.props.onEditSubmit(
                                            this.state.title?.trim(),
                                            this.state.url?.trim()
                                        )
                                    }
                                    disabled={!this.isValidEdit()}
                                >
                                    <FontAwesomeIcon
                                        id={'addBookmark__icon_edit'}
                                        icon={faCheck}
                                    />
                                </IconButton>
                                <IconButton
                                    id={'addBookmark__iconButton_delete'}
                                    aria-label="delete"
                                    onClick={this.props.onEditCancel}
                                >
                                    <FontAwesomeIcon
                                        id={'addBookmark__icon_delete'}
                                        icon={faX}
                                    />
                                </IconButton>
                            </Box>
                        </Col>
                    )}
                </Row>
            </React.Fragment>
        );
    }
}

export default AddBookmark;
