import { SAVE_APPLICATIONS_FILTER } from "./actions";

const initialStateApplications = {
    text: '',
}

//needs to persist across pages
export const applicationsReducer = (state = initialStateApplications, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_APPLICATIONS_FILTER: {
            return {...state, text: payload.text}
        }
        default:
            return state;
    }
}