import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Tooltip, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography, Badge} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortalTooltip from "../../../common/PortalTooltip";

class MenuListItem extends Component {

    render() {
        return ( <React.Fragment>
            <ListItem disableGutters sx={{p:0, display: 'block'}} className={'menuItem ' + (this.props?.className ? this.props.className : "")}>
                <ListItemButton id={"sideNav__button_" + this.props.title.replace(' ', '')} onClick={this.props.onClick} className={this.props.isOpen ? "justify-content-start" : "justify-content-center"}>
                    {!this.props.isOpen && <>
                            <ListItemIcon sx={{color: '#DADADA', minWidth: '1.75rem', pt:1, pb:1}} role="button">
                                <Badge variant={this.props.variant} badgeContent={this.props.badgeCount} color='error' max={10} className={"myOdu__iconBadge smallSideNav " + this.props?.variant}>
                                    <PortalTooltip title={this.props.isNewWindow? "Open " + this.props.title + " in New Window" : this.props.title} placement="right">
                                    {/* //TODO: write this to pass in icon or image and render instead of having 2 options */}
                                    {this.props?.icon ?
                                        <FontAwesomeIcon icon={this.props.icon} size={"lg"} fixedWidth />
                                    :
                                        <Box sx={{height: '22px', width: '22px', marginTop: "-4px"}}>
                                            <img src={"/img/logos/" + this.props.img} alt={this.props.title} />
                                        </Box>
                                    } </PortalTooltip>
                                </Badge>
                            </ListItemIcon>
                       
                    </>}
                    {this.props.isOpen && <>
                        <ListItemIcon sx={{color: '#DADADA', minWidth: '1.75rem'}}>
                            {this.props?.icon ?
                                <FontAwesomeIcon icon={this.props.icon} size={"lg"} fixedWidth />
                            :
                                <Box sx={{height: '18px', width: '18px', marginTop: "-4px"}}>
                                    <PortalTooltip tooltip={this.props.isNewWindow? "Open " + this.props.title + "in New Window" : this.props.title}>
                                        <img src={"/img/logos/" + this.props.img} alt={this.props.title} />
                                    </PortalTooltip>
                                </Box>
                            }
                        </ListItemIcon> 
                        <Badge variant={this.props.variant} badgeContent={this.props.badgeCount} color='error' max={10} className={"myOdu__iconBadge " + this.props?.variant}>
                            <ListItemText disableTypography sx={{ opacity: this.props.isOpen ? 1 : 0 }} primary={<Typography sx={{ fontWeight: '600' }}>
                                {this.props.title}
                            </Typography>}/>
                        </Badge>
                    </>}
                </ListItemButton>
            </ListItem>
        </React.Fragment>);
    }
}

export default MenuListItem;



