import React, { Component } from "react";
import { Typography, Box, Checkbox, FormControlLabel, FormControl } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";
import Tooltip from '@mui/material/Tooltip';


class PrettyCheckbox extends Component {

	render(){
		return ( 
			<>
			<FormControl>
				{this.props.title ? 
					// no tooltip on this since it has a title
					<FormControlLabel 
						sx={{ml:0}}
						control={<Checkbox
							icon={<FontAwesomeIcon icon={faSquare} />}
							checkedIcon={<FontAwesomeIcon icon={faSquareCheck} />}
							{...this.props}
						/>} 
					label={this.props.title} 
					id={this.props.id + '_label'}
					inputProps={{ 'aria-label': this.props.label }} />
			:  	
				<Tooltip title={this.props.label} enterDelay={500} enterNextDelay={500} target={this.props.id}>
					<Checkbox
						icon={<FontAwesomeIcon icon={faSquare} />}
						checkedIcon={<FontAwesomeIcon icon={faSquareCheck} />}
						{...this.props}
						inputProps={{ 'aria-label': this.props.label }}
					/>
				</Tooltip>
			} </FormControl>
		</>
		);
	}
}

export default PrettyCheckbox;