import axios from 'axios';
import {getImpersonationHeader, getLtiToken, isLti} from './util';
import {authorize} from '../AWS';
import {cognitoErrors} from './services';

// API gateway base URL from environment variable
// This value is from .env which is written during CodeBuild step
// CodeBuild gets it from CodePipeline which gets it from backend build step
export const baseURL = process.env.REACT_APP_API_GATEWAY_BASE_URL + 'portal/';
export const baseURLPublic =
    process.env.REACT_APP_API_GATEWAY_BASE_URL + 'public/';
export const baseURLLti = process.env.REACT_APP_API_GATEWAY_BASE_URL + 'lti/';

// Private Instance
const privateInstance = axios.create({
    baseURL
});

privateInstance.interceptors.request.use(async config => {
    const authorization = await authorize();
    if (!authorization) {
        return Promise.reject(new Error('Login again'));
    }

    config.headers = {
        'Content-Type': 'application/json',
        Authorization: authorization,
        ...getImpersonationHeader()
    };
    return config;
});

privateInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        // We want to return timeout if there is ERR_NETWORK error code
        // due to timeout or bad connection to inform users to check back if applicable
        if (error.code === 'ERR_NETWORK') {
            error.response = {
                data: {
                    timeout: true
                }
            };
        }
        return Promise.reject(error.response.data);
    }
);

// Public Instance
const publicInstance = axios.create({
    baseURL: baseURLPublic
});

publicInstance.interceptors.request.use(config => {
    config.headers = {
        'Content-Type': 'application/json',
        ...getImpersonationHeader()
    };
    return config;
});

publicInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return Promise.reject(error.response.data);
    }
);

// Lti Instance
const ltiInstance = axios.create({
    baseURL: baseURLLti
});

ltiInstance.interceptors.request.use(config => {
    const ltiToken = getLtiToken();
    if (!ltiToken) {
        return Promise.reject(new Error('Login again'));
    }
    config.headers = {
        'Content-Type': 'application/json',
        LtiAuthorization: ltiToken
    };
    return config;
});

ltiInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        // We want to return timeout if there is ERR_NETWORK error code
        // due to timeout or bad connection to inform users to check back if applicable
        if (error.code === 'ERR_NETWORK') {
            error.response = {
                data: {
                    timeout: true
                }
            };
        }
        return Promise.reject(error.response.data);
    }
);

//Async method to get data from server
export const getData = async (service, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        return (isLti() ? ltiInstance : privateInstance)
            .get(service)
            .then(data => resolve(data))
            .catch(err => {
                //only reject if the rejection is expected, else the app renders errors on the main page
                if (isReturnError) reject(err);
            });
    });
};

//Async method to get data from server for Public routes
export const getDataPublic = async (service, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        return publicInstance
            .get(service)
            .then(data => resolve(data))
            .catch(err => {
                //only reject if the rejection is expected, else the app renders errors on the main page
                if (isReturnError) reject(err);
            });
    });
};

//Async method to post data to server
export const postData = async (service, body, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        return (isLti() ? ltiInstance : privateInstance)
            .post(service, body)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                //only reject if the rejection is expected, else the app renders errors on the main page
                if (isReturnError) reject(err);
            });
    });
};

//Async method to post data to server for public routes
export const postDataPublic = async (service, body, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        return publicInstance
            .post(service, body)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                //only reject if the rejection is expected, else the app renders errors on the main page
                if (isReturnError) reject(err);
            });
    });
};

//Async method to post data to server for public routes
export const patchDataPublic = async (service, body, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        return publicInstance
            .patch(service, body)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                //only reject if the rejection is expected, else the app renders errors on the main page
                if (isReturnError) reject(err);
            });
    });
};

//Async method to put data to server
export const putData = async (service, body, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        return (isLti() ? ltiInstance : privateInstance)
            .put(service, body)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                //only reject if the rejection is expected, else the app renders errors on the main page
                if (isReturnError) reject(err);
            });
    });
};

//Async method to patch data to server
export const patchData = async (service, body, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        return (isLti() ? ltiInstance : privateInstance)
            .patch(service, body)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                //only reject if the rejection is expected, else the app renders errors on the main page
                if (isReturnError) reject(err);
            });
    });
};

//Async method to delete data from server
export const deleteData = async (service, isReturnError = false) => {
    return new Promise(async (resolve, reject) => {
        return (isLti() ? ltiInstance : privateInstance)
            .delete(service)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                //only reject if the rejection is expected, else the app renders errors on the main page
                if (isReturnError) reject(err);
            });
    });
};

export const logCognitoError = async cognitoError => {
    postDataPublic(cognitoErrors, cognitoError);
};
