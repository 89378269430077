import React, {Component} from 'react';
import {Button} from '@mui/material';

class AlertAction extends Component {
  render() {
    return (
      <Button
        id={this.props.id+"__button_alertAction"}
        onClick={() => {
          this.props.toggleAlertVisibility(this.props.action, this.props.item);
        }}
        variant="outlined"
        size="small"
        aria-label={this.props.label}
        className="myOdu__button secondary statusAlertButton"
      >
        {this.props.label}
      </Button>
    );
  }
}

export default AlertAction;
