import React from 'react';

import {Link, Grid, Typography, Container, Box} from '@mui/material';
import PortalTooltip from '../common/PortalTooltip';

export const LegalNameSection = ({data}) => {
  return (
    // <Container >
    <Box className="legalNameSection">
      <Typography component="h3">
        Legal Name
      </Typography>
      <Typography>
        A student's legal name will be used where{' '}
        <Link
          href="https://www.odu.edu/academics/academic-records/preferred-name#tab1771972802753=1&amp;done1612907281342"
          target="_blank"
          id="myInfo__link_requiredByLaw"
        >
          required by law or the University
        </Link>
        .
      </Typography>

      <Box className="legalNameBox" >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={3}
            lg={4}
            xl={3}
            textAlign={{xs: 'start', md: 'end', lg: 'end', xl: 'end'}}
          >
            <Typography className="myOdu__label" component="label">
              First
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={8} xl={9}>
            <Typography>{data.firstName}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={4}
            xl={3}
            textAlign={{xs: 'start', md: 'end', lg: 'end', xl: 'end'}}
          >
            <Typography className="myOdu__label" component="label">
              Middle
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={8} xl={9}>
              {data.middleName ? 
                <Typography>{data.middleName}</Typography>
                :
                <PortalTooltip title="No Middle Name Found">
                  <Typography className="myOdu__optional cursor-pointer" >-</Typography>
                </PortalTooltip>
              }
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={4}
            xl={3}
            textAlign={{xs: 'start', md: 'end', lg: 'end', xl: 'end'}}
          >
            <Typography className="myOdu__label" component="label">
              Last
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={8} xl={9}>
            <Typography>{data.lastName}</Typography>
          </Grid>
        </Grid>
      </Box>
    {/* // </Container> */}
    </Box>
  );
};
