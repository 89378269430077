import React from "react";
import { Alert, AlertTitle, Link, Typography, Box, Stack } from "@mui/material";
import PortalTooltip from "./PortalTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class PortalAlert extends React.Component {
    state = {

    };

    render() {
        return <React.Fragment>
            <Box id={this.props.id + '__box_wrapper'} {...this.props} className={"myOdu__alert " + this.props?.severity + " " + this.props?.type} sx={{p: 1}}>
                <Stack id={this.props.id + '__stack_wrapper'} direction={"row"} spacing={1} alignItems="top">
                    {this.props?.icon && 
                        <Box id={this.props.id + '__box_image_wrapper'} sx={{height: '40px', width: '40px', margin: '0 auto', minHeight:"4px", minWidth:"40px"}}>
                            <img id={this.props.id + '__imageIcon'} src={this.props.icon} alt={this.props.alt} />
                        </Box>
                    }
                    <Box sx={{mb: 0}}>
                        {this.props?.titleLink ? 
                            <PortalTooltip id={this.props.id + '__tooltip'}>
                                <Link href={this.props.titleLink} id={this.props.id + '__link_titleWrapper'} target="_blank" underline="hover" sx={{ cursor: 'pointer', fontWeight: 600 }} >
                                    <Typography id={this.props.id + '__text_title'} component="h3" variant="h6">
                                        {this.props.title}
                                    </Typography>
                                </Link>
                            </PortalTooltip>
                        :
                            <Typography id={this.props.id + '__text_title'} component="h3" variant="h6">
                                {this.props.title}
                            </Typography>
                        }

                        {this.props?.subTitle && 
                            <Typography id={this.props.id + '__text_subTitle'} component="p" variant="smallest" sx={{fontStyle: 'italic'}} >
                                {this.props.subTitle}
                            </Typography>
                        }

                        {this.props.isBodyUnderTitle ? 
                            //BodyUnderTitle is only an option for non xs screens, hence hiding it on xs screen.
                            <Box id={this.props.id + '__box_children_nonXsScreen'} className="alertBody mt-0 d-none d-sm-block">
                                {this.props.children}
                            </Box>
                        : ""}
                    </Box>
                </Stack>

                {/* //always see it unless true and not small */}
                {this.props.isBodyUnderTitle ? 
                    <Box id={this.props.id + '__box_children_smNone'} className={"alertBody d-block d-sm-none"}>
                        {this.props.children}
                    </Box>
                :
                    <Box className={"alertBody d-block"} id={this.props.id + '__box_children_dblock'}>
                        {this.props.children}
                    </Box>
                }


            </Box>
        </React.Fragment>
    }
}

export default PortalAlert;