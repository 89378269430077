import React, { Component } from 'react';
import { Box, Typography } from '@mui/material';

class DateBox extends Component {

    state = {
        month: '',
        dateNumber: ''
    }
    
    componentDidMount() {
        const epochTime = this.props.date;
        const date = new Date(epochTime);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        this.setState({month, day});
    }
    render() {
        return <Box id={this.props.id+"__boxWrapper_" + this.state.month +"_" + this.state.day} className="myOdu__dateBox" sx={{ p: 2 }}><Typography id={this.props.id+"_text_month_" + this.state.month +"_" + this.state.day} className='myOdu__month'>{this.state.month}</Typography><Typography id={this.props.id+"_number_day_"  + this.state.day + "_"+ this.state.month } className="myOdu__dateNumber">{this.state.day}</Typography></Box>;
    }
}

export default DateBox;