import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Link, List, ListItem, Box, Grid, Typography} from '@mui/material';
import ContactCard from '../common/ContactCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAddressBook} from '@fortawesome/pro-light-svg-icons';
import {getData, postData, putData} from '../../DataAccessLayer';
import {myContacts} from '../../DataAccessLayer/services';
import {connect} from 'react-redux';
import {
    saveContacts,
    saveContactsResults,
    saveContactsUpdate,
    toggleContactsManager,
    toggleContactsLoading
} from './actions';
import ContactsManager from './ContactsManager';
import PortalTooltip from '../common/PortalTooltip';
import {toast} from 'react-toastify';
import { ODU_DIRECTORY } from '../common/constants';

class MyContacts extends Component {
    state = {
        isExpand: false,
        menu: [
            {
                id: 'myContacts_editContacts',
                title: 'Edit Contacts',
                onClick: this.props.toggleContactsManager
            }
            //TODO:  enable in v1.1
            // {id: "myContacts_hide", title: "Hide"},
        ],
        isSearching: false
    };

    componentDidMount() {
        this.loadContacts();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.updates &&
            JSON.stringify(prevProps.updates) !=
                JSON.stringify(this.props.updates)
        )
            this.onUpdate();
    }

    //Θ(1) load contacts and save
    loadContacts = () => {
        getData(myContacts)
            .then(contacts => {
                this.props.saveContacts(contacts);
            })
            .finally(_ => {
                this.props.toggleContactsLoading();
            });
    };

    //Θ(1) toggle widget expand
    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    toggleIsSearching = () => {
        this.setState({
            isSearching: !this.state.isSearching
        });
    };

    //search contacts and save results
    onSearch = () => {
        this.props.toggleContactsLoading();
        this.toggleIsSearching();
        postData(myContacts, this.props.search)
            .then(results => {
                this.props.saveContactsResults(results);
            })
            .finally(() => {
                this.props.toggleContactsLoading();
                this.toggleIsSearching();
            });
    };

    //Θ(1) update contacts and reload
    onUpdate = () => {
        this.props.toggleContactsLoading();
        putData(myContacts, {contacts: this.props.updates}).then(
            data => {
                this.loadContacts();
                this.props.saveContactsUpdate();
            }
        );
    };

    render() {
        return (
            <React.Fragment>
                <ContactsManager
                    onSearch={this.onSearch}
                    onUpdate={this.onUpdate}
                    isSearching={this.state.isSearching}
                    toggleIsSearching={this.toggleIsSearching}
                    id="myContacts__contactsManager"
                />
                <div id={"myContacts__div_contentWrapper"}>
                    
                    <Widget
                        data={{
                            id: this.props.widget.id,
                            title: this.props.title,
                            isTitleStylized: this.props.isTitleStylized,
                            menu: this.state.menu,
                            isExpand: this.props.widget.isExpand,
                            isRequired: this.props.widget.isRequired
                        }}
                        isExpand={this.state.isExpand}
                        {...this.props}
                        className="myOdu__myContacts"
                        hasScrollY
                    >
                        <Box id={"myContacts__box_universityDirectory_wrapper"}
                            sx={{
                                width: '100%',
                                pb: 2,
                                mb: 1
                            }}
                            className="bb-1"
                        >
                            <FontAwesomeIcon
                                id={"myContacts__icon_contactsBook"}
                                icon={faAddressBook}
                                size="lg"
                                style={{
                                    marginRight: '0.51rem',
                                    marginLeft: '0.5rem'
                                }}
                            />
                            <Link
                                id="myContacts__link_universityDirectory"
                                href={ODU_DIRECTORY}
                                sx={{fontWeight: 600}}
                                underline={'hover'}
                                target="_blank"
                            >
                                University Directory
                            </Link>
                        </Box>
                        {/* <Grid id={"myContacts__grid_listWrapper"}
                            container
                            alignItems={'stretch'}
                            sx={{p: 0, mt: 1}}
                            spacing={1}
                            className="listWrapper"
                        > */}
                            {this.props.contacts.length ? (
                                <List spacing={1} className="myOdu__contactCardList listWrapper">
                                    {this.props.contacts?.map(contact => (
                                        <ListItem 
                                            id={"myContacts__gridItem_contactCard_wrapper_" + contact.midas}
                                            key={contact.midas}
                                        >
                                            <ContactCard id={"myContacts__contactCard"} midas={contact.midas} data={contact} />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Box sx={{mr:1}}>
                                    <Box className="noContactsFound">
                                        <Typography className="p" id={"myBookmarks__cardText_noBookmarksFound"} >
                                            No contacts found.{' '}
                                        </Typography>
                                        <PortalTooltip iid={"myContacts__tooltip_addContacts"} title="Open contact editor">
                                            <Link id="myContacts__link_addContacts"
                                                onClick={ this.props.toggleContactsManager }
                                            >
                                                Add contacts.
                                            </Link>
                                        </PortalTooltip>
                                    </Box>
                                </Box>
                            )}
                        {/* </Grid> */}
                    </Widget>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.myContactsReducer.contacts,
        search: state.myContactsReducer.search,
        updates: state.myContactsReducer.updates
    };
};

const mapDispatchToProps = dispatch => ({
    saveContacts: contacts => dispatch(saveContacts(contacts)),
    saveContactsUpdate: contacts => dispatch(saveContactsUpdate(contacts)),
    saveContactsResults: results => dispatch(saveContactsResults(results)),
    toggleContactsManager: () => dispatch(toggleContactsManager()),
    toggleContactsLoading: () => dispatch(toggleContactsLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(MyContacts);
