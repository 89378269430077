import React, {Component} from 'react';
import {Box, Button, Stack} from '@mui/material';

class ItemButtons extends Component {
    render() {
        const itemTitle = this.props.item.title;
        const informationButton = {
            title:
                this.props.item.informationTitle?.toLowerCase() === 'info'
                    ? 'Get More Info'
                    : this.props.item.informationTitle,
            url: this.props.item.informationUrl
        };
        const statusButton = {
            title: this.props.item.statusTitle,
            url: this.props.item.statusUrl
        };
        const actionButton = {
            title: this.props.item.actionLinkTitle,
            url: this.props.item.actionLinkUrl
        };
        return (
            <Stack sx={{width: '100%', mt:2}} direction={{xs:'col', sm:'row'}} spacing={1} alignItems={'center'} justifyContent="flex-end">
                {informationButton.title && informationButton.url && (
                    <Button
                        id={"checklist__button_item_" + itemTitle.replace(/\s/g, '') + "_firstButton"}
                        href={informationButton.url}
                        className="myOdu__button secondary checklistButton"
                        target="_blank"
                        variant="outlined"
                        size="small"
                        aria-label={`${itemTitle} - Get more info`}
                    >
                        {informationButton.title}
                    </Button>
                )}
                {statusButton.title && statusButton.url && (
                    <Button
                        id={"checklist__button_item_" + itemTitle.replace(/\s/g, '') + "_secondButton"}
                        href={statusButton.url}
                        className="myOdu__button primary checklistButton"
                        target="_blank"
                        variant="outlined"
                        size="small"
                        aria-label={`${itemTitle} - ${statusButton.title}`}
                    >
                        {statusButton.title}
                    </Button>
                )}
                {actionButton.title && actionButton.url && (
                    <Button
                        id={"checklist__button_item_" + itemTitle.replace(/\s/g, '') + "_thirdButton"}
                        href={actionButton.url}
                        className="myOdu__button primary checklistButton"
                        target="_blank"
                        variant="outlined"
                        size="small"
                        aria-label={`${itemTitle} - ${actionButton.title}`}
                    >
                        {actionButton.title}
                    </Button>
                )}
            </Stack>
        );
    }
}

export default ItemButtons;
