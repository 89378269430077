import { Box, Typography, Link, Button, Tooltip, Stack, TextField, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, InputLabel, Select, MenuItem } from '@mui/material';
import React, {Component} from 'react';
import {BreakpointBoxes} from '../common/BreakpointBoxes';
import {toast} from 'react-toastify';
import PortalToast from '../common/PortalToast';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DragDrop from './DragDrop';
import {DemoMuiFormComponents} from './DemoMUIFormComponents';
import WithRouter from '../Layout/Router/WithRouter';
import InvalidRoute from '../Layout/Router/InvalidRoute';
import Unauthorized from '../Layout/Router/Unauthorized';
import GenericError from '../Layout/Router/GenericError';
import PortalTimeTests from "./tests/PortalTimeTests";
import { faFaceSadCry} from '@fortawesome/pro-light-svg-icons';
import CCT from './CCT';
import {getUser, isProduction, isUserMedical} from '../Layout/utils';
import PortalTooltipIssue from './tests/PortalToolTipIssue';
import ButtonTests from './tests/ButtonTests';
import ActionCheckboxTests from './tests/ActionCheckboxTests';
import PortalAlertTests from './tests/PortalAlertTests';
import PortalModalTests
 from './tests/PortalModalTests';
// import {BrowserView, MobileView} from 'react-device-detect';


class DevSpace extends Component {
    state = {
        isModalOpen: false,
        isExpand: false
    };

    toggleAccordion = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    notifySuccess = () => {
        toast.success('Contact for KEENAN, JENNIFER added');
    };

    notifyError = () => {
        toast.error('Oh No! Errors!!!!');
    };

    notifyLongMessage = () => {
        toast.error(this.getLongMessage());
    };

    toggleIsModalOpen = () => {
        this.setState({isModalOpen: !this.state.isModalOpen});
    };

    handlePrimaryClick = () => {
        console.log('primary button clicked');
    };

    handleSecondaryClick = () => {
        console.log('secondary button clicked');
    };

    getLongMessage = () => {
        return (
            <>
                <Stack>
                    <Typography component="h3">OH NO!</Typography>
                    <Typography component="p">Lorem Ispum Dolor</Typography>
                    <ul>
                        <li>blah</li>
                        <li>blah</li>
                        <li>blah</li>
                        <li>blah</li>
                    </ul>
                </Stack>
            </>
        );
    };

    render() {
        return (
            <React.Fragment>
                <BreakpointBoxes />

                <Typography component="h2">Component/Style Examples</Typography>
                <Typography component={"p"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum condimentum rutrum eros, vitae blandit nibh egestas sed. Proin in nibh nibh. Donec at dapibus nulla. Aenean at ante ut nisl pharetra pulvinar. Duis sed neque nisl. Phasellus maximus cursus metus, quis tincidunt nulla porta eu. Nunc maximus urna ac nisi finibus, ac tristique nunc fringilla. Pellentesque hendrerit nunc vitae elit pellentesque, non dapibus orci dapibus.</Typography>
                <Typography component={"p"} sx={{mt:2}}>Aliquam enim mi, pretium id elementum vitae, pulvinar id nisi. Nunc egestas lacinia orci sit amet accumsan. Nam sed condimentum nisi. Sed congue, lectus in posuere aliquet, lorem enim molestie est, sed dignissim nisi libero eu quam. Donec sed viverra risus, id sollicitudin dui. Vivamus iaculis, neque ut accumsan efficitur, neque leo mollis dolor, ac consequat odio nisl aliquet leo. Suspendisse nec bibendum dolor. Aliquam erat volutpat. Aliquam eu est non nisl ullamcorper suscipit.</Typography>

                {/* <FontAwesomeIcon icon={faFaceSadCry} size="2xl" className="wiggle"/> */}
                {/* <PortalTimeTests/> */}
                {/* <PortalPageLoader /> */}
                {/* <GenericError /> */}
                {/* <PortalAlertTests /> */}
                {/* <PortalToast severity="error" /> */}
                {/* <ButtonTests /> */}
                {/* <ActionCheckboxTests/> */}
                <PortalModalTests />
                {/* <Link onClick={() => this.props.navigate('Crud/')} > <Typography>CRUD Styled</Typography> </Link> */}
                {/* <DemoMuiFormComponents /> */}
                {/* <DragDrop /> */}
                {/* <CCT /> */}


                {/* <Typography component={"h2"}>Unresolved Issues</Typography> */}
                {/* <PortalTooltipIssue /> */}

            </React.Fragment>
        );
    }
}

export default  WithRouter(DevSpace);
