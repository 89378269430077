import React from 'react';
import {defaultAnimateLayoutChanges, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import ApplicationCard from '../common/ApplicationCard';
import { ICON_PATH } from '../common/constants';

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.id,
    data: {
      app: props.app
    },
    transition: {
      duration: 400,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  });


  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };
  
  return (
    <div ref={setNodeRef} style={{...style, height: '100%'}} {...attributes}>
       <div style={{height: '100%', opacity: props.isDragging && props.app.id === props.selectedApp.id ? '0%' : '100%'}} className={props.isDragging && "wiggle"}>
            <ApplicationCard 
                widgetId="myApps" 
                app={props.app} 
                data={{icon: ICON_PATH + props.app?.icon}} 
                isActive={true} 
                showHeart={false} 
                showInfo={false} 
                showLinks={!props.isReorderApps} 
                showReorder = {props.isReorderApps} 
                listeners = {{...listeners}}     
            />
        </div>
    </div>
  );
}

export default SortableItem;