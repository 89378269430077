import React from 'react';
import {defaultAnimateLayoutChanges, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import ContactCard from '../common/ContactCard';
import { Skeleton } from '@mui/material';

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props.id,
    data: {
      app: props.app
    },
    transition: {
      duration: 400,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  });


  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };
  
  return (
    <div ref={setNodeRef} style={{...style}} {...attributes}>
        <div style={{
          paddingBottom: 8
        }}>
            {
                !props.isLoading ? (
                    <ContactCard
                        id={props.id}
                        midas={props.midas}
                        data={props.data}
                        onDelete={props.onDelete}
                        isDisabled={
                            props.isLoading
                        }
                        isDelete
                        isDragging = {isDragging}
                        showDrag = {props.showDrag}
                        showDragButtons = {!props.isDNDSelected}
                        listeners = {listeners}
                        onMoveContact = {props.onMoveContact}
                        hideUp = {props.hideUp}
                        hideDown = {props.hideDown}
                    />
                ) : (
                    <Skeleton
                        id={ props.id }
                        variant="rectangular"
                        height={80}
                    />
                )
            }    
        </div>
    </div>
  );
}

export default SortableItem;