import {
    Box,
    Button,
    InputLabel,
    OutlinedInput,
    Typography
} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'reactstrap';
import {saveContactsSearch} from './actions';
import {FormTextField} from '../common/Form/FormTextField';

const boxStyle = {
    xs: 0.5,
    xxl: 2
};

class Search extends Component {
    state = {
        firstName: '',
        lastName: ''
    };

    onFirstNameChange = value => {
        this.props.saveContactsSearch({...this.props.search, firstName: value});
    };

    onLastNameChange = value => {
        this.props.saveContactsSearch({...this.props.search, lastName: value});
    };

    render() {
        return (
            <div
                id={this.props.id + '__div_searchContacts'}
                className={this.props.className}
            >
                <Typography
                    id={this.props.id + '__search_text_addContact'}
                    variant="h4"
                    component="h3"
                    className="py-3"
                >
                    Add Contact from University Directory
                </Typography>
                <Row id={this.props.id + '__row_firstNameWrapper'}>
                    <Col id={this.props.id + '__col_firstNameWrapper'}>
                        <Box id={this.props.id + '__box_firstNameWrapper'}>
                            <FormTextField
                                id={
                                    this.props.id +
                                    '__search_inputText_firstName'
                                }
                                fullWidth
                                size="small"
                                label="First Name"
                                disabled={this.props.isLoading}
                                placeholder="First Name"
                                value={this.props.search.firstName}
                                onChange={this.onFirstNameChange}
                                textAlign={{xs: 'start'}}
                                labelGridProps={{xs: 12, sm: 12, md: 3}}
                                inputGridProps={{xs: 12, sm: 12, md: 9}}
                            />
                        </Box>
                    </Col>
                </Row>
                <Row id={this.props.id + '__row_lastNameWrapper'}>
                    <Col id={this.props.id + '__col_lastNameWrapper'}>
                        <Box
                            id={this.props.id + '__box_lastNameWrapper'}
                            py={boxStyle}
                        >
                            <FormTextField
                                id={this.props.id + '__search_lastName'}
                                fullWidth
                                size="small"
                                label="Last Name"
                                disabled={this.props.isLoading}
                                placeholder="Last Name"
                                value={this.props.search.lastName}
                                onChange={this.onLastNameChange}
                                textAlign={{xs: 'start'}}
                                labelGridProps={{xs: 12, sm: 12, md: 3}}
                                inputGridProps={{xs: 12, sm: 12, md: 9}}
                            />
                        </Box>
                    </Col>
                </Row>
                <Row
                    id={this.props.id + '__row_searchContacts_wrapper'}
                    className="text-end"
                >
                    <Col id={this.props.id + '__col_searchContacts_wrapper'}>
                        {/* <Box style={{float: 'right'}}> */}
                        <Button
                            id={this.props.id + '__button_searchContacts'}
                            variant="outlined"
                            sx={{mt: 1}}
                            className="myOdu__button primary contactManagerButton"
                            onClick={this.props.onSearch}
                            disabled={
                                this.props.isLoading ||
                                (!this.props.search.firstName &&
                                    !this.props.search.lastName)
                            }
                        >
                            {'Search'}
                        </Button>
                        {/* </Box> */}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        search: state.myContactsReducer.search,
        isLoading: state.myContactsReducer.isLoading
    };
};

const mapDispatchToProps = dispatch => ({
    saveContactsSearch: search => dispatch(saveContactsSearch(search))
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
