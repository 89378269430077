import {Box, Grid, Skeleton, Stack, Switch, Typography} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import ContactCard from '../common/ContactCard';
import {saveContacts, saveContactsSearch, saveContactsUpdate, toggleContactsDragging} from './actions';
import {toast} from 'react-toastify';
import DNDContext from './DNDContext';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { MOVE_CONTACT_DOWN, MOVE_CONTACT_UP, MOVE_WIDGET_DOWN, MOVE_WIDGET_UP } from '../common/constants';

const grid = 8;

class List extends Component {
    state = {
        isDNDSelected: true
    };

    toggleIsDNDSelected = (isDNDSelected = !this.state.isDNDSelected) => {
        this.setState({
            isDNDSelected
        })
    }

    //Θ(1) style for DnD list
    getListStyle = isDraggingOver => ({});

    //Θ(1) style for DnD item
    getItemStyle = (isDragging, draggableStyle) => ({
        paddingTop: 8,

        // styles we need to apply on draggables
        ...draggableStyle
    });

    //Θ(1) filter out contact to be deleted and update
    onDelete = data => {
        this.props.saveContactsUpdate(
            this.props.contacts
                .map(contact => contact.midas)
                .filter(contact => contact != data.midas)
        );
        toast.success(
            'Contact for ' +
                data.lastName.toUpperCase() +
                ', ' +
                data.firstName.toUpperCase() +
                ' deleted'
        );
    };

    //θ(N) where N is the number of contacts
    //Moves the contacts up and down
    onMoveContact = (midas, direction) => {
        let updatedContacts = this.props.contacts;
        const contactIndex = this.props.contacts.findIndex((item) => item.midas === midas);

        if(direction === MOVE_CONTACT_UP && contactIndex !== 0) {
            updatedContacts = arrayMove(this.props.contacts, contactIndex, contactIndex-1);
        }
        else if(direction === MOVE_CONTACT_DOWN && contactIndex !== (this.props.contacts.length - 1)) {
            updatedContacts = arrayMove(this.props.contacts, contactIndex, contactIndex+1);
        }

        this.props.saveContacts(updatedContacts)
        this.props.saveContactsUpdate(
            updatedContacts.map(contact => contact.midas)
        );
    }

    //θ(N) where N is the number of contacts
    //Reorders the contacts
    onDragEnd = (result) => {
        const {active, over} = result;
        const items = result?.over?.data?.current?.sortable?.items;
        if (active?.id && over?.id && active.id !== over.id) {
            const oldIndex = this.props.contacts.findIndex((item) => item.midas === active.id);
            const newIndex = this.props.contacts.findIndex((item) => item.midas === over.id);

            //Array move is the function of dnd kit which reorders the apps. It moves all the items to left
            const updatedContacts = arrayMove(this.props.contacts, oldIndex, newIndex);
            this.props.saveContacts(updatedContacts)
            this.props.saveContactsUpdate(
                updatedContacts.map(contact => contact.midas)
            );
        }
    }

    render() {
        const contactsLength = this.props?.contacts?.length;
        return (
            <React.Fragment>
                <Typography
                    id={this.props.id + '__text_contactList'}
                    component="h3"
                >
                    Contact List
                </Typography>
                {
                    contactsLength > 1 &&
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            className="myOdu__toggleSwitch"
                        >
                            <Stack sx={{width: '100%'}} direction={{xs: 'column', lg: 'row'}} 
                                justifyContent={'flex-start'}
                            > 
                                <label>   
                                    Choose Reorder mode: &nbsp;
                                </label>
                                
                                <Stack direction="row">
                                    <Typography>
                                        Drag and Drop
                                    </Typography>
                                        <Switch 
                                            id = "contactManager__contactList_switch_toggleDND"
                                            size="small"
                                            color='primary'
                                            value={this.state.isDNDSelected}
                                            onChange={() => {this.toggleIsDNDSelected()}}
                                        />
                                    <Typography>
                                        Keyboard Accessible
                                    </Typography>
                                </Stack> 
                            </Stack>
                        </Stack>
                }
                {
                    contactsLength > 0 ?
                    <DNDContext
                        id={this.props.id + '__div_dragDropContext'}
                        onDragEnd={this.onDragEnd}
                        modifiers = {[restrictToVerticalAxis]}
                    >
                        <SortableContext items={this.props.contacts.map(contact => contact.midas)}>
                            {
                                this.props?.contacts?.map((contact, index, contacts) => (
                                    <SortableItem 
                                        id ={contact.midas}
                                        key = { contact.midas}
                                        midas={contact.midas}
                                        data={contact}
                                        onDelete={this.onDelete}
                                        isDisabled={this.props.isLoading}
                                        isDelete
                                        isDNDSelected = {this.state.isDNDSelected}
                                        showDrag = {this.state.isDNDSelected && contacts.length > 1}
                                        onMoveContact = {this.onMoveContact}
                                        hideUp = {index === 0 || contacts.length === 1 || this.state.isDNDSelected}
                                        hideDown = {index === contacts.length - 1 || contacts.length === 1 || this.state.isDNDSelected}
                                    />
                                ))
                            }
                        </SortableContext>
                    </DNDContext>
                    : <Box>
                        <Typography
                            id={this.props.id + '__text_contactList_not_found'}
                            component="p"
                        >
                            No contacts found. Add contacts.
                        </Typography>
                    </Box>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.myContactsReducer.contacts,
        results: state.myContactsReducer.results,
        isLoading: state.myContactsReducer.isLoading
    };
};

const mapDispatchToProps = dispatch => ({
    saveContactsSearch: search => dispatch(saveContactsSearch(search)),
    saveContactsUpdate: updates => dispatch(saveContactsUpdate(updates)),
    saveContacts: contacts => dispatch(saveContacts(contacts)),
    toggleContactsDragging: () => dispatch(toggleContactsDragging())
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
