import React from 'react';
import {Typography, Stack, Link} from '@mui/material';
import {faFaceSmileRelaxed} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const PositionNotice = ({course}) => {
    const {POSITION: position} = course;

    const renderComponent = pos => {
        const components = {
            1: (
                <>
                    Yay! You're up next. Check your email soon for details.{' '}
                    <FontAwesomeIcon icon={faFaceSmileRelaxed} />
                </>
            ),
            2: "You're on deck!",
            default: 'People ahead of you.'
        };

        return (
            <Typography className="notice">
                {components[pos] || components.default}
            </Typography>
        );
    };

    const component = renderComponent(position);

    return (
        <Stack alignItems="center">
            <Typography
                className={`position ${position <= 3 && 'myOdu__success'}`}
            >
                {position}
            </Typography>

            {component}
        </Stack>
    );
};
