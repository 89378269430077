import { SAVE_CONTACTS, SAVE_CONTACTS_RESULTS, SAVE_CONTACTS_SEARCH, SAVE_CONTACTS_UPDATE, TOGGLE_CONTACTS_DRAGGING, TOGGLE_CONTACTS_LOADING, TOGGLE_CONTACTS_MANAGER } from "./actions";


const initialStateMyContacts = {
    isContactsmanager: false,
    isLoading: true,
    isDragging: false,
    contacts: [],
    results: [],
    updates: null,
    search: {
        firstName: '',
        lastName: ''
    }
}

//does not needs to persist across pages
export const myContactsReducer = (state = initialStateMyContacts, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_CONTACTS: {
            return {...state, contacts: payload.contacts}
        }
        case SAVE_CONTACTS_UPDATE: {
            return {...state, updates: payload?.contacts ?? null}
        }
        case SAVE_CONTACTS_SEARCH: {
            return {...state, search: payload?.search ?? initialStateMyContacts.search}
        }
        case SAVE_CONTACTS_RESULTS: {
            return {...state, results: payload.results ?? []}
        }
        case TOGGLE_CONTACTS_MANAGER: {
            return {...state, isContactsmanager: !state.isContactsmanager}
        }
        case TOGGLE_CONTACTS_LOADING: {
            return {...state, isLoading: !state.isLoading}
        }
        case TOGGLE_CONTACTS_DRAGGING: {
            return {...state, isDragging: !state.isDragging}
        }
        default:
            return state;
    }
}