import React, {useEffect, useState, useRef} from 'react';
import {Box, Typography, IconButton} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLightbulbOn, faTimes} from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from './PortalTooltip';
import {useSelector} from 'react-redux';
import {patchData} from '../../DataAccessLayer';
import {infoTips} from '../../DataAccessLayer/services';
import ReactDOM from 'react-dom';
import {isHTML} from './utils';

export const InfoTip = ({
    id,
    openTargetId,
    title,
    children,
    message,
    showCloseIcon = true,
    placement = 'right-start',
    closeTargetId,
    ...props
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const manuallyClosed = useRef(false);
    const popperInstanceRef = useRef(null);

    const isImpersonating = useSelector(
        state =>
            state.impersonationReducer.impersonation?.isImpersonating ?? false
    );

    const handleClose = async () => {
        try {
            if (!isImpersonating) {
                await patchData(infoTips, {id}, true);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setAnchorEl(null);
        }
    };

    useEffect(() => {
        // We want to observe changes in the DOM to determine when to open or close the tooltip.
        // If the `closeTargetElement` is found, we close the tooltip by setting `setAnchorEl(null)`.
        // If the `openTargetElement` is present in the DOM, is not manually closed, and is not
        // marked as dismissed in preferences, we open or reopen the tooltip by setting `setAnchorEl(openTargetElement)`.
        // Additionally, if the Popper instance is available, we update its position to ensure it is correctly placed.
        // If neither condition is met, we close the tooltip.
        const observer = new MutationObserver(() => {
            const openTargetElement = document.getElementById(openTargetId);
            const closeTargetElement = document.getElementById(closeTargetId);

            if (closeTargetElement) {
                setAnchorEl(null);
            } else if (
                document.body.contains(openTargetElement) &&
                openTargetElement &&
                !manuallyClosed.current
            ) {
                setAnchorEl(openTargetElement); // Reopen if the target is available
                if (popperInstanceRef.current) {
                    popperInstanceRef.current.update(); // Manually update the Popper position
                }
            } else {
                setAnchorEl(null);
            }
        });

        observer.observe(document.body, {childList: true, subtree: true});

        return () => {
            observer.disconnect(); // Clean up the observer on component unmount
        };
    }, [openTargetId, closeTargetId]);

    const handleManualClose = () => {
        manuallyClosed.current = true;
        handleClose();
    };

    if (!anchorEl) {
        return null;
    }

    return ReactDOM.createPortal(
        <PortalTooltip
            disableHoverListener
            disableFocusListener
            title={
                message && (
                    <div className="myOdu__infoTip">
                        <Box className="header">
                            <FontAwesomeIcon
                                icon={faLightbulbOn}
                                size="xl"
                                className="lightBulb"
                            />
                            <Typography className="title">
                                {title || 'Did You Know...'}
                            </Typography>
                            {showCloseIcon && (
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    onClick={handleManualClose}
                                    className="myOdu__iconButton closeIcon"
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </IconButton>
                            )}
                        </Box>
                        <Box className="message">
                            {isHTML(message) ? (
                                <div
                                    dangerouslySetInnerHTML={{__html: message}}
                                />
                            ) : (
                                message
                            )}
                        </Box>
                    </div>
                )
            }
            open={Boolean(anchorEl)}
            onClose={handleManualClose}
            arrow
            placement={placement}
            PopperProps={{
                anchorEl: anchorEl,
                popperRef: instance => {
                    popperInstanceRef.current = instance;
                },
                sx: {zIndex: 1299}
            }}
            {...props}
        >
            <span></span>
        </PortalTooltip>,
        document.body
    );
};
