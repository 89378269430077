import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Box, Badge, Typography, Breadcrumbs, Link, List, ListItem, Skeleton } from '@mui/material';
import {Container} from 'reactstrap';
import PortalTooltip from '../common/PortalTooltip';
import {saveChecklists, saveChecklistsIsError} from './actions';
import {checklists} from '../../DataAccessLayer/services';
import {getData} from '../../DataAccessLayer';
import PortalAlert from '../common/PortalAlert';
import ApiErrorMessage from '../common/ApiErrorMessage';
import WithRouter from '../Layout/Router/WithRouter';

class Checklists extends Component {
    state = {
        isLoading: false
    };

    componentDidMount() {
        if (!this.props.checklists && !this.props.isError) {
            this.toggleIsLoading(true);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.checklists !== this.props.checklists &&
                this.props.checklists) ||
            (prevProps.isError !== this.props.isError && this.props.isError)
        ) {
            this.toggleIsLoading(false);
        }
    }

    toggleIsLoading = isLoading => {
        this.setState({isLoading});
    };

    loadChecklists = () => {
        this.toggleIsLoading(true);
        this.props.saveChecklistsIsError(false);
        getData(checklists, true)
            .then(checklists => {
                this.props.saveChecklists(checklists?.checklists); // Also marks Checklists isError to false in the redux
            })
            .catch(err => {
                console.log(err);
                this.props.saveChecklistsIsError(true);
            })
            .finally(() => this.toggleIsLoading(false));
    };

    render() {
        return (
            <div className="myOdu__checklists">
                <Container
                    id={"myChecklists__container_pageWrapper"}
                    className="myOdu__container secondaryPage"
                >
                    <Typography
                        id={"myChecklists__h2_myChecklistsTitle"}
                       component="h2"
                    >
                        My Checklists
                    </Typography>

                    <Breadcrumbs className="myOdu__breadcrumbs" id={"myChecklists__breadCrumbs"} aria-label="breadcrumb">
                        <Link
                            id={"myChecklist__breadCrumbs_linkDashboard"}
                            underline="hover"
                            href="/"
                        >
                            Dashboard
                        </Link>
                        <Typography id={"myChecklist__breadCrumbs_index"}>
                            Checklists
                        </Typography>
                    </Breadcrumbs>


                    <Box className="wrapper">
                        {!this.state.isLoading && this.props.isError && (
                            <PortalAlert severity="error" type="checklist">
                                <ApiErrorMessage
                                    reload={this.loadChecklists}
                                    widgetName="Checklists"
                                    isPage={true}
                                />
                            </PortalAlert>
                        )}
                        {this.state.isLoading && <Skeleton variant="rectangular" />}
                        {!this.state.isLoading &&
                            !this.props.isError &&
                            this.props.checklists?.length > 0 && (
                                <Box className="wrapper">
                                    <List sx={{pt: 0, pl: 2, listStyleType: 'disc'}}>
                                        {this.props.checklists.map(checklist => {
                                            const code = checklist.checklist_time_code;
                                            return (
                                                <ListItem
                                                    key={code + '_listitem'}
                                                    sx={{
                                                        p: 0,
                                                        display: 'list-item'
                                                    }}
                                                >
                                                    <Link
                                                        variant="small"
                                                        onClick={() => {
                                                            this.props.navigate(
                                                                '/checklists/' + code,
                                                                {
                                                                    state: {
                                                                        name: checklist.checklist_desc
                                                                    }
                                                                }
                                                            );
                                                        }}
                                                        underline="always"
                                                        color="inherit"
                                                        id={'checklist__link_' + code}
                                                        sx={{
                                                            mr: 1,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {checklist.checklist_desc}
                                                    </Link>
                                                    <PortalTooltip
                                                        title={`${checklist.checklist_items_completed} items checked`}
                                                    >
                                                        <Badge
                                                            badgeContent={
                                                                checklist.checklist_items_completed
                                                            }
                                                            max={10}
                                                            sx={{cursor: 'pointer'}}
                                                            className="myOdu__badge checklistBadge"
                                                        />
                                                    </PortalTooltip>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Box>
                            )}
                        {!this.state.isLoading &&
                            this.props.checklists?.length === 0 && (
                                <Box className="wrapper">
                                    <Typography component="p" class="noChecklistFound">
                                        No checklist found.
                                    </Typography>
                                </Box>
                            )}
                    </Box>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        checklists: state.myChecklistsReducer.checklists,
        isError: state.myChecklistsReducer.isError
    };
};

const mapDispatchToProps = dispatch => ({
    saveChecklists: checklists => dispatch(saveChecklists(checklists)),
    saveChecklistsIsError: isError => dispatch(saveChecklistsIsError(isError))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithRouter(Checklists));
