
//Θ(N) where N is the length of Emails

import { FACULTY_EMAIL_CODE, STUDENT_EMAIL_CODE } from "../../../../../common/constants";

//Returns email address of user or impersonating user
export const getUserEmail = (user, impersonation) => {
    
    if(!impersonation.isImpersonating)
        //If not impersonating the user's email
        return user.email
    
    let fEmail;
    let sEmail;

    //Else iterate over the emails list
    impersonation?.emails?.forEach(emailDetails => {
        if(emailDetails.emailCode === FACULTY_EMAIL_CODE)
            fEmail = emailDetails.address;
        else if(emailDetails.emailCode === STUDENT_EMAIL_CODE)
            sEmail = emailDetails.address
    })

    return fEmail ?? sEmail ?? (impersonation.midas + "@odu.edu");
}