import React, {Component} from 'react';
import {Link, Stack, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhone} from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from '../common/PortalTooltip';

class AlertContact extends Component {
  render() {
    const address = this.props.addressLine2
      ? this.props.addressLine1 + ', ' + this.props.addressLine2
      : this.props.addressLine1;
    return (
      <Stack id={this.props.id+"__stackWrapper"}>
        <Typography
            id={this.props.id+"__titleContact"}
          sx={{
            color: 'primary.main',
            fontWeight: '600'
          }}
          variant="small"
          component="h4"
        >
          Contact
        </Typography>
        {this.props.addressLine1 &&
          (this.props.contactUrl ? (
            <div id={this.props.id+"__div_officeAddress"}>
              <PortalTooltip
                id={this.props.id+"__portalTooltip_officeAddress"}
              >
                <Link
                  href={this.props.contactUrl}
                  variant="small"
                  target="_blank"
                  underline="always"
                  color="inherit"
                  sx={{cursor: 'pointer'}}
                  id={this.props.id+"__link_address"}
                >
                  {address}
                </Link>
              </PortalTooltip>
            </div>
          ) : (
            <Typography id={this.props.id+"__text_address"} variant="small">{address}</Typography>
          ))}
        {this.props.phoneNumber && (
          <div id={this.props.id+"__div_phoneNumber"}>
            <PortalTooltip
              id={this.props.id+"__tooltip_phoneNumber"}
              title={'Call ' + this.props.phoneNumber}
            >
              <Link
                href={'tel:' + this.props.phoneNumber}
                variant="small"
                underline="always"
                color="inherit"
                sx={{cursor: 'pointer'}}
                id={this.props.id+"__link_phone"}>
                <FontAwesomeIcon
                  id={this.props.id+"__icon_phone"}
                  icon={faPhone}
                  style={{
                    marginRight: '0.25rem'
                  }}
                />
                {this.props.phoneNumber}
              </Link>
            </PortalTooltip>
          </div>
        )}
        {this.props.email && (
          <div id={this.props.id+'__div_email'}>
            <PortalTooltip
              id={this.props.id+'__email_tooltip'}
              title={'Email ' + this.props.email}
            >
              <Link
                href={'mailTo:' + this.props.email}
                variant="small"
                underline="always"
                color="inherit"
                sx={{cursor: 'pointer'}}
                id={this.props.id+"__link_email"}
              >
                <FontAwesomeIcon
                  id={this.props.id+"__icon_email"}
                  icon={faEnvelope}
                  style={{
                    marginRight: '0.25rem'
                  }}
                />
                {this.props.email}
              </Link>
            </PortalTooltip>
          </div>
        )}
      </Stack>
    );
  }
}

export default AlertContact;
