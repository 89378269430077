import {Typography, Link} from '@mui/material';
import React, {Component} from 'react';
import PortalModal from '../../../../common/PortalModal';
import Loader from '../../../../common/Loader';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {deleteData, getData, postData} from '../../../../../DataAccessLayer';
import {googleDrive} from '../../../../../DataAccessLayer/services';
import {FormButton} from '../../../../common/Form/FormButton';
import {Container, Row, Col} from 'reactstrap';
import {WithSmallScreen} from '../../../../common/WithSmallScreen';
import {getCourseMetadata} from '../utils';

class GoogleDrive extends Component {
    state = {
        drive: {},
        isDeleteModalOpen: false,
        isLoading: false
    };

    componentDidMount() {
        this.loadGoogleDrive();
    }

    handleToggleModal = modal => {
        this.setState({[modal]: !this.state[modal]});
    };

    //Θ(1)
    loadGoogleDrive = async () => {
        const {termCode, crn} = getCourseMetadata(this.props.course);

        this.setState({isLoading: true});
        try {
            const data = await getData(
                googleDrive.load({
                    crn,
                    termCode
                }),
                true
            );

            this.setState({drive: data});
        } catch (err) {
            console.log(err);
            this.setState({drive: {}});
        } finally {
            this.setState({isLoading: false});
        }
    };

    //Θ(1)
    createSharedDrive = async () => {
        const {termCode, crn, subjectCode, courseNumber, courseTitle} =
            getCourseMetadata(this.props.course);

        const body = {
            crn,
            termCode,
            driveName: `${termCode}_${subjectCode}_${courseNumber}_${courseTitle}`
        };

        this.setState({isLoading: true});
        try {
            await postData(googleDrive.create, body, true);
            toast.success('Drive Created!');
        } catch (err) {
            if (err.timeout) {
                toast.error(
                    'Pending Creating Drive. Please check back in 15 minutes'
                );
            } else {
                toast.error('Error Creating Drive');
            }
        } finally {
            this.setState({isLoading: false});
            this.loadGoogleDrive();
        }
    };

    //Θ(1)
    deleteSharedDrive = async () => {
        const {termCode, crn} = getCourseMetadata(this.props.course);

        this.setState({isLoading: true});
        try {
            await deleteData(
                googleDrive.delete({
                    crn,
                    termCode
                }),
                true
            );
            toast.success('Drive Deleted!');
        } catch (err) {
            toast.error('Error Deleting Drive');
        } finally {
            this.setState({isLoading: false});
            this.handleToggleModal('isDeleteModalOpen');
            this.loadGoogleDrive();
        }
    };

    render() {
        const {isLoading, isDeleteModalOpen, drive} = this.state;

        const {course} = this.props;

        const isTeaching = course.isTeaching;

        return (
            <React.Fragment>
                <Container className="p-3">
                    <Loader isOpen={isLoading} />
                    <Typography variant="h3">Google Drive</Typography>
                    <Row
                        className={`justify-content-center align-items-center ${
                            !drive.URL ? 'flex-column' : 'flex-row'
                        }`}
                    >
                        <Col
                            xs="12"
                            sm="6"
                            md="6"
                            lg="3"
                            className={`d-flex ${
                                !drive.URL
                                    ? 'justify-content-center'
                                    : 'justify-content-end'
                            } align-items-center`}
                            style={{height: 200, width: 200}}
                        >
                            <img
                                src={
                                    !drive.URL
                                        ? '/img/cct_googleDriveSearch.svg'
                                        : '/img/cct_googleDrive.svg'
                                }
                                alt="Google Drive"
                                style={{width: '200px', height: 'auto'}}
                            />
                        </Col>

                        {!drive.URL ? (
                            <Row className="w-100">
                                <Col className="text-center p-2">
                                    <Typography>
                                        There is no Google Drive for this class.{' '}
                                        {isTeaching && (
                                            <Link
                                                sx={{cursor: 'pointer'}}
                                                onClick={this.createSharedDrive}
                                            >
                                                Create a Google Drive
                                            </Link>
                                        )}
                                    </Typography>
                                </Col>
                            </Row>
                        ) : (
                            <Col
                                xs="12"
                                lg="3"
                                className="d-flex flex-column align-items-center"
                            >
                                <FormButton
                                    label="Launch Google Drive"
                                    href={drive.URL}
                                    target="_blank"
                                    disabled={!drive.URL}
                                />
                                {isTeaching && (
                                    <Link
                                        sx={{
                                            cursor: 'pointer',
                                            marginTop: '20px'
                                        }}
                                        onClick={() =>
                                            this.handleToggleModal(
                                                'isDeleteModalOpen'
                                            )
                                        }
                                    >
                                        Delete Google Drive
                                    </Link>
                                )}
                            </Col>
                        )}
                    </Row>
                </Container>

                <PortalModal
                    severity="error"
                    maxWidth="sm"
                    onClose={() => this.handleToggleModal('isDeleteModalOpen')}
                    onPrimaryClick={this.deleteSharedDrive}
                    onSecondaryClick={() =>
                        this.handleToggleModal('isDeleteModalOpen')
                    }
                    isOpen={isDeleteModalOpen}
                    title="Delete Google Drive"
                    primaryButtonTitle="Yes"
                    secondaryButtonTitle="No, Cancel"
                >
                    <Loader isOpen={isLoading} />
                    <Typography>
                        Are you sure you want to delete this drive?
                    </Typography>
                </PortalModal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course
    };
};

export default connect(mapStateToProps)(WithSmallScreen(GoogleDrive));
