import {LinearProgress, Box} from '@mui/material';
import React, {Component} from 'react';
import PortalDataGrid from '../../common/PortalDataGrid';

class FeedbackTable extends Component {
    state = {
        page: 0,
        rowsPerPage: 10
    };

    render() {
        return (
                <Box className={this.props.className??''}>
                    <PortalDataGrid
                        rows={this.props.rows}
                        columns={this.props.columns}
                        handleClick={this.props.onClick}
                        isLoading={this.props.isLoading}
                        columnVisibilityModel={{
                            ...this.props.columnVisibilityModel,
                            createdAt: false
                        }}
                        
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 20}
                            }
                        }}
                        getRowId={row => {
                            return row.id
                                ? row.id
                                : row.MIDAS.concat('_', row.TIMESTAMP);
                        }}
                        slots={{
                            ...this.props.slots,
                            loadingOverlay: LinearProgress
                        }}
                        slotProps={this.props.slotProps}
                        disableColumnFilter={this.props.disableColumnFilter}
                        disableColumnSelector={this.props.disableColumnSelector}
                        disableDensitySelector={
                            this.props.disableDensitySelector
                        }
                    />
                </Box>

        );
    }
}

export default FeedbackTable;
