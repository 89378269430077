import React, {useEffect} from 'react';
import {Typography, Container, Stack, Box} from '@mui/material';
import {FormControlledPhoneField} from '../common/Form/FormPhoneField';
import {FormControlledTextField} from '../common/Form/FormTextField';
import {useFormContext} from 'react-hook-form';
import _ from 'lodash';

export const UniversityCommunicationSection = ({control}) => {
  const {watch, setValue} = useFormContext();

  const mobilePhone = watch('mobilePhone');
  const currentPhone = watch('currentPhone');
  const permanentPhone = watch('permanentPhone');

  const currentSameAsMobilePhoneNumber = watch(
    `currentSameAsMobilePhoneNumber`
  );

  const permanentSameAsMobilePhoneNumber = watch(
    `permanentSameAsMobilePhoneNumber`
  );

  if (!_.isEqual(mobilePhone, currentPhone) && currentSameAsMobilePhoneNumber) {
    setValue('currentPhone', {...mobilePhone});
  }

  if (
    !_.isEqual(mobilePhone, permanentPhone) &&
    permanentSameAsMobilePhoneNumber
  ) {
    setValue('permanentPhone', {...mobilePhone});
  }

  useEffect(() => {
    setValue(
      'currentSameAsMobilePhoneNumber',
      _.isEqual(mobilePhone, currentPhone)
    );
  }, [mobilePhone, currentPhone, setValue]);

  useEffect(() => {
    setValue(
      'permanentSameAsMobilePhoneNumber',
      _.isEqual(mobilePhone, permanentPhone)
    );
  }, [mobilePhone, permanentPhone, setValue]);

  useEffect(() => {
    if (currentSameAsMobilePhoneNumber) {
      setValue('currentPhone', {...mobilePhone});
    }
  }, [currentSameAsMobilePhoneNumber, mobilePhone, setValue]);

  useEffect(() => {
    if (permanentSameAsMobilePhoneNumber) {
      setValue('permanentPhone', {...mobilePhone});
    }
  }, [permanentSameAsMobilePhoneNumber, mobilePhone, setValue]);

  return (
    <Box className="universityCommunications">
      <Typography component="h3">
        University Communications
      </Typography>
      <Typography>
        The University is committed to information privacy. The information
        below may be used in the event of an emergency or to provide important
        University information.
      </Typography>

      <Box sx={{flexGrow: 1, mt: 3, mb: 3}}>
        <Stack direction="column" marginTop={3} gap={1}>
          {/* FormTextField for Non-ODU Email */}
          <FormControlledTextField
            name="alternateEmail"
            control={control}
            label="Non-ODU Email Address"
            rules={{
              required: 'Email is required',

              validate: {
                correctEmailFormat: value => {
                  let message;
                  if (value) {
                    if (
                      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                        value
                      )
                    ) {
                      message = 'Enter valid email format';
                    } else if (
                      !/^(?!.*@odu\.edu)(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+)$/i.test(
                        value
                      )
                    ) {
                      message = 'Enter a non-ODU email address.';
                    }

                    return message;
                  }
                }
              }
            }}
            required
          />

          <div style={{marginTop: 10}}>
            <FormControlledPhoneField
              name={'mobilePhone'}
              control={control}
              label={'Mobile Phone'}
              required
              rules={{
                phoneTypeRule: {
                  required: 'Type is required'
                },
                phoneCountryCodeRule: {
                  required: 'Country code is required'
                },
                phoneNumberRule: {
                  required: 'Phone number is required'
                }
              }}
            />
          </div>

          <div style={{marginTop: 10}}>
            <FormControlledPhoneField
              name={'currentPhone'}
              control={control}
              label={'Current Phone'}
              required
              rules={{
                phoneTypeRule: {
                  required: 'Type is required'
                },
                phoneCountryCodeRule: {
                  required: 'Country code is required'
                },
                phoneNumberRule: {
                  required: 'Phone number is required'
                }
              }}
              sameAsOther={{
                name: 'currentSameAsMobilePhoneNumber',
                label: 'Same as mobile number',
                value: currentSameAsMobilePhoneNumber,
                gridProps: {md: 3, lg: 4, xl: 3}
              }}
            />
          </div>

          <div style={{marginTop: 10}}>
            <FormControlledPhoneField
              name={'permanentPhone'}
              control={control}
              label={'Permanent Phone'}
              required
              rules={{
                phoneTypeRule: {
                  required: 'Type is required'
                },
                phoneCountryCodeRule: {
                  required: 'Country code is required'
                },
                phoneNumberRule: {
                  required: 'Phone number is required'
                }
              }}
              sameAsOther={{
                name: 'permanentSameAsMobilePhoneNumber',
                label: 'Same as mobile number',
                value: permanentSameAsMobilePhoneNumber,
                gridProps: {md: 3, lg: 4, xl: 3}
              }}
            />
          </div>
        </Stack>
      </Box>
    </Box>
  );
};
