export const TOGGLE_CCT = 'TOGGLE_CCT';
export const SAVE_ZOOM_MEETINGS = 'SAVE_ZOOM_MEETINGS';

export const toggleCCT = (course) => ({
    type: TOGGLE_CCT,
    payload: {course}
});

export const saveZoomMeetings = (meetings) => ({
    type: SAVE_ZOOM_MEETINGS,
    payload: {meetings}
})