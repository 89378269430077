import { SAVE_USER } from "./actions";

const initialStateAWS = {
    user: {},
}

//needs to persist across pages
export const AWSReducer = (state = initialStateAWS, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_USER: {
            return {...state, user: payload.user}
        }
        default: 
            return state;
    }
}