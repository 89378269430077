import {logCognitoError} from './DataAccessLayer';

const interceptUrlParams = () => {
    const params = new URLSearchParams(window.location.search);

    const cognitoError = {
        state: params.get('state'),
        error: params.get('error'),
        description: params.get('error_description')
    };

    if (cognitoError.error) {
        logCognitoError(cognitoError);

        return cognitoError;
    }

    return false;
};

export default interceptUrlParams;
