import { faAlarmClock, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Link, Menu, MenuItem, Typography, Stack } from '@mui/material';
import moment from 'moment';
import React, {Component} from 'react';
import { Time } from '../../../common/MomentTime';
import { startMeeting } from '../utils';
import { connect } from 'react-redux';
import { getUserEmail } from '../Body/Zoom/ScheduleMeeting/utils';

class StartMeeting extends Component {
    state = {

    }

    //Θ(N) where N is length of meetings starting in 30 mins
    //Returns the Meeting start title
    getHeaderMeetingTitle = (meetings) => {
        let meetingStartingNext = meetings.find(meeting => {
            return Time.University(meeting.start_time).isAfter(Time.University());
        });
        if(meetingStartingNext !== undefined) {
            return 'Meeting starts in ' + Math.ceil(moment.duration(Time.University(meetingStartingNext.start_time).diff(Time.University())).asMinutes()) + ' minutes';
        }

        return '';
    }

    onStartMeeting = (meetingDetails) => {
        startMeeting(getUserEmail(this.props.user, this.props.impersonation), meetingDetails, this.props.course);
    }

    render() {
        const {isTaking, isTeaching} = this.props;
        return <React.Fragment>
            <Stack className="startButtonWrapper" sx={{minWidth: '9rem'}}>
                {
                    this.props.meetingsToStart.length === 1 ?
                    <Button 
                        variant='outlined'
                        className="myOdu__button secondary startMeetingButton"
                        startIcon={<FontAwesomeIcon icon={faAlarmClock} size="sm" />}
                        target='_blank'
                        onClick={() => this.onStartMeeting(this.props.meetingsToStart[0])}
                    >{isTeaching ? 'Start Meeting' : 'Launch Meeting'}</Button> :
                    
                    <div>
                        <Button 
                            variant='outlined'
                            className="myOdu__button secondary startMeetingButton"
                            startIcon={<FontAwesomeIcon icon={faAlarmClock} size="xs" style={{marginLeft: "0.5rem"}} />}
                            endIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            onClick={this.props.dropAnchor}
                        >Start Meetings</Button>
                        <Menu
                            anchorEl={this.props.anchor}
                            open={this.props.isOpen}
                            onClose={this.props.pullAnchor}
                        >
                            {
                                this.props.meetingsToStart.map(meeting => {
                                    return <MenuItem onClick={() => {this.onStartMeeting(meeting)}}>
                                        {this.props.getMeetingStartTitle(meeting)}
                                    </MenuItem>
                                })
                            }
                        </Menu>
                    </div>
                }

                { this.props.meetingsToStart.length > 1 &&
                    <Typography variant="smaller" sx={{fontStyle: 'italic'}}>{this.getHeaderMeetingTitle(this.props.meetingsToStart)}</Typography>
                }
            </Stack>
        </React.Fragment>
    }
}
const mapStateToProps = (state) => {
    return {
        course: state.CCTReducer.course,
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation
    }
}
export default connect(mapStateToProps)(StartMeeting);