import { faFaceSurprise } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography, Button, Stack, Link} from "@mui/material"
import { errors } from "../../../DataAccessLayer/services";
import { postDataPublic } from "../../../DataAccessLayer";
import { connect } from "react-redux";
import React, { Component } from 'react';


class InvalidRoute extends Component {
    state = {  }

    componentDidMount(){
        this.logError(this.props.error)
    }

    logError = (error) => {
        const user = this.props.isImpersonating ? this.props.impersonation : this.props.user
        postDataPublic(errors, {error, midas: user?.midas})
    }

    render() { 
        return <React.Fragment>
        <Stack alignItems={"center"} id="page404__stack_wrapper">
            <FontAwesomeIcon icon={faFaceSurprise} fixedWidth size="9x" id="page404__icon_suprised"/>
            <Typography component="h2" className="myOdu__errorCode" id="page404__header_pageNotFound">Page Not Found</Typography>
            <Typography component="p" className="myOdu__errorCode" id="page404__text_oops">Oops! The page you are looking for cannot be found.</Typography>
            {
                !this.props.isHideHomePage &&
                <Button className="myOdu__errorCodeButton" href="/" sx={{mt:3}} variant="outlined" id="page404__button_goHome">
                    Go to the home page
                </Button>
            }
        </Stack>
    </React.Fragment>
    }
}
 
const mapStateToProps = state => {
    return {
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating,
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(InvalidRoute);
