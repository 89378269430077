import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    Tooltip,
    Typography,
    Stack
} from '@mui/material';
import {faTrash, faPencil} from '@fortawesome/pro-light-svg-icons';
import React, {Component} from 'react';
import {Box} from '@mui/system';
import AddBookmark from './AddBookmark';
import PortalDelete from '../common/PortalDelete';
import PortalTooltip from '../common/PortalTooltip';

class EditBookmark extends Component {
    state = {
        //if for uniqueness
        widgetId: 'widget__myBookmarks',

        //base message for common delete component
        deletionMessage: 'Are you sure you want to delete',

        //active bookmark to be edited
        activeEdit: {},

        //active boookmark to be deleted
        activeDelete: {}
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isReset !== this.props.isReset && this.props.isReset) {
            this.reset();
            this.props.toggleIsReset();
        }
    }

    //Θ(1) save bookmark to be edited
    activateEdit = activeEdit => {
        this.setState({activeEdit});
    };

    //Θ(1) save bookmark to be deleted
    activateDelete = activeDelete => {
        this.setState({activeDelete});
    };

    //Θ(1) reset acive bookmarks
    reset = () => {
        this.setState({activeEdit: {}, activeDelete: {}});
    };

    //Θ(1) trigger bookmark edit and reset
    onEditSubmit = (title, url) => {
        this.props.onEdit(title, url, this.state.activeEdit.CREATED_AT);
    };

    //Θ(1) trigger bookmark delete and reset
    onDeleteSubmit = () => {
        this.props.onDelete(this.state.activeDelete.CREATED_AT);
    };

    render() {
        const deletionMessage =
            this.state.deletionMessage +
            ' "' +
            this.state.activeDelete?.TITLE +
            '"?';
        return (
            <React.Fragment>
                {this.state.activeDelete?.CREATED_AT && (
                    <PortalDelete
                        onToggle={this.reset}
                        onDelete={this.onDeleteSubmit}
                        message={deletionMessage}
                        title={'Delete Bookmark'}
                    />
                )}
                <List
                    id={'editBookmarks__list_listItemWrapper'}
                    sx={{pt: 0}}
                    dense={true}
                >
                    {this.props.bookmarks.map(bookmark =>
                        (this.state.activeEdit?.CREATED_AT ?? 0) ==
                        bookmark.CREATED_AT ? (
                            <AddBookmark
                                id={
                                    'editBookmarks__addBookmark_listItemWrapper'
                                }
                                bookmark={bookmark}
                                onEditCancel={this.reset}
                                onEditSubmit={this.onEditSubmit}
                                isEdit
                                isReset={this.props.isReset}
                                toggleIsReset={this.props.toggleIsReset}
                                isDuplicate={this.props.isDuplicate}
                            />
                        ) : (
                            <ListItem
                                sx={{pl: 1}}
                                id={
                                    'myBookmarks__' +
                                    this.state.widgetId +
                                    'listItem' +
                                    bookmark.CREATED_AT
                                }
                                key={
                                    this.state.widgetId +
                                    '_ul_li_' +
                                    bookmark.CREATED_AT
                                }
                                divider
                            >
                                <Stack
                                    id={
                                        'myBookmarks__stack_listItem_' +
                                        bookmark.CREATED_AT
                                    }
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{width: '100%'}}
                                >
                                    <div
                                        id={
                                            'myBookmarks__div_listItem_' +
                                            bookmark.CREATED_AT
                                        }
                                    >
                                        <PortalTooltip
                                            id={
                                                'myBookmarks__portalTooltip_' +
                                                bookmark.CREATED_AT
                                            }
                                        >
                                            <Link
                                                variant="small"
                                                href={bookmark.URL}
                                                underline={'hover'}
                                                target="_blank"
                                                id={
                                                    'myBookmarks__link_editList_' +
                                                    bookmark.CREATED_AT
                                                }
                                                className="myOdu__lineClamp-1"
                                            >
                                                {bookmark.TITLE}
                                            </Link>
                                        </PortalTooltip>
                                    </div>
                                    <Box
                                        id={
                                            'myBookmarks__box_iconsWrapper_' +
                                            bookmark.CREATED_AT
                                        }
                                        sx={{
                                            textAlign: 'right',
                                            width: '275px'
                                        }}
                                    >
                                        <IconButton
                                            id={
                                                'myBookmarks__link_editBookmarks_edit_' +
                                                bookmark.CREATED_AT
                                            }
                                            bookmarkclassName="myOdu__iconButton "
                                            aria-label="edit"
                                            onClick={e =>
                                                this.activateEdit(bookmark)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPencil}
                                                fixedWidth
                                            />
                                        </IconButton>
                                        <IconButton
                                            id={
                                                'myBookmarks__link_editBookmarks_delete_' +
                                                bookmark.CREATED_AT
                                            }
                                            className="myOdu__iconButton"
                                            aria-label="delete"
                                            onClick={e =>
                                                this.activateDelete(bookmark)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                fixedWidth
                                            />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </ListItem>
                        )
                    )}
                </List>
                {this.props.bookmarks.length < 1 && (
                    <Typography
                        id={'myBookmarks__text_noBookmarksFound'}
                        variant="h6"
                        component="h4"
                        className="py-3"
                    >
                        No bookmarks found
                    </Typography>
                )}
            </React.Fragment>
        );
    }
}

export default EditBookmark;
