//Θ() Filters the selected apps from the apps preferences
export const getSelectedApps = (apps) => {
    const selectedApps = Object.entries(apps)
        .sort(([app1Id, app1Index], [app2Id, app2Index]) => {
            return app1Index < app2Index ? -1 : 1
        })
        .reduce((filteredApps, [appId, selected]) => {
            if(selected > -1) {
                filteredApps.push(appId)
            }
            return filteredApps
        }, []);
    
    return selectedApps;
}

//Θ(NXM)where N is the size of apps stored in preferences and M is the size of app config
//Remove the apps with bad id
export const filterBadData = (apps, appsConfig) => {
    for(var index in apps) {
        let configApp = appsConfig?.Apps?.find(app => app?.id === apps[index])
        if(!configApp) {
            apps.splice(index, 1)
        }
    };

    const appsDetails = apps.map((appId) => {
        return appsConfig.Apps.find(config => config?.id == appId);
    });
    return appsDetails;
}