import {InputLabel} from '@mui/material';
import {FormTextField} from './FormTextField';

export const FormTextArea = ({
    id,
    label,
    error,
    helperText,
    errorText,
    required,
    value,
    onChange,
    rows = 4,
    charCountEnabled = true,
    ...props
}) => {
    return (
        <FormTextField
            id={id}
            rows={rows}
            multiline
            label={label}
            ariaLabel={props.ariaLabel || label}
            error={error}
            helperText={helperText}
            errorText={errorText}
            required={required}
            charCountEnabled={charCountEnabled}
            value={value}
            onChange={onChange}
            isTextArea
            {...props}
        />
    );
};
