import {faX} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from '@mui/material';
import React from 'react';
import {Col, Row} from 'reactstrap';
import { cleanUpID } from '../../common/utils';

class DeleteDialog extends React.Component {
    state = {};

    render() {
        return (
            <React.Fragment>
                <Dialog
                    id={this.props.id+"__dialog_"+cleanUpID(this.props.title)}
                    open
                    fullWidth
                    className="myOdu__wizard"
                    onClose={this.props.toggleIsDeleteTutoring}
                >
                    <DialogTitle
                        id={this.props.id+"__dialogTitle_"+cleanUpID(this.props.title)}
                        sx={{
                            backgroundColor: '#003057',
                            color: 'white',
                            borderBottom: '3px solid #2188A2'
                        }}
                    >
                        <Row id={this.props.id+"__row_"+cleanUpID(this.props.title)}>
                            <Col id={this.props.id+"__col_"+cleanUpID(this.props.title)}>
                                <Typography id={this.props.id+"__title_"+cleanUpID(this.props.title)} variant="h4" component="h2">
                                    {this.props.title}
                                </Typography>
                            </Col>
                            <Col
                                id={this.props.id+"__col_"+cleanUpID(this.props.title)+"_iconButton_wrapper"}
                                xs={1}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'right'
                                }}
                            >
                                <IconButton
                                     id={this.props.id+"__iconButton_"+cleanUpID(this.props.title)+"_closeDialog"}
                                    size="small"
                                    onClick={this.props.toggleIsDeleteTutoring}
                                >
                                    <FontAwesomeIcon id={this.props.id+"__icon_"+cleanUpID(this.props.title)+"_closeDialog"}
                                        icon={faX}
                                        style={{color: '#ffffff'}}
                                    />
                                </IconButton>
                            </Col>
                        </Row>
                    </DialogTitle>

                    <DialogContent id={this.props.id+"__dialogContent_"+cleanUpID(this.props.title)} className="py-4">
                        <Typography id={this.props.id+"__text_"+cleanUpID(this.props.title)+"_title"}>
                            Are you sure you want to delete tutoring for{' '}
                            {this.props.tutoringToDelete?.SUBJECT}{' '}
                            {this.props.tutoringToDelete?.COURSE_NUMBER} at{' '}
                            {this.props.tutoringToDelete?.locations
                                ?.map(location => location.text)
                                .join(', ')}
                            ?
                        </Typography>
                    </DialogContent>

                    <DialogActions id={this.props.id+"__dialogActions_"+cleanUpID(this.props.title)}>
                        <Button id={this.props.id+"__button_"+cleanUpID(this.props.title)+"_noCancel"} onClick={this.props.toggleIsDeleteTutoring}>
                            <span id={this.props.id+"__text_"+cleanUpID(this.props.title)+"_noCancel"} style={{textDecoration: 'underline'}}>
                                no, cancel
                            </span>
                        </Button>
                        <Button id={this.props.id+"__button_"+cleanUpID(this.props.title)+"_yesDelete"}
                            variant="outlined"
                            onClick={this.props.onSubmit}
                            disabled={this.props.isLoading}
                        >
                            yes, delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default DeleteDialog;
