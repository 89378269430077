import React, {Component} from 'react';
import {Stack, Link, Typography, Avatar, Box, Portal} from '@mui/material';
import PortalTooltip from '../../common/PortalTooltip';

class PortalAvatar extends Component {
    formatLongNames = (firstName, lastName, isLoggedIn) => {
        return (firstName + ' ' + lastName).length < 22 ? (
            <Typography
                id="sideNav__name"
                className="avatarName"
                sx={{margin: '0 auto'}}
            >
                {isLoggedIn ? firstName + ' ' + lastName : 'unknown user'}
            </Typography>
        ) : (
            <>
                <Typography
                    id="sideNav__name_first"
                    className="avatarName"
                    sx={{margin: '0 auto', display: 'block'}}
                >
                    {isLoggedIn ? firstName : 'unknown first name'}
                </Typography>
                <Typography
                    id="sideNav__name_second"
                    className="avatarName"
                    sx={{margin: '0 auto', display: 'block'}}
                >
                    {isLoggedIn ? lastName : 'unknown last name'}
                </Typography>
            </>
        );
    };

    render() {
        const displayName = this.props.user?.displayName?.toUpperCase();
        let lastName = '',
            firstName = '';

        if (displayName) {
            // For impersionation, display name is <lastname>, <firstname>
            if (displayName.includes(',')) {
                const nameParts = displayName
                    .replace(/([A-Z]\.)+/g, ' ')
                    .trim()
                    .split(',');

                if (nameParts.length > 1) {
                    lastName = nameParts[0].trim(); // The first part as lastName
                    firstName = nameParts[1].trim(); // The second part as firstName
                } else {
                    firstName = nameParts[0].trim(); // If there's only one part, it's the firstName
                }
            } else {
                // For regular cognito, display name is <firstname> <lastname>
                const nameParts = displayName
                    .replace(/ ([A-Z]\. )+/g, ' ')
                    .trim()
                    .split(' ');
                if (nameParts.length > 1) {
                    lastName = nameParts[nameParts.length - 1].trim(); // The last part as lastName
                    firstName = nameParts.slice(0, -1).join(' '); // Everything except the last part as firstName
                } else {
                    firstName = nameParts[0].trim(); // If there's only one part, it's the firstName
                }
            }
        }

        return (
            <React.Fragment>
                <Stack
                    className="avatarWrapper"
                    direction={{xs: 'row', sm: 'column'}}
                    sx={{margin: '1rem auto', textAlign: 'center'}}
                >
                    <PortalTooltip
                        title={
                            <>
                                Update your photo by visiting{' '}
                                <Link
                                    href="https://www.odu.edu/card-center/id-card"
                                    target="_blank"
                                >
                                    {' '}
                                    Monarch Card and Business Services.
                                </Link>{' '}
                                (Opens in new window)
                            </>
                        }
                    >
                        <Avatar
                            id="sideNav__avatar"
                            className={
                                'avatar ' +
                                (this.props.isMenuOpen ? 'large ' : 'small')
                            }
                            title={
                                this.props.isLoggedIn
                                    ? firstName + ' ' + lastName
                                    : 'unknown user'
                            }
                            src={
                                this.props.isLoggedIn && this.props.isValidUrl
                                    ? this.props.img
                                    : ''
                            }
                        />
                    </PortalTooltip>

                    {this.props.isMenuOpen ? (
                        <>
                            <Box className="d-none d-sm-block mt-2">
                                {this.formatLongNames(
                                    firstName,
                                    lastName,
                                    this.props.isLoggedIn
                                )}
                            </Box>
                            <Box
                                className="d-block d-sm-none"
                                alignContent={'center'}
                            >
                                <Typography
                                    id="sideNav__name_mobile"
                                    component="h2"
                                    variant="h6"
                                    sx={{margin: '0 auto'}}
                                >
                                    {this.props.isLoggedIn
                                        ? firstName + ' ' + lastName
                                        : 'unknown user'}
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <Typography
                            id="sideNav__initials"
                            className="avatarName initials"
                        >
                            {this.props.isLoggedIn
                                ? firstName.charAt(0) + lastName.charAt(0)
                                : '?'}
                        </Typography>
                    )}
                </Stack>
            </React.Fragment>
        );
    }
}

export default PortalAvatar;
