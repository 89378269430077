//AWS cognito config

import { isProduction } from "../components/Layout/utils";

let user_pool_id;
let user_pool_client_id;
let oauth_domain;

let override_auth = process.env?.REACT_APP_OVERRIDE_AUTH?.trim();

if(override_auth === 'prod' || isProduction()) {
    // production values
    user_pool_id = "us-east-1_vx7WTv0bb";
    user_pool_client_id = "53vmarvgicfd79ilmk49d6jffi";
    oauth_domain = "oduportal.auth.us-east-1.amazoncognito.com";
} else {
    // non-production values
    user_pool_id = "us-east-1_cksGfHCvH";
    user_pool_client_id = "14oaer5f1u8tpkuc675u2ff5ol";
    oauth_domain = "odumobilepprd.auth.us-east-1.amazoncognito.com";
}

export default {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: user_pool_id,
    aws_user_pools_web_client_id: user_pool_client_id,
    oauth: {
        domain: oauth_domain,
        scope: ['phone','email','profile','openid','aws.cognito.signin.user.admin'],
        redirectSignIn:  window.location.origin,
        redirectSignOut:  window.location.origin,
        responseType: 'code'
    }
}
