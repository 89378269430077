import {Box, Link, Typography, Stack, Grid, IconButton} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PortalTooltip from '../PortalTooltip';
import {AppConfigs} from '../../SetUp/config';
import {AlphabetBar} from '../../Landing/AlphabetBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart as faHeart_solid} from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import App from './App';
import { getSelectedAppsCount, removeApp, toggleAppSelection } from '../../SetUp/utils';
import { savePreferences } from '../../SetUp/actions';
import { getUser } from '../../Layout/utils';
import { myPreferences } from '../../../DataAccessLayer/services';
import { getData, putData } from '../../../DataAccessLayer';
import { deepCloneObject } from '../utils';

class ApplicationsList extends Component {
    state = {
        selectedLetter: '',
        selectedAppsCount: 0,
        //Used to record the ids of the apps going into the loading state when toggled
        loadingApps: {}
    };

    //Θ(1) Sets selectedAppsCount
    saveSelectedAppsCount = (selectedAppsCount) => {
        this.setState({
            selectedAppsCount
        })
    }

    componentDidMount() {
        this.loadPreferences();
    }

    handleSelectLetter = letter => {
        this.setState({selectedLetter: letter});
    };

    //Θ(N) where N is the total number of application in App config
    filterApps = text => {
        return AppConfigs.Apps.filter(app =>
            app.name.toLowerCase().includes(text.toLowerCase())
        ).reduce((groups, app) => {
            const key = app.name[0].toUpperCase();
            groups[key] = groups[key] || [];
            groups[key].push(app);
            return groups;
        }, {});
    };

    //Θ(1) checks if the application is already selected
    isAppSelected = (app) => {
        return this.props.preferences?.Apps?.Apps && app.id in this.props.preferences?.Apps?.Apps && this.props.preferences?.Apps?.Apps[app.id] > -1
    }

    //Θ(1) adds the appid into loadingApps object on loading and deletes when done
    updateLoadingApps = (appId, loading) => {

        if(!appId)
            return;

        const loadingApps = this.state.loadingApps;
        if(loading) {
            //add app to the object if its toggled and waiting for the backend call to finish
            loadingApps[appId] = true;
        } else {
            // Remove the appid from the object when we receive a response for respective app from bankend.
            delete loadingApps[appId];
        }

        this.setState({
            loadingApps
        })
    }

    //Θ(N) where N is the number of applications 
    //Updates the selection of the app
    updateAppSelection = (app) => {
        this.updateLoadingApps(app.id, true);
        let preferences = deepCloneObject(this.props.preferences)
        const {apps, selectedAppsCount} = toggleAppSelection(preferences.Apps.Apps, app, this.state.selectedAppsCount);
        this.saveSelectedAppsCount(selectedAppsCount);
        preferences.Apps.Apps = apps;
        this.storePreferences(preferences, app.id);
    }

    //Θ(1) makes a call to backend to store preferences
    storePreferences = (preferences, appId) => {
        putData(myPreferences, {
            preferences,
            midas: getUser(this.props.user, this.props.impersonation).midas
        }, true)
        .then(_ => {
            this.props.savePreferences(preferences);
        })
        .catch(err => console.log(err))
        .finally(_ => {
            this.loadPreferences(appId);
        })
    }

    loadPreferences = (appId) => {
        getData(
            myPreferences +
                '/' +
                (getUser(this.props.user, this.props.impersonation).midas),
            true
        )
        .then(preferences => {
            this.props.savePreferences(preferences);
            this.setState({
                selectedAppsCount: getSelectedAppsCount(preferences?.Apps?.Apps || {})
            })
        })
        .catch(err => {
            console.log(err);
        })
        .finally(_ => {
            this.updateLoadingApps(appId, false)
        })
        
    }

    render() {
        const {text} = this.props; // Assuming text is passed as a prop for filtering
        const filtered = this.filterApps(text);
        const {selectedLetter} = this.state;
        const availableLetters = Object.keys(filtered);

        return (
            <Box sx={{pb: 5}}>
                {this.props.isAlphabetBarVisible && (
                    <Box sx={{overflowY: 'auto', mb: 4}}>
                        <AlphabetBar
                            onSelectLetter={this.handleSelectLetter}
                            availableLetters={availableLetters}
                        />
                    </Box>
                )}
                <Box className="myOdu__applicationList">
                    {Object.entries(
                        selectedLetter
                            ? {[selectedLetter]: filtered[selectedLetter] || []}
                            : filtered
                    )
                        .sort()
                        .map(([group, apps]) => {
                            return (
                                <React.Fragment key={group}>
                                    <div className="groupBlock">
                                        <a name={group} />
                                        <Typography
                                            component="h3"
                                            className={"header " + (this.props.showHeart ? 'allApps': '')}
                                        >
                                            {group}
                                        </Typography>
                                        {/* TODO://update and clean up the mobile display part  */}
                                        {apps.length > 0 ? (
                                            apps.map(app => (
                                                <div key={app.name}>
                                                    {' '}
                                                    {<App app={app} showHeart={this.props.showHeart} isSelected = {this.isAppSelected(app)} updateAppSelection = {this.updateAppSelection} loadingApps = {this.state.loadingApps} />}{' '}
                                                </div>
                                            ))
                                        ) : (
                                            <Typography>
                                                No applications to display
                                            </Typography>
                                        )}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        text: state.applicationsReducer.text,
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({
    // saveApplicationsFilter: (text) => dispatch(saveApplicationsFilter(text))
    savePreferences: (preferences) => dispatch(savePreferences(preferences))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsList);
