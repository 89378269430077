import {Typography, Link, Paper} from '@mui/material';
import React, {Component} from 'react';

import {postData, getData, deleteData} from '../../../../../DataAccessLayer';
import {teamsDrive} from '../../../../../DataAccessLayer/services';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../../common/Loader';
import {getCourseMetadata} from '../utils';
import {FormButton} from '../../../../common/Form/FormButton';
import PortalModal from '../../../../common/PortalModal';
import {Container, Row, Col} from 'reactstrap';
import {WithSmallScreen} from '../../../../common/WithSmallScreen';

class Teams extends Component {
    state = {
        isModalOpen: false,
        type: null,
        teamsDrive: {},
        isLoading: false,
        isOpenSharedDrive: false,
        isDeleteModalOpen: false
    };

    selectTeamsType = type => {
        this.setState({type});
    };

    componentDidMount() {
        this.getTeamsDrive();
    }

    handleToggleModal = modal => {
        this.setState({[modal]: !this.state[modal]});
    };

    getTeamsDrive = async () => {
        const {encodedName, crn, termCode} = getCourseMetadata(
            this.props.course
        );

        this.setState({isLoading: true});

        try {
            const data = await getData(
                teamsDrive.get({name: encodedName, crn, termCode}),
                true
            );

            this.setState({teamsDrive: data});
        } catch (err) {
            console.log(err);
            this.setState({teamsDrive: {}});
        } finally {
            this.setState({isLoading: false});
        }
    };

    createTeamsDrive = async type => {
        const {name, crn, termCode} = getCourseMetadata(this.props.course);

        const body = {
            name,
            crn,
            termCode
        };

        try {
            this.setState({isLoading: true});
            await postData(teamsDrive.create(type), body, true);
            toast.success('Microsoft Team site created!');
        } catch (err) {
            if (err.timeout) {
                toast.error(
                    'Pending Creating Microsoft Team site. Please check back in 15 minutes'
                );
            } else {
                toast.error('Could not create Microsoft Team site.');
            }
        } finally {
            this.setState({isLoading: false});
            this.getTeamsDrive();
            this.setState({isOpenSharedDrive: false});
        }
    };

    deleteTeamsDrive = async () => {
        const {encodedName, crn, termCode} = getCourseMetadata(
            this.props.course
        );

        try {
            this.setState({isLoading: true});
            await deleteData(
                teamsDrive.delete({name: encodedName, crn, termCode}),
                true
            );
            toast.success('Microsoft Team site deleted!');
        } catch (err) {
            toast.error('Could not create Microsoft Team site');
        } finally {
            this.setState({isLoading: false});
            this.getTeamsDrive();
            this.setState({isOpenSharedDrive: false});
            this.handleToggleModal('isDeleteModalOpen');
        }
    };

    render() {
        const {
            isDeleteModalOpen,
            type,
            teamsDrive,
            isLoading,
            isOpenSharedDrive
        } = this.state;

        const {course} = this.props;

        const isTeaching = course.isTeaching;

        return (
            <React.Fragment>
                <Container className="p-3">
                    <Loader isOpen={isLoading} />
                    <Typography variant="h3">Microsoft Teams</Typography>

                    <Row
                        className={`justify-content-center align-items-center ${
                            !teamsDrive.weburl ? 'flex-column' : 'flex-row'
                        }`}
                    >
                        {!isOpenSharedDrive && (
                            <Col
                                xs="12"
                                sm="6"
                                md="6"
                                lg="3"
                                className={`d-flex ${
                                    !teamsDrive.weburl
                                        ? 'justify-content-center'
                                        : 'justify-content-end'
                                } align-items-center`}
                                style={{height: 200, width: 200}}
                            >
                                <img
                                    src={
                                        !teamsDrive.weburl
                                            ? '/img/cct_teamsSearch.svg'
                                            : '/img/cct_teams.svg'
                                    }
                                    alt="Microsoft Teams"
                                    style={{width: '100%', height: 'auto'}}
                                />
                            </Col>
                        )}

                        {!teamsDrive.weburl ? (
                            !isOpenSharedDrive && (
                                <Row className="w-100">
                                    <Col className="text-center p-2">
                                        <Typography>
                                            There is no Microsoft Teams site for
                                            this class.{' '}
                                            {isTeaching && (
                                                <Link
                                                    sx={{cursor: 'pointer'}}
                                                    onClick={() =>
                                                        this.setState({
                                                            isOpenSharedDrive: true
                                                        })
                                                    }
                                                >
                                                    Create a Microsoft Team
                                                </Link>
                                            )}
                                        </Typography>
                                    </Col>
                                </Row>
                            )
                        ) : (
                            <Col
                                xs="12"
                                lg="4"
                                className="d-flex flex-column align-items-center"
                            >
                                <FormButton
                                    label="Launch Microsoft Teams"
                                    href={teamsDrive.weburl}
                                    target="_blank"
                                    disabled={!teamsDrive.weburl}
                                    sx={{mb: 2}}
                                />
                                {isTeaching && (
                                    <Link
                                        sx={{cursor: 'pointer'}}
                                        onClick={() =>
                                            this.handleToggleModal(
                                                'isDeleteModalOpen'
                                            )
                                        }
                                    >
                                        Delete Microsoft Teams
                                    </Link>
                                )}
                            </Col>
                        )}

                        {isOpenSharedDrive && (
                            <Row className="w-100 flex-column">
                                <Col style={{padding: 0}}>
                                    <Paper
                                        className={`myOdu__button teamsButton ${
                                            type === 'groups' ? 'selected' : ''
                                        } `}
                                        variant="outlined"
                                        sx={{
                                            margin: 1,
                                            padding: 2
                                        }}
                                        onClick={() =>
                                            this.selectTeamsType('groups')
                                        }
                                    >
                                        <Row>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={2}
                                                xl={2}
                                            >
                                                <Typography variant="h4">
                                                    Basic Team
                                                </Typography>
                                            </Col>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={9}
                                                lg={10}
                                                xl={10}
                                            >
                                                <Typography>
                                                    Offers tools like chat,
                                                    video calls, file sharing,
                                                    channels for organizing
                                                    discussions, and integration
                                                    with Microsoft Office apps.
                                                </Typography>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Col>
                                <Col style={{padding: 0}}>
                                    <Paper
                                        className={`myOdu__button teamsButton ${
                                            type === 'classes' ? 'selected' : ''
                                        } `}
                                        variant="outlined"
                                        sx={{
                                            margin: 1,
                                            padding: 2,

                                            cursor: 'pointer'
                                        }}
                                        onClick={() =>
                                            this.selectTeamsType('classes')
                                        }
                                    >
                                        <Row>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                lg={2}
                                                xl={2}
                                            >
                                                <Typography variant="h4">
                                                    Class Team
                                                </Typography>
                                            </Col>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={9}
                                                lg={10}
                                                xl={10}
                                            >
                                                <Typography>
                                                    Includes features specific
                                                    to education, such as class
                                                    notebook integration,
                                                    collaboration spaces for
                                                    group projects, and
                                                    attendance tracking.
                                                </Typography>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Col>
                            </Row>
                        )}
                    </Row>

                    {isOpenSharedDrive && (
                        <Row className="p-2">
                            <Col
                                xs="12"
                                sm="6"
                                className="d-flex justify-content-center justify-content-sm-start p-1"
                            >
                                <FormButton
                                    className="myOdu__button sharedDriveButton"
                                    label="Back"
                                    onClick={() =>
                                        this.setState({
                                            isOpenSharedDrive: false
                                        })
                                    }
                                />
                            </Col>
                            <Col
                                xs="12"
                                sm="6"
                                className="d-flex justify-content-center justify-content-sm-end p-1"
                            >
                                <FormButton
                                    className="myOdu__button sharedDriveButton"
                                    label="Create Teams Site"
                                    disabled={teamsDrive.weburl || !type}
                                    onClick={() => this.createTeamsDrive(type)}
                                />
                            </Col>
                        </Row>
                    )}
                </Container>
                <PortalModal
                    severity="error"
                    maxWidth="sm"
                    onClose={() => this.handleToggleModal('isDeleteModalOpen')}
                    onPrimaryClick={this.deleteTeamsDrive}
                    onSecondaryClick={() =>
                        this.handleToggleModal('isDeleteModalOpen')
                    }
                    isOpen={isDeleteModalOpen}
                    title="Delete Microsoft Teams"
                    primaryButtonTitle="Yes"
                    secondaryButtonTitle="No, Cancel"
                >
                    <Loader isOpen={isLoading} />
                    <Typography>
                        Are you sure you want to delete this Team?
                    </Typography>
                </PortalModal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course
    };
};

export default connect(mapStateToProps)(WithSmallScreen(Teams));
