import React, {Component} from 'react';
import {IconButton, Typography, Slide, Fade, Zoom, Tooltip} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMessageSmile, faXmark} from '@fortawesome/pro-light-svg-icons';
import PortalModal from '../PortalModal';
import FeedbackForm from './FeedbackForm';
import FeedbackButton from './FeedbackButton';
import {WithSmallScreen} from '../../common/WithSmallScreen';
import {isProduction} from '../../Layout/utils';
class Feedback extends Component {
    state = {

        //boolean for feedback modal
        isExpand: false
    };

    //Θ(1) toggle isExpand boolean
    toggleModalOpen = () => {
        this.setState({isExpand: !this.state.isExpand});
    };



    render() {
        return (<>
            <> 
                <PortalModal
                    title={'New myODU Portal Feedback'}
                    maxWidth="sm"
                    isOpen={this.state.isExpand}
                    onClose={this.toggleModalOpen}
                    isCloseable
                    className="myOdu__feedbackForm"
                    id="feedbackButton__mobile_getFeedback"
                >
                    <FeedbackForm />
                </PortalModal>
                <FeedbackButton 
                    toggleModalOpen = {this.toggleModalOpen}
                    isSmall = {this.props.isSmall}
                />
            </>
        </>
        );
    }
}

export default WithSmallScreen(Feedback);
