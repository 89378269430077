import React, {Component} from "react";
import { Box, Typography, Stack } from "@mui/material";
import { Col, Row } from "reactstrap";

class CreditDetails extends Component {
    state = {

    }

    render() {
        let creditDetailId =  this.props.text.charAt(0).toLowerCase()+this.props.text.slice(1).replace(/\s/g, '');
        return <React.Fragment>
            <Stack direction="row" spacing={1}>
                <Typography className="text-end" sx={{width: '4rem'}} id={"creditDetails__number_numberOfCredits_"+ creditDetailId } component="h5" variant='h4'>{this.props.data}</Typography>            
                <Typography id={"creditDetails__text_"+creditDetailId} component="p" variant ="small">{this.props.text}</Typography>
            </Stack>            
        </React.Fragment>
    }
}

export default CreditDetails;