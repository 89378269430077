import React, {Component} from 'react';
import { connect } from 'react-redux';

import { cctCourse, cctTaking, cctTeaching, cctTerms } from './cctData';
import Body from '../MyCourses/CCT/Body';
import { toggleCCT } from '../MyCourses/CCT/actions';
import { saveMyCoursesTaking, saveMyCoursesTeaching } from '../MyCourses/actions';
import { saveTerms } from '../MyCourses/Terms/actions';

class CCT extends Component {

    state = {

    }

    loadCCTReducer = (course) => {
        this.props.toggleCCT(course);
        this.props.saveMyCoursesTaking(cctTaking);
        this.props.saveMyCoursesTeaching(cctTeaching);
        this.props.saveTerms(cctTerms);
    }

    render() {
        this.loadCCTReducer(cctCourse);
        return <React.Fragment>
            <Body />
        </React.Fragment>
    }
}

const mapStateToProps = state => {
    return {
        isCCT: state.CCTReducer.isCCT,
        course: state.CCTReducer.course
    };
};

const mapDispatchToProps = dispatch => ({
    toggleCCT: course => dispatch(toggleCCT(course)),
    saveMyCoursesTaking: coursesTaking =>
        dispatch(saveMyCoursesTaking(coursesTaking)),
    saveMyCoursesTeaching: coursesTeaching =>
        dispatch(saveMyCoursesTeaching(coursesTeaching)),
    saveTerms: terms => dispatch(saveTerms(terms)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CCT);
