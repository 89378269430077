import { faLink, faShareNodes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import React, {Component} from "react";
import PortalTooltip from "../../../../../common/PortalTooltip";

class MeetingTitle extends Component {
    state = {

    }

    //Θ(1) Copies the text to clipboard
    onCopyText = (text) => {
        navigator.clipboard.writeText(text);
        this.props.openSnackbar('Link Copied', true)
    }

    render() {
        return <React.Fragment>
            <div>
                <Stack direction='row' alignItems='center' gap={1}>
                    {
                        <PortalTooltip id={this.props.row.topic + '_shared_meeting'} title="Shared meeting">
                            <FontAwesomeIcon icon={faShareNodes}  visibility={!(this.props.row.isShared || this.props.row.shareWith) && 'hidden'} />
                        </PortalTooltip>
                    }
                    {this.props.getShortTopic(this.props.row)}
                    {
                        this.props.row.start_url &&
                        <PortalTooltip id={this.props.row.topic + '_copy_meeting_link'} title="Copy Meeting Link">
                            <IconButton
                                aria-label="copy"
                                onClick={() => this.onCopyText(this.props.row.join_url)}
                                size="small"
                            >
                                <FontAwesomeIcon icon={faLink} size="sm" />
                            </IconButton>
                        </PortalTooltip>
                    }
                </Stack>
            </div>
        </React.Fragment>
    }
}

export default MeetingTitle;