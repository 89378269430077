import React, {Component} from 'react';
import {
    List,
    ListItem,
    Stack,
    Box,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFaceWorried,
    faFaceFrownSlight,
    faFaceMeh,
    faFaceSmile,
    faFaceSmileHearts
} from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from './PortalTooltip';

class LikertFaceScale extends Component {
    state = {};

    getFaces = stepNum => {
        var faces = [
            <FontAwesomeIcon id="likert__icon_awful" icon={faFaceWorried} fixedWidth size="xl" />,
            <FontAwesomeIcon id="likert__icon_dislike" icon={faFaceFrownSlight} fixedWidth size="xl" />,
            <FontAwesomeIcon id="likert__icon_mid" icon={faFaceMeh} fixedWidth size="xl" />,
            <FontAwesomeIcon id="likert__icon_like" icon={faFaceSmile} fixedWidth size="xl" />,
            <FontAwesomeIcon id="likert__icon_love" icon={faFaceSmileHearts} fixedWidth size="xl" />
        ];
        return faces[stepNum];
    };

    getStepTitle = stepNum => {
        var title = [
            "It's awful.",
            "I don't like it.",
            "It's mid.",
            'I like it.',
            'I love it!'
        ];
        return title[stepNum];
    };

    onClick = stepNum => {
        this.props.onChange(stepNum);
    };

    makeStep = stepNum => {
        return (
            <>
                <Stack
                    justifyContent={'center'}
                    alignContent={'center'}
                    alignItems={'flex-start'}
                    id="likert__buttonWrapper"
                >
                    <div className="text-center" style={{width: '100%'}}>
                        <IconButton
                            className={`myOdu__likertFaceButton step_${stepNum} ${
                                this.props.rating === stepNum
                                    ? ' selected '
                                    : ''
                            }`}
                            onClick={() => this.onClick(stepNum)}
                            id={"likert__iconButton_" + stepNum}
                        >
                            <PortalTooltip title={this.getStepTitle(stepNum)} id={"likert__iconButton_tooltip_" + stepNum }>
                                {this.getFaces(stepNum)}
                            </PortalTooltip>
                        </IconButton>
                    </div>
                    <Typography
                        variant="smallest"
                        className="text-center myOdu__likertFaceDescription"
                        id={"likertButton__stepText_" + stepNum}
                    >
                        {this.getStepTitle(stepNum)}
                    </Typography>
                </Stack>
            </>
        );
    };

    render() {
        return (
            <>
                <Stack
                    sx={{width: '100%', mb: 2}}
                    direction="row"
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    className="text-center"
                >
                    {this.makeStep(0)}
                    {this.makeStep(1)}
                    {this.makeStep(2)}
                    {this.makeStep(3)}
                    {this.makeStep(4)}
                </Stack>
            </>
        );
    }
}

export default LikertFaceScale;
