export const TOGGLE_IMPERSONATION = 'TOGGLE_IMPERSONATION';
export const toggleImpersonation = () => ({
    type: TOGGLE_IMPERSONATION,
    payload: {}
});

export const SAVE_IMPERSONATION = 'SAVE_IMPERSONATION';
export const saveImpersonation = (impersonation) => ({
    type: SAVE_IMPERSONATION,
    payload: {impersonation}
});