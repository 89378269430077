import React, { Component } from 'react';
import {SnackbarProvider, closeSnackbar  } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@mui/material';

class PortalSnackbarProvider extends Component {
    state = {  } 
    render() { 
        return <React.Fragment>
            <SnackbarProvider 
                maxSnack={3}
                autoHideDuration={5000}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                action={(snackbarId) => (
                    <IconButton size='small' onClick={() => closeSnackbar(snackbarId)}>
                        <FontAwesomeIcon icon={faX} color='white'/>
                    </IconButton>
                )}
            />
        </React.Fragment>
    }
}
 
export default PortalSnackbarProvider;