import React, {Component} from 'react';
import {
    Stack,
    Grid,
    Box,
    Button,
    InputAdornment,
    Typography,
    Autocomplete,
    TextField,
    Card,
    CardActionArea,
    IconButton
} from '@mui/material';
import {AppConfigs} from '../SetUp/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-light-svg-icons';

const ariaLabel = {'aria-label': 'description'};
class SearchAppsAutocomplete extends Component {
    state = {
        searchText: '',
    };

    onTextChange = (e, text) => {
        this.setState({searchText: text});
    };

    onAppChange = (e, app) => {
        this.props.saveApplicationsFilter(app ?? '');
    };

    // toggleFocus = () =>{
    //     this.setState({onFocus: !this.state.onFocus});
    // };

    render() {
        const options = AppConfigs.Apps.map(app => ({...app, label: app.name}));
        const imgPath = "/img/logos/"
        return (
            <React.Fragment>
                <Autocomplete
                    className={
                        "myOdu__hideFloatLabel myOdu__searchAppsAutocomplete " + 
                        (this.props.isTopNav ? "topNav " : "") + " " + 
                        (this.props.isMobile ? "mobile " : "") + " " + 
                        (this.props.isSearchOnFocus ? " onFocus" : "")
                    }
                    onBlur={this.props.toggleFocus}
                    onFocus={this.props.toggleFocus}
                    options={options}
                    forcePopupIcon={false}
                    inputValue={this.props.searchText}
                    onInputChange={this.onTextChange}
                    value={this.props.text}
                    onChange={this.onAppChange}
                    open={this.props.isSearchOnFocus && this.state.searchText}
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            label="Search"
                            placeholder="Search for applications"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment className="cursor-pointer" position="end">
                                    {/* <InputAdornment className="cursor-pointer" position="end" onClick={this.props.toggleOpen}> */}
                                        {/* <FontAwesomeIcon icon={this.props.isTopNav ? faXmark : faMagnifyingGlass} /> */}
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    renderOption={(props, option, state) => (
                        <Card sx={{borderRadius: '0', width: '100%'}}>
                            <CardActionArea sx={{p: 1}} alignItems="center" href={option.link} >
                                <Stack direction="row">
                                    <Box sx={{ height: '40px', width: '40px', backgroundColor: 'white', m: 1 }} className="myOdu__logoZoom" >
                                        <img src={ imgPath + option.icon } alt={option.name} />
                                    </Box>
                                    <Typography sx={{mt: 2}}>
                                        {option.name}
                                    </Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    )}
                />
            </React.Fragment>
        );
    }
}

// const mapStateToProps = (state) => {
//     return {
//         text: state.applicationsReducer.text
//     }
//   }

// const mapDispatchToProps = (dispatch) => ({
//     saveApplicationsFilter: (text) => dispatch(saveApplicationsFilter(text))
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Head);

export default SearchAppsAutocomplete;
