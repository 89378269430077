export const SAVE_MY_COURSES_TAKING = 'SAVE_MY_COURSES_TAKING';
export const SAVE_MY_COURSES_TEACHING = 'SAVE_MY_COURSES_TEACHING';

export const saveMyCoursesTaking = (coursesTaking) => ({
    type: SAVE_MY_COURSES_TAKING,
    payload: {coursesTaking}
});

export const saveMyCoursesTeaching = (coursesTeaching) => ({
    type: SAVE_MY_COURSES_TEACHING,
    payload: {coursesTeaching}
});