const { useTheme } = require("@emotion/react");
const { useMediaQuery } = require("@mui/material");

export const WithSmallScreen = Component => props => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    let size = 'xs';

    const isUpXXL = useMediaQuery(theme.breakpoints.up('xxl'));
    size = isUpXXL && '^xxl' || size;
    const isXXL = useMediaQuery(theme.breakpoints.only('xxl'));
    size = isXXL && 'xxl' || size;
    const isXL = useMediaQuery(theme.breakpoints.only('xl'));
    size = isXL && 'xl' || size;
    const isLG = useMediaQuery(theme.breakpoints.only('lg'));
    size = isLG && 'lg' || size;
    const isMD = useMediaQuery(theme.breakpoints.only('md'));
    size = isMD && 'md' || size;
    const isSM = useMediaQuery(theme.breakpoints.only('sm'));
    size = isSM && 'sm' || size;
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    // size = isXS && 'xs' || size;
    return (<Component {...props} isSmall = {isSmall}
            isXXL = {isXXL}
            isXL = {isXL}
            isLG = {isLG}
            isMD = {isMD}
            isSM = {isSM}
            isXS = {isXS}
            screenSize = {size}
    /> );
}