import React, {Component} from 'react';
import {
    Badge,
    Box,
    Link,
    List,
    ListItem,
    Stack,
    Typography,
    Skeleton
} from '@mui/material';
import Alert from './Alert';
import {ALERT_KEY_FINANCIAL_AID} from '../common/constants';

class AlertCategory extends Component {
    state = {};

    render() {
        return (
            <div id={this.props.id+"__div_contentWrapper"}>
                <Box id={this.props.id+"__box_wrapper"} sx={{mb: 2}}>
                    <Stack id={this.props.id+"__stack_wrapper"} direction="row" sx={{mb: 0}} alignItems="center">
                        <Typography
                            id={this.props.id+"__text_title"}
                            component="h3"
                        >
                            {this.props.title}
                        </Typography>

                        {this.props.data.length > 0 && !this.props.isError && (
                            <Badge
                                id={this.props.id+"__badge"}
                                badgeContent={this.props.data.length}
                                max={10}
                                className="myOdu__badge alertBadge"
                                sx={{ml: 1, mr: 2}}
                                style={{marginTop: '-1rem'}}
                            />
                        )}
                        {this.props.alertKey === ALERT_KEY_FINANCIAL_AID &&
                            this.props.hiddenFinancialAidLength > 0 && (
                                <Link
                                    variant="smallest"
                                    component="button"
                                    onClick={
                                        this.props.toggleIsShowHiddenAlerts
                                    }
                                    underline="always"
                                    color="inherit"
                                    sx={{ml: 1, cursor: 'pointer'}}
                                    id={this.props.id+"__link_alertHidden"}
                                >
                                    {this.props.hiddenFinancialAidLength +
                                        ' Alert' +
                                        (this.props.hiddenFinancialAidLength > 1
                                            ? 's'
                                            : '') +
                                        ' Hidden'}
                                </Link>
                            )}
                    </Stack>

                    <Box id={this.props.id+"__box_cardWrapper"} className="" sx={{m: 0, p:0}}>
                        <List id={this.props.id+"__list_wrapper"} sx={{p: 0}}>
                            {this.props.isLoading && (
                                <Skeleton id={this.props.id+"__skeleton_preloading"} variant="rectangular" />
                            )}
                            {!this.props.isLoading && this.props.isError && (
                                <Typography id={this.props.id+"__text_noInfoAvailable"} component="p">
                                    Information not available.
                                </Typography>
                            )}
                            {!this.props.isLoading &&
                                !this.props.isError &&
                                this.props.data.length > 0 &&
                                this.props.data.map((item, index) => {
                                    return (
                                        <ListItem
                                            id={this.props.id+"__listItem_"+index}
                                            sx={{pr: 0, pl: 0, pt:0}}
                                            key={index}
                                            divider
                                            className="myOdu__statusAlert"
                                        >
                                            <Alert
                                                id={this.props.id+"__alert_"+index}
                                                item={item}
                                                index={index}
                                                alertKey={this.props.alertKey}
                                                action={this.props.action}
                                                toggleAlertVisibility={
                                                    this.props
                                                        .toggleAlertVisibility
                                                }
                                            />
                                        </ListItem>
                                    );
                                })}
                        </List>
                        {!this.props.isLoading &&
                            !this.props.isError &&
                            this.props.data.length === 0 && (
                                <Typography id={this.props.id+"_no"} component="p">
                                    {'No ' + this.props.title + (this.props.alertKey === ALERT_KEY_FINANCIAL_AID ? ' alerts.': ".")}
                                </Typography>
                            )}
                    </Box>
                </Box>
            </div>
        );
    }
}

export default AlertCategory;
