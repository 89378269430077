import { SAVE_IMPERSONATION, TOGGLE_IMPERSONATION } from "./actions";

const initialStateImpersonation = {
    isImpersonation: false,
    impersonation: {}
}
//needs to persist across pages
export const impersonationReducer = (state = initialStateImpersonation, action) => {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_IMPERSONATION: {
            return {...state, isImpersonation: !state.isImpersonation}
        }
        case SAVE_IMPERSONATION: {
            return {...state, impersonation: payload.impersonation}
        }
        default:
            return state;
    }
}