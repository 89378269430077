import React from 'react';

import PortalTooltip from '../../common/PortalTooltip';

export const FieldRequiredMark = ({label}) => {
    return (
        <PortalTooltip title={`${label} is required`}>
            <span style={{color: '#C23335', cursor: 'pointer'}}>*</span>
        </PortalTooltip>
    );
};
