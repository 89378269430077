import React, { Component } from 'react';
import Widget from '../common/Widget';
import { Link, List, ListItem, Typography, Box, Button, Stack, IconButton, Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import PortalTooltip from '../common/PortalTooltip';

class BalanceBox extends Component {
    render() {
        return <Box id={this.props.id + "__box_balanceCard"} sx={{ height: '100%' }} textAlign={"center"} className="balanceBox">
                <Stack id={this.props.id + "_stack_balanceCard_reducer"} sx={{ p: 1, height: '100%' }} direction={"column"} alignItems="stretch" justifyContent={"center"}>
                    <Stack id={this.props.id + "_stack_balance_reducer"} direction={"row"} alignItems="flex-start" justifyContent="center">
                        <Box id={this.props.id + "_boxImage"} sx={{ height: '40px', width: '40px', mr: 0 }}>
                            <img id={this.props.id + "_Image"} src={this.props.icon} alt={this.props.alt} />
                        </Box>

                        <Stack id={this.props.id +"_stack_balance_wrapper"} direction={"column"} alignItems={"top"} spacing={0} sx={{ width: '100%' }}>
                            <div id={this.props.id +"_div_balance_wrapper"} className="ms-2 text-start">
                                <Typography id={this.props.id + "_text_balanceMainTitle"} component="h3">{this.props.title}</Typography>
                                {this.props.balance == "N/A" ?
                                    <Typography id={this.props.id + "_text_noAccountFound"} component="p">
                                        No account found.
                                    </Typography>
                                    :
                                    <div id={this.props.id + "_div_balanceInfo_wrapper"} className="">
                                            <Stack id={this.props.id + "_stack_balanceInfo_wrapper"} direction="row" alignItems="center" justifyContent="left">
                                                <Typography id={this.props.id + "_text_balanceTitle"} component="h4" className="text-start">Balance</Typography>

                                                <PortalTooltip title={this.props.isDisplayBalance ? "Hide Balance" : "Balance Hidden, Show Balance"}>
                                                    <IconButton className="" id={this.props.id + "_iconButton_toggleBalanceIcon"} aria-label="show balance" onClick={this.props.toggleIsDisplayBalance}>
                                                        <FontAwesomeIcon id={`${this.props.id}_toggleBalanceIcon_${this.props.isDisplayBalance ? 'eye' : 'eyeSlash'}`} icon={this.props.isDisplayBalance ? faEye : faEyeSlash} size="xs"/>
                                                    </IconButton>
                                                </PortalTooltip>
                                            </Stack>                                   
                                        

                                            {this.props.isDisplayBalance &&
                                                <Chip 
                                                    label={"$" + this.props.balance}
                                                    id={this.props.id + "_text_balanceDisplay"} 
                                                />
                                            }
                                    </div>
                                }
                            </div>
                        </Stack>
                    </Stack>
                    <Stack id={this.props.id + "_stack"} sx={{ height: '100%' }} justifyContent="flex-end" alignItems='center' className="align-bottom" >
                        <PortalTooltip id={this.props.id + "_tooltip"}>
                            <Button id={this.props.id + "_button"} target="_blank" className="myOdu__button secondary myBalancesButton" href={this.props.depositLink} variant="outlined" sx={{ mt: 1, width: '100%' }}>{this.props.buttonLabel}</Button>
                        </PortalTooltip>
                    </Stack>
                </Stack>
                {/* <Box sx={{display:'flex', alignItems: 'stretch', border: '1px solid green'}} /> */}

            </Box>
       
    }
}

export default BalanceBox;