import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
import {
	DialogTitle,
	DialogContent,
	Dialog,
	DialogActions,
	Typography,
	Button,
	Box,
	IconButton,
	Stack} 
	from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faXmark, faThumbsUp } from "@fortawesome/pro-light-svg-icons";

class PortalModal extends Component {

	getIcon = () =>{
		return (this.props.severity == "error")? faExclamationCircle : faThumbsUp;		
	}
	
	render () {
		return (<React.Fragment>
			<Dialog
				open={this.props.isOpen}
				fullWidth
				fullScreen={this.props?.isFullScreen}
				maxWidth={this.props?.maxWidth ?? "xl"}
				className=
					{"myOdu__modal " + 
						(this.props?.severity ? this.props.severity + " ": "") + 
						(this.props?.className ? this.props.className + " ": "") + 
						(this.props.noHeader ? "noHeader": "")
					}
				id={this.props.id}
				onClose={this.props.isCloseable ? this.props.onClose : ''}
				// hideBackdrop={this.props.hideBackdrop}
			>

				<DialogTitle>
					<Stack direction="row" sx={{justifyContent: !this.props.severity ? "space-between" : "" }}>
					
						{!this.props.noHeader && <>
							{this.props.severity  && 
								<FontAwesomeIcon icon={this.getIcon()} size="2x" style={{marginRight:'0.5rem'}} />
							}
							
							{this.props?.title && 
							<Stack>
								<Typography variant="h5" component="h2">
									{this.props.title}
									
								</Typography>
						
								{this.props?.subTitle && (
									<Typography component="p" variant="small">
										{this.props.subTitle}
									</Typography>
								)}
							</Stack>
							}
						</>}

						{this.props.noHeader && <>
							<Box sx={{ display: 'flex'}}/> 
						</>}

					{this.props.isCloseable && 
						<IconButton
							aria-label="close"
							onClick={this.props.onClose}
							className="myOdu__closeX myOdu__iconButton"
							id={this.props.id + "__button_close"} >
							<FontAwesomeIcon icon={faXmark} fixedWidth size="xs" />
						</IconButton>
					}
					</Stack>
				</DialogTitle>
				
				<DialogContent contentClassName={this.props.contentClassName} style={this.props.style}>{this.props.children}</DialogContent>

				{/* //need primary button before you can have secondary button */}
				{this.props.primaryButtonTitle && 
				<DialogActions style={{padding:'1rem', paddingTop: 0}}>
					<Stack sx={{width: '100%'}} direction={{xs:'col', sm:'row'}} spacing={1} alignItems={'center'} justifyContent={"end"}>
							{this.props.primaryButtonTitle && (
								<Button variant="outlined" 
									className="myOdu__button primary modalActionButton"
									onClick={this.props.onPrimaryClick} 
									disabled={this.props?.isPrimaryButtonDisabled ? true : false }
									id={this.props.id + "__button_primary"}
								>
									{this.props.primaryButtonTitle}
								</Button>
							)}
						
							{this.props.secondaryButtonTitle && (
								<Button variant="outlined" 
									onClick={this.props.onSecondaryClick} 
									className="myOdu__button secondary modalActionButton"
									disabled={this.props?.isSecondaryButtonDisabled ? true : false }
									id={this.props.id + "__button_secondary"}
								>
									{this.props.secondaryButtonTitle}
								</Button>
							)}								
					</Stack>
				</DialogActions>}
			</Dialog>
		</React.Fragment>);
	}
}
export default PortalModal;
