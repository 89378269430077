import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link, List} from '@mui/material';
import {GridActionsCellItem} from '@mui/x-data-grid';
import {faLocationDot} from '@fortawesome/pro-solid-svg-icons';
import { cleanUpID } from '../../common/utils';

const getColumns = actionFunction => {
    return [
        {
            field: 'SUBJECT',
            valueGetter: params => params.row.SUBJECT,
            headerName: 'Subject',
            width: 80
        },
        {
            field: 'COURSE_NUMBER',
            valueGetter: params => params.row.COURSE_NUMBER,
            headerName: '#',
            width: 40
        },
        {
            field: 'COURSE_TITLE',
            valueGetter: params => params.row.COURSE_TITLE,
            headerName: 'Title',
            flex: 3
        },
        {
            field: 'locations',
            headerName: 'Tutoring Info/Location',
            minWidth: 150,
            flex: 2,
            renderCell: params => {
                return (
                    //TODO: if this is a list then the items inside need to be list items
                    <List id={"tutoring__list_infoWrapper_" + cleanUpID(params.row.COURSE_TITLE) + '_' + params.row.SUBJECT + "_" + params.row.COURSE_NUMBER }>
                        {params.row.locations.map((location, index) => (
                            <span id={`tutoring__span_infoWrapper_` + cleanUpID(params.row.COURSE_TITLE) + '_' + params.row.SUBJECT + "_" + params.row.COURSE_NUMBER+'_'+ cleanUpID(location.text)} key={location.text}>
                                <span id={`tutoring__span_` + cleanUpID(params.row.COURSE_TITLE)+ '_'+ params.row.SUBJECT + "_" + params.row.COURSE_NUMBER+'_'+ cleanUpID(location.text)}>{index !== 0 ? ', ' : ''}</span>
                                <Link
                                    id={`tutoring__location_url_` + cleanUpID(params.row.COURSE_TITLE)+ '_' + params.row.SUBJECT + '_' + params.row.COURSE_NUMBER+'_'+ cleanUpID(location.text)}
                                    mr={1}
                                    target="_blank"
                                    href={location.url}
                                >
                                    {location.text}
                                </Link>
                                {location.mapUrl && (
                                    <Link
                                        id={`tutoring__link_map_` + cleanUpID(params.row.COURSE_TITLE)+ '_' + params.row.SUBJECT + "_" + params.row.COURSE_NUMBER}
                                        target="_blank"
                                        href={location.mapUrl}
                                    >
                                        <FontAwesomeIcon
                                            id={`tutoring__icon_map_` + cleanUpID(params.row.COURSE_TITLE)+ '_' + params.row.SUBJECT + "_" + params.row.COURSE_NUMBER}
                                            fixedWidth
                                            size="lg"
                                            icon={faLocationDot}
                                        />
                                    </Link>
                                )}
                            </span>
                        ))}
                    </List>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 80,
            cellClassName: 'actions',
            type: 'actions',
            getActions: event => [
                <GridActionsCellItem
                    id={`tutoring__gridActionsCellItem_delete_` + event.row.SUBJECT + event.row.COURSE_NUMBER}
                    icon={<FontAwesomeIcon id={`tutoring__icon_delete_` + event.row.SUBJECT + event.row.COURSE_NUMBER} icon={faTrash} />}
                    label="Delete"
                    onClick={() => actionFunction(event.row)}
                />
            ]
        },
        {field: 'createdAt', valueGetter: params => params.row.createdAt}
    ];
};

export default getColumns;
