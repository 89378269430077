import React, { Component } from 'react';
import { IconButton, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from '../../../common/PortalTooltip';


class MobileNavToggler extends Component {
    render() { 
        return <Box className={"d-block d-sm-none myOdu__mobileMenuToggleButton " + (this.props.isMenuOpen? "open ": "mx-1")} style={{backgroundColor:"#003057"}}>
                <PortalTooltip title={"Expand Menu"}>
                    <IconButton className={"myOdu__iconButton"} onClick={this.props.onToggle} sx={{float: 'right'}}>
                        <FontAwesomeIcon style={{color: "white"}} icon={this.props.isMenuOpen ? faXmark : faBars} sx={{mr: 1}}/>
                    </IconButton>                
                </PortalTooltip>
            </Box>
       
    }
}
 
export default MobileNavToggler;


