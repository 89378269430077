export const getCourseMetadata = course => {
    const {
        CRNS,
        SSBSECT_TERM_CODE,
        COURSE_NUMBER,
        COURSE_TITLE,
        SUBJECT_CODE,
        SUBJECT_5,
        COURSE_NUMBER_5,
        COURSE_TITLE_5,
        COURSE_REFERENCE_NUMBER_5
    } = course;

    const crn = (CRNS || COURSE_REFERENCE_NUMBER_5).split(',')[0];
    const termCode = SSBSECT_TERM_CODE;
    const subjectCode = SUBJECT_CODE || SUBJECT_5;
    const courseNumber = COURSE_NUMBER || COURSE_NUMBER_5;
    const courseTitle = COURSE_TITLE || COURSE_TITLE_5;

    const name =
        `${termCode}_${subjectCode}_${courseNumber}_${courseTitle.toUpperCase()}`.replaceAll(
            /[@()\\[\]"`;:.<> ,]/g,
            ''
        );

    const encodedName = encodeURIComponent(name);

    return {
        name,
        encodedName,
        crn,
        termCode,
        subjectCode,
        courseNumber,
        courseTitle
    };
};

/**
 * Returns true if the given student courses is in teaching courses.
 */
export const verifyFullPermission = (studentCourse, teachingCourses) => {
    const isTeaching = teachingCourses.find(
        ({
            CRNS: crns,
            COURSE_REFERENCE_NUMBER_5: crn5,
            SSBSECT_TERM_CODE: termCode
        }) =>
            (studentCourse.CRNS || studentCourse.COURSE_REFERENCE_NUMBER_5) ===
                (crns || crn5) && studentCourse.SSBSECT_TERM_CODE === termCode
    );

    if (isTeaching) {
        return true;
    }

    return false;
};
