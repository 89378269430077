import { faX } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "reactstrap";
import PortalModal from "./PortalModal";


class PortalDelete extends React.Component {
    state = {

    };

    render() {
        return <React.Fragment>
            <PortalModal
                maxWidth="xs"
                onClose={this.props.onToggle}
                isOpen={true}
                primaryButtonTitle="yes, delete"
                onPrimaryClick={this.props.onDelete}
                isPrimaryButtonDisabled={this.props.isLoading}
                secondaryButtonTitle="no, cancel"
                onSecondaryClick={this.props.onToggle}
                isCloseable={true}
                title={this.props.title}
            >
                <Typography>{this.props.message}</Typography>
            </PortalModal>
        </React.Fragment>
    }
}

export default PortalDelete;