import React, {Component} from 'react';
import {Typography, Box, Paper, Stack} from '@mui/material';

import LikertFaceScale from '../LikertFaceScale';
import {FormCheckbox} from '../Form/FormCheckbox';
import {FormTextField} from '../Form/FormTextField';
import {FormTextArea} from '../Form/FormTextArea';
import {FormButton} from '../Form/FormButton';
import {feedback as feedbackService} from '../../../DataAccessLayer/services';
import {postData} from '../../../DataAccessLayer';
import {toast} from 'react-toastify';
import {getBrowserMetadata} from '../../../DataAccessLayer/util';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFaceSmileRelaxed} from '@fortawesome/pro-light-svg-icons';

class FeedbackForm extends Component {
    state = {
        rating: null,
        bugChecked: false,
        ideaChecked: false,
        othersChecked: false,
        pageUrl: '',
        bugDetail: '',
        ideaDetail: '',
        otherDetail: '',
        changeDetail: '',
        isSubmitted: false
    };

    handleBugChecked = () => {
        this.setState({bugChecked: !this.state.bugChecked});
    };

    handleIdeaChecked = () => {
        this.setState({ideaChecked: !this.state.ideaChecked});
    };

    handleOtherChecked = () => {
        this.setState({othersChecked: !this.state.othersChecked});
    };

    submitFeedback = async () => {
        const {
            rating,
            pageUrl,
            bugDetail,
            ideaDetail,
            otherDetail,
            changeDetail
        } = this.state;

        const metadata = await getBrowserMetadata();

        const body = {
            rating,
            pageUrl,
            bugDetail,
            ideaDetail,
            otherDetail,
            changeDetail,
            metadata
        };

        postData(feedbackService.feedback, body)
            .then(result => {
                this.setState({isSubmitted: true});
            })
            .catch(err => toast.error('Error submitting feedback'));
    };

    render() {
        const {
            rating,
            bugChecked,
            ideaChecked,
            othersChecked,
            pageUrl,
            bugDetail,
            ideaDetail,
            otherDetail,
            changeDetail,
            isSubmitted
        } = this.state;
        return (
            <Box sx={{p: 0}} id="feedbackForm__wrapper">
                {isSubmitted ? (
                    <Stack
                        alignItems={'center'}
                        id="feedbackForm__wrapper_header_thanks"
                    >
                        <FontAwesomeIcon
                            icon={faFaceSmileRelaxed}
                            fixedWidth
                            size="4x"
                        />
                        <Typography
                            component="h3"
                            className="myOdu__feedback text-center"
                            id="feedbackForm__header_thanks"
                        >
                            Thanks for the Feedback!
                        </Typography>
                        <Typography
                            compoent="p"
                            className="myOdu__feedback"
                            sx={{mb: 2}}
                            id="feedback__paragraphText_thanks"
                        >
                            We're always striving to improve, and your input
                            helps us do just that. So, thanks again for being
                            awesome and sharing your thoughts with us!
                        </Typography>
                    </Stack>
                ) : (
                    <>
                        <Stack alignItems={'center'}>
                            <Typography
                                component={'label'}
                                className="myOdu__label"
                                id="feedback__label_whatDoYouLike"
                            >
                                How do you like the new myODU portal design?
                            </Typography>

                            <LikertFaceScale
                                isTextAreaDisplayed
                                rating={rating}
                                onChange={e => this.setState({rating: e})}
                            />
                        </Stack>
                        {[0, 1].includes(rating) && (
                            <>
                                <Typography
                                    component="label"
                                    className="myOdu__label"
                                    htmlFor="feedback_changeDetail"
                                    id="feedback__label_changeDetail"
                                >
                                    What would you change?
                                </Typography>
                                <FormTextArea
                                    id="feedback_changeDetail"
                                    value={changeDetail}
                                    onChange={e =>
                                        this.setState({changeDetail: e})
                                    }
                                    maxChars={500}
                                    inputGridProps={{xs: 12}}
                                />
                            </>
                        )}

                        <FormCheckbox
                            value={bugChecked}
                            label={'I found a bug'}
                            onChange={this.handleBugChecked}
                            labelGridProps={{xs: 0}}
                            id="feedback__checkbox_foundBug"
                        />
                        {bugChecked && (
                            <>
                                <Typography
                                    component="label"
                                    className="myOdu__label"
                                    htmlFor="feedback_pageUrl"
                                    id="feedback__label_pageUrl"
                                >
                                    Which Page?
                                </Typography>
                                <FormTextField
                                    id="feedback_pageUrl"
                                    value={pageUrl}
                                    onChange={e => this.setState({pageUrl: e})}
                                    inputGridProps={{xs: 12}}
                                />
                                <Typography
                                    component="label"
                                    className="myOdu__label"
                                    htmlFor="feedback_bugDetail"
                                    sx={{mt: 2}}
                                    id="feedback__label_bugDetail"
                                >
                                    Tell Us About the Bug
                                </Typography>
                                <FormTextArea
                                    id="feedback_bugDetail"
                                    value={bugDetail}
                                    onChange={e =>
                                        this.setState({bugDetail: e})
                                    }
                                    maxChars={500}
                                    inputGridProps={{xs: 12}}
                                />
                            </>
                        )}
                        <FormCheckbox
                            value={ideaChecked}
                            label={'I have an idea for something new'}
                            onChange={this.handleIdeaChecked}
                            labelGridProps={{xs: 0}}
                            id="feedback__checkbox_idea"
                        />
                        {ideaChecked && (
                            <>
                                <Typography
                                    component="label"
                                    className="myOdu__label"
                                    htmlFor="feedback_ideaDetail"
                                    id="feedback__label_ideaDetail"
                                >
                                    Tell Us About Your Idea
                                </Typography>
                                <FormTextArea
                                    id="feedback_ideaDetail"
                                    value={ideaDetail}
                                    onChange={e =>
                                        this.setState({ideaDetail: e})
                                    }
                                    maxChars={500}
                                    inputGridProps={{xs: 12}}
                                />
                            </>
                        )}
                        <FormCheckbox
                            value={othersChecked}
                            label={'I have something else to say'}
                            onChange={this.handleOtherChecked}
                            labelGridProps={{xs: 0}}
                            id="feedback__checkbox_somethingElse"
                        />
                        {othersChecked && (
                            <>
                                <Typography
                                    component="label"
                                    className="myOdu__label"
                                    htmlFor="feedback_otherDetail"
                                    id="feedback__label__somethingElse"
                                >
                                    What else would you like to say?
                                </Typography>
                                <FormTextArea
                                    id="feedback_somethingElse"
                                    value={otherDetail}
                                    onChange={e =>
                                        this.setState({otherDetail: e})
                                    }
                                    maxChars={500}
                                    inputGridProps={{xs: 12}}
                                />
                            </>
                        )}
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            id="feedback__wrapper_submitButton"
                        >
                            <FormButton
                                sx={{mt: 2}}
                                label="Submit Feedback"
                                onClick={this.submitFeedback}
                                id="feedback__button_submitFeedback"
                            />
                        </Box>
                    </>
                )}
            </Box>
        );
    }
}

export default FeedbackForm;
