import { Box, Button, Grid, Stack, Switch, Tab, Tabs, Typography } from '@mui/material';
import React, { Component } from 'react';
import { savePreferences, saveSetup } from '../../../SetUp/actions';
import { connect } from 'react-redux';
import { AppConfigs } from '../../../SetUp/config';
import AppsPanel from './AppsPanel';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import DNDContext from './DNDContext';
import { MYAPPS_DIRECTIONS, MYAPPS_ITEMS_COUNT_PANEL, MYAPPS_ITEMS_COUNT_ROW } from '../../../common/constants';
import { filterBadData, getSelectedApps } from '../../../MyApps/utils';
import { getUser } from '../../../Layout/utils';
import { myPreferences } from '../../../../DataAccessLayer/services';
import { getData, putData } from '../../../../DataAccessLayer';
import { toast } from 'react-toastify';

class ReorderApps extends Component {

    state = {
        activeTab: 1,
        selectedApps: [],
        isLoading: false,
        isDNDSelected: true
    }

    componentDidMount() {
        const selectedApps = getSelectedApps(this.props.preferences?.Apps?.Apps);
        this.setState({
            selectedApps
        })
    }

    //Θ(1) Sets active tab
    onTabChange = (e, activeTab) => {
        this.setState({activeTab});
    };

    //Θ(1) toggle isLoading
    toggleIsLoading = (isLoading = !this.state.isLoading) => {
        this.setState({
            isLoading
        })
    }

    //Θ(1) toggle isDNDSelected
    toggleIsDNDSelected = (isDNDSelected = !this.state.isDNDSelected) => {
        this.setState({
            isDNDSelected
        })
    }

    //Θ(N) where N is the number of selected apps
    //Returns the index of the app in the array
    getAppIndex = (appId) => {
        return this.state.selectedApps.indexOf(appId);
    }

    getAppPositionInfo =  (appId) => {
        const appIndex = this.getAppIndex(appId);
        const panelNo = Math.floor(appIndex / MYAPPS_ITEMS_COUNT_PANEL);
        const rowCount = MYAPPS_ITEMS_COUNT_PANEL / MYAPPS_ITEMS_COUNT_ROW;

        return {rowCount, panelNo}
    }

    //Θ(N) where N is the number of selected apps
    //Creates an array of panel consisting an array of apps
    panelizeApps = (apps) => {

        const noPanels = Math.ceil(apps.length / MYAPPS_ITEMS_COUNT_PANEL);

        let appsByPanel = [];

        Array(noPanels).fill(0).forEach((_, index) => {
            appsByPanel.push(apps.slice(MYAPPS_ITEMS_COUNT_PANEL * (index+1) - MYAPPS_ITEMS_COUNT_PANEL, MYAPPS_ITEMS_COUNT_PANEL * (index+1)))
        });

        return appsByPanel;
    }

    //Θ(N) where N is the number of selected apps
    //Updated the index of each app in the object 
    updatePreferences = (selectedApps = this.state.selectedApps) => {
        let preferences = {...this.props.preferences};

        //Change the preferences as per the new order
        selectedApps.forEach((appId, index) => {
            preferences.Apps.Apps[appId] = index
        });
        return preferences;
    }

    //Θ(N) where N is the number of selected apps
    //Shifts the apps based on new positions
    onDragEnd = (result) => {
        const {active, over} = result;

        const items = this.state.selectedApps;

        if(active?.id && over?.id && items && active.id !== over.id) {
            const oldIndex = items.findIndex((item) => item === active.id);
            const newIndex = items.findIndex((item) => item === over.id);

            const selectedApps = arrayMove(items, oldIndex, newIndex);

            this.setState({
                selectedApps
            });
        }
    }

    //Θ(1) Get index of application to the left of selected app
    getLeftAppIndex = (appId) => {
        const appIndex = this.getAppIndex(appId);
        const {panelNo} = this.getAppPositionInfo(appId);

        //Initially we set isLeft to true which mean there is an application to the left of selected app
        let isLeft = true;
        //The left index is set as -1. The application to the left of selected application is always the application behind it.
        let leftIndex = appIndex - 1;

        //We will check if the current application is in first column.
        const isFirstColumn = (appIndex) % MYAPPS_ITEMS_COUNT_ROW === 0;

        //If the selected app is in first column and not in 1st panel then the app left to selected app will be app in previous panel.
        if(isFirstColumn) {
            leftIndex = appIndex - (MYAPPS_ITEMS_COUNT_PANEL - (MYAPPS_ITEMS_COUNT_ROW - 1));
        }

        //If the selected app is in 1st columns and 1st panel the there will be no app to the left
        if(panelNo === 0 && (appIndex % MYAPPS_ITEMS_COUNT_ROW === 0)) {
            isLeft = false;
        }

        if(!isLeft || this.state.isDNDSelected) {
            leftIndex = -1;
        }
        

        return leftIndex;
    }

    //Θ(1) Get index of application to the right of selected app
    getRightAppIndex = (appId) => {
        const appIndex = this.getAppIndex(appId);
        //We check if the selected app is in the last column
        const isLastColumn = (appIndex+1) % MYAPPS_ITEMS_COUNT_ROW === 0;

        //Initially right index will be set to -1
        let rightIndex = -1;

        //If last column then the app right to the selected application will be in next panel.
        if(isLastColumn) {
            rightIndex =  appIndex + (MYAPPS_ITEMS_COUNT_PANEL - (MYAPPS_ITEMS_COUNT_ROW - 1));
        } else {
            rightIndex = appIndex + 1;
        }

        //Check if there is an application to the right of the selected application
        const isRight = this.state.selectedApps.length > rightIndex

        if(!isRight || this.state.isDNDSelected)
            rightIndex = -1;

        return rightIndex;
    }

    //Θ(1) Get index of application to the down of selected app
    getDownAppIndex = (appId) => {
        const appIndex = this.getAppIndex(appId);
        const {rowCount, panelNo} = this.getAppPositionInfo(appId);

        //Check if the app is in the last row
        const isLastRow = Math.floor((appIndex - MYAPPS_ITEMS_COUNT_PANEL * panelNo) / MYAPPS_ITEMS_COUNT_ROW) === (rowCount - 1);

        //Check if there is an app below selected app. If app is in last arrow then there will be no app below
        const isAppDown = this.state.selectedApps.length > (appIndex + MYAPPS_ITEMS_COUNT_ROW);

        const isDown = !isLastRow && isAppDown;

        let downIndex = appIndex + MYAPPS_ITEMS_COUNT_ROW;

        if(!isDown || this.state.isDNDSelected)
            downIndex = -1;

        return downIndex;
    }

    //Θ(1) Get index of application to the up of selected app
    getUpAppIndex = (appId) => {
        const appIndex = this.getAppIndex(appId);
        const { panelNo} = this.getAppPositionInfo(appId);

        //Check if the app is in first row. If in first row then there is no app at top of selected app.
        const isFirstRow = (appIndex - MYAPPS_ITEMS_COUNT_PANEL * panelNo) < MYAPPS_ITEMS_COUNT_ROW;

        const isUp = !isFirstRow;

        let upIndex = appIndex - MYAPPS_ITEMS_COUNT_ROW;

        if(!isUp || this.state.isDNDSelected)
            upIndex = -1;

        return upIndex;
    }

    //Θ(1) Returns the indexes of the apps to the sides of selected app
    getSideAppsIndexes = (appId) => {

        const leftAppIndex = this.state.isDNDSelected ? -1 : this.getLeftAppIndex(appId);

        const rightAppIndex = this.state.isDNDSelected ? -1 : this.getRightAppIndex(appId);

        const downAppIndex = this.state.isDNDSelected ? -1 : this.getDownAppIndex(appId);
        
        const upAppIndex = this.state.isDNDSelected ? -1 : this.getUpAppIndex(appId);

        return {
            leftAppIndex, rightAppIndex, downAppIndex, upAppIndex
        }
    }

    //Θ(1) Updates the location of the app in the grid
    moveApp = (appId, nextAppIndex) => {
        if(nextAppIndex === -1) {
            return;
        }

        const currentAppIndex = this.getAppIndex(appId);

        const selectedApps = arrayMove(this.state.selectedApps, currentAppIndex, nextAppIndex);

        this.setState({
            selectedApps
        });
    }

    //Θ(1) Saves the edited apps order
    onSaveNewArrangement = () => {
        this.toggleIsLoading(true);
        this.updatePreferences(this.state.selectedApps);
        const preferences = this.props.preferences
        const user = getUser(this.props.user, this.props.impersonation);
        putData(myPreferences, {
            preferences,
            midas: user.midas
        })
        .then(_ => {
            this.props.savePreferences(preferences)
            this.loadPreferences();
            toast.success("New app arrangement saved")
        })
        .catch(err => {
            console.log(err);
            toast.error("Error saving new app arrangement")
        })
        .finally(_ => {
            this.toggleIsLoading(false);
        })
    }

    loadPreferences = () => {
        getData(
            myPreferences +
                '/' +
                (getUser(this.props.user, this.props.impersonation).midas),
            true
        )
        .then(preferences => {
            this.props.savePreferences(preferences);
        })
        .catch(err => {
            console.log(err);
        });
    }

    //Θ(1) Cancels rearrangement and updates the order back to original.
    onRearrangeCancel = () => {
        const selectedApps = getSelectedApps(this.props.preferences?.Apps?.Apps);
        this.setState({
            selectedApps
        })
    }

    render() {
        const allApps = filterBadData(this.state.selectedApps, AppConfigs);

        const appsByPanel = this.panelizeApps(allApps);
        
        return <Box className="setDisplayOrder">
            <Typography component="h4" className="sr-only visibility-hidden">Set Display Order</Typography>

            <Box sx={{width: '100%'}}>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    className="myOdu__toggleSwitch"
                >
                    <Stack direction={{sm: 'column', md: 'row'}}>
                        <label>   
                                Choose mode: &nbsp;
                        </label>
                        
                        <Stack direction="row">
                            <Typography>
                                Drag and Drop
                            </Typography>
                                <Switch 
                                    id = "settings_myApps_redorder_switch_toggleDND"
                                    size="small"
                                    color='primary'
                                    value={this.state.isDNDSelected}
                                    onChange={() => {this.toggleIsDNDSelected()}}
                                />
                            <Typography>
                                Keyboard Accessible
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>    

            <Box>
                <DNDContext
                    onDragEnd = {this.onDragEnd}
                    isDNDSelected = {this.state.isDNDSelected}
                >
                    <Stack direction={'row'} overflow={'auto'}>
                        <SortableContext items={this.state.selectedApps}>
                            {
                                appsByPanel.map((apps, panelNo) => {
                                    return <Box 
                                       className="appsPanelWrapper"
                                        sx={{
                                        minWidth: '30rem',
                                        margin: 1
                                        }}
                                    >
                                        <AppsPanel apps = {apps} panelNo = {(panelNo + 1)} moveApp = {this.moveApp} getSideAppsIndexes = {this.getSideAppsIndexes} isDNDSelected = {this.state.isDNDSelected} />
                                    </Box>
                                })
                            }
                        </SortableContext>
                    </Stack>
                    
                    <Stack
                            sx={{width: '100%', mt: 2}}
                            direction={{xs: 'col', sm: 'row'}}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                variant="outlined"
                                size='small'
                                id={'myApps__button_saveNewArrangement'}
                                onClick={this.onSaveNewArrangement}
                                className="myOdu__button primary myAppsButton"
                                disabled = {this.state.isLoading}
                            >
                                Save Arrangement
                            </Button>

                            <Button
                                variant="outlined"
                                size='small'
                                id={'myApps__button_rearrangeCancel'}
                                onClick={this.onRearrangeCancel}
                                className='myOdu__button secondary myAppsButton'
                            >
                                Cancel
                            </Button>

                        </Stack>
                </DNDContext>
            </Box>
        </Box>
    }
}

const mapStateToProps = (state) => {
    return {
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveSetup: (setup) => dispatch(saveSetup(setup)),
    savePreferences: (preferences) => dispatch(savePreferences(preferences))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReorderApps);