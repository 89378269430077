import React, {Component} from 'react';
import {Link, Tooltip, Typography} from '@mui/material';
import PortalTooltip from '../common/PortalTooltip';

class AlertDescription extends Component {
  render() {
    return (
      <Typography id={this.props.id+"__textLinkDescriptionWrapper"} component="p" sx={{mb: 1}}>
        <Typography
          id={this.props.id+"__textDescriptionWrapper"}
          variant="p"
          dangerouslySetInnerHTML={{
            __html: this.props.description
          }}
        />
        {this.props.linkTitle?.trim() && this.props.linkUrl?.trim() && (
          <span id={this.props.id+"__span_linkWrapper"}>
            <PortalTooltip id={this.props.id+"__portalTooltip"}>
              <Link
                href={this.props.linkUrl}
                underline="always"
                target="_blank"
                color="inherit"
                id={this.props.id+"__link_viewHolds"}
                sx={{ml: 1, cursor: 'pointer'}}
                variant="small"
              >
                {this.props.linkTitle?.trim()}
              </Link>
            </PortalTooltip>
          </span>
        )}
      </Typography>
    );
  }
}

export default AlertDescription;
