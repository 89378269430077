import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import React, {Component} from "react";
import { hasDuplicate, emailOccurences, isValidEmail, isValidEmails } from "../MyCourses/CCT/Body/Zoom/Meetings/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import PortalTooltip from "./PortalTooltip";

class PortalEmailsInput extends Component {
    state = {
        errorMessages: {
            INVALID_EMAIL: 'Alternative hosts can only use an ODU email address. \n',
            DUPLICATE_EMAIL: 'Duplicate email address(es) found. Delete duplicate(s). '
        }
    }

    //Θ(1) Updates the altHosts array
    onHostsChange = (_, newValue) => {
        let {isError, errorMessage} = this.validateEmails(newValue)
        this.props.onChangeAltHosts(newValue);
        this.props.onChangeIsError(isError, errorMessage);
    }

    //Θ(1) On press of space it updates the altHosts array
    handleKeyDown = event => {
        if(event.target.value.trim().length === 0)
            return;
        switch (event.key) {
          case ",":
          case " ": {
            event.preventDefault();
            event.stopPropagation();
            if (event.target.value.length > 0) {
                this.props.onChangeAltHosts([...this.props.altHosts, event.target.value]);
                this.props.onChangeInputValue('');

                let {isError, errorMessage} = this.validateEmails([...this.props.altHosts, event.target.value])
                this.props.onChangeIsError(isError, errorMessage);
            }

            break;
          }
          default:
        }
    };

    //Θ(1) Checks if there is any error in emails and returns appropriate error message
    validateEmails = (emails = []) => {
        let isError = false;
        let errorMessage = {};

        if(!isValidEmails(emails)) {
            isError = true;
            errorMessage[this.state.errorMessages.INVALID_EMAIL] = true;
        } 
        else {
            delete errorMessage[this.state.errorMessages.INVALID_EMAIL];
        }
        if(hasDuplicate(emails)) {
            isError = true;
            errorMessage[this.state.errorMessages.DUPLICATE_EMAIL] = true;
        }
        else {
            delete errorMessage[this.state.errorMessages.DUPLICATE_EMAIL];
        }

        return {isError, errorMessage};
    }

    //Θ(N) where N is the number of emails
    //Updates the state of input
    onInputChange = (event, newInput) => {
        let value = event.target.value
        let tokens = value.split(/[\s, \n]+/)

        let isError = false;
        let errorMessage = '';

        if(tokens.length > 1) {
            let distinct = new Set()
            for(const token of tokens) {
                try {
                    distinct.add(token)
                }
                catch {
                }
            }
            let emails = Array.from(distinct);

            ({isError, errorMessage} = this.validateEmails([...this.props.altHosts, ...emails]));

            this.props.onChangeAltHosts([...this.props.altHosts, ...emails]);
            this.props.onChangeIsError(isError, errorMessage);

            return;
        }

        ({isError, errorMessage} = this.validateEmails([...this.props.altHosts, newInput]));
        this.props.onChangeInputValue(newInput);
        this.props.onChangeIsError(isError, errorMessage);
    }

    //Θ(N) where N is the length of altHosts
    //Removes the email form the list
    handleChipClick = (item) => {
        let altHosts = this.props.altHosts.filter(host => host !== item)
        this.props.onChangeInputValue(item);
        this.props.onChangeAltHosts(altHosts);
    }

    render() {
        return <React.Fragment>
            <Autocomplete 
                clearIcon = {true}
                options={[]}
                freeSolo
                multiple
                value={this.props.altHosts}
                inputValue={this.props.inputValue}
                renderTags={(value, props) => {
                    const emailCounts = emailOccurences(value);
                    return value.filter(option => option !== '').map((option, index) => {
                        const isError = !isValidEmail(option) || (emailCounts[option] > 1)
                        return <Chip variant="outlined" icon={isError && <FontAwesomeIcon icon={faCircleExclamation} />} color={!isError ? 'default' : 'error'} onClick = {() => this.handleChipClick(option)} label={option} {...props({ index })} 
                            deleteIcon={
                                <PortalTooltip id={option + '_remove'} title="Remove from alt host list">
                                    <FontAwesomeIcon icon={faCircleXmark} style={{color: 'black'}} />
                                </PortalTooltip>
                            }
                        />
                    })
                }}
                onChange={this.onHostsChange}
                renderInput={(params) => {
                    params.inputProps.onKeyDown = this.handleKeyDown
                    return <TextField
                        label=""
                        variant="outlined"
                        {...params} 
                        placeholder="Add ODU Email Address"
                        className="myOdu__hideFloatLabel"
                    />
                }}
                onInputChange={this.onInputChange}
            />
            {this.props.isError ? (
                <div>
                    {Object.keys(this.props.errorMessage)?.map(err => {
                        return <Typography
                            display="flex"
                            justifyContent="flex-start"
                            variant="caption"
                            className="myOdu__error"
                        >
                            {err}
                        </Typography>
                    })}
                </div>
                ) : (
                <Typography
                    display="flex"
                    justifyContent="flex-start"
                    variant="caption"
                    className="myOdu__helper"
                >
                    Only ODU users can be set as alternative hosts
                </Typography>
                )}
        </React.Fragment>
    }
}

export default PortalEmailsInput;