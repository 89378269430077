import React from 'react';

import {Typography, Container, Box} from '@mui/material';

import {campusLocations} from '../common/Form/FormOptions';
import {FormControlledDropdown} from '../common/Form/FormDropdown';

export const CampusServiceSection = ({control}) => {
  return (
    <Box className="campusServiceLocation">
      <Typography component="h3">Campus Service Location</Typography>
      <Typography>
        Location for which campus services will be received.
      </Typography>

      <Box sx={{flexGrow: 1, mt: 3, mb: 3}}>
        <FormControlledDropdown
          name="accessServiceAt"
          label="Location"
          control={control}
          options={campusLocations}
        />
      </Box>
    </Box>
  );
};
