import MeetingActions from "./MeetingActions";
import moment from 'moment';
import MeetingTitle from "./MeetingTitle";
import { Col, Row } from "reactstrap";
import { Grid, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmClock } from "@fortawesome/pro-light-svg-icons";
import PortalTooltip from "../../../../../common/PortalTooltip";
import { Time } from "../../../../../common/MomentTime";
import PortalTime from "../../../../../common/PortalTime";
import { isMeetingReadyToStart, startMeeting } from "../../../utils";

//Θ(1) returns the columns for meetings
export const getColumns = (props, isCurrentMeetings) => {
    const timeFormat = "h:mm A"
    return [
        {field: 'topic', headerName: 'Title', flex: 1.5,
            renderCell: params => {
                //TODO: render meeting recording for past meetings
                return <MeetingTitle row = {params.row} openSnackbar = {props.openSnackbar} getShortTopic = {props.getShortTopic} />
            }
        },
        {field: 'start_time', headerName: 'Date/Time', flex: 2,
            renderCell: params => {
                const date = new Date(params.value)
                const today = new Date();

                const startTime = Time.University(params.value);
                const endTime = Time.University(params.value).add(params.row.duration);
                const currentTime = Time.University();

                const timeZone = "America/New_York"
                if(Time.University(params.value).isSame(Time.University(), 'day')) {
                    //Format: Today, 10:00 AM - 11:00 AM
                    return <Grid container alignItems='center' gap={0.2} wrap="nowrap">
                        {
                            (isMeetingReadyToStart(params.row) ||
                            ( moment.duration(startTime.diff(currentTime)).asMinutes() > 0 && moment.duration(endTime.diff(currentTime)).asMinutes() <= 0 )) &&
                            isCurrentMeetings && params.row.start_url &&
                            <Grid item>
                                <PortalTooltip id={params.row.topic + '_start_meeting'} title={props.course.isTeaching ? "Start meeting" : "Launch meeting"}>
                                    <IconButton color="inherit" aria-label="start_meeting" onClick={() => {
                                        startMeeting(props.email, params.row, props.course)
                                    }} className="myOdu__iconButton myOdu__urgent">
                                        <FontAwesomeIcon icon={faAlarmClock} fixedWidth />
                                    </IconButton>
                                </PortalTooltip>
                            </Grid>
                        }
                        <Grid item container direction={'row'}>
                            {'Today, '} &nbsp;
                            <PortalTime className="myOdu__small" title={Time.University(params.value).format(timeFormat) + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'}>
                                {Time.University(params.value).format(timeFormat)}
                            </PortalTime>
                            &nbsp; {' - '} &nbsp;
                            <PortalTime 
                                time={Time.University(params.value).add(params.row.duration, 'minutes').format(timeFormat)} className="myOdu__small"
                                title={Time.University(params.value).add(params.row.duration, 'minutes').format(timeFormat) + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'}    
                            />
                        </Grid>
                    </Grid>
                }
                else if(date.getMonth() === today.getMonth() || Time.University(date).diff(Time.University(today), 'week')) {
                    // Format: Monday, Jan 4, 10:00 AM - 11:00 AM
                    // return date.toLocaleDateString('en-us', {weekday: 'long', hour: '1 or 2 digit', minute: "2-digit"})
                    return <Grid container direction={'row'}>
                        {Time.University(params.value).format('dddd, MMM D, ')}
                        &nbsp;
                        <PortalTime 
                            time={Time.University(params.value).format(timeFormat)} className="myOdu__small" 
                            title={Time.University(params.value).format(timeFormat) + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'}
                        />
                        &nbsp; {  ' - ' } &nbsp;
                        <PortalTime 
                            time={Time.University(params.value).add(params.row.duration, 'minutes').format(timeFormat)}  className="myOdu__small"
                            title={Time.University(params.value).add(params.row.duration, 'minutes').format(timeFormat) + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'}
                        />
                    </Grid>
                }
                // return new Date(params.value).toLocaleDateString('en-us', {month: 'long', day: '1 or 2-digit', hour: '1 or 2-digit', minute: '2-digit'})
                //Format: January 1, 10:00 AM - 11:00 AM
                return <Grid container direction={'row'}>
                    {Time.University(params.value).format('MMMM D, ')}
                    &nbsp;
                    <PortalTime 
                        time={Time.University(params.value).format(timeFormat)} className="myOdu__small" 
                        title={Time.University(params.value).format(timeFormat) + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'}
                    />
                        &nbsp; { ' - ' } &nbsp;
                    <PortalTime 
                        time={Time.University(params.value).add(params.row.duration, 'minutes').format(timeFormat)}  className="myOdu__small"
                        title={Time.University(params.value).add(params.row.duration, 'minutes').format(timeFormat) + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'}
                    />
                </Grid>
            }
        },
        // {
        //     field: 'meeting_details', headerName: 'Meeting ID', flex: 1,
        //     valueGetter: (params) => {
        //         return params.row?.queued ? 'Being Processed' : params.row.id
        //     } 
        // },
        // {
        //     field: 'meeting_password', headerName: 'Password', flex: 1,
        //     valueGetter: (params) => {
        //         return params.row?.queued ? '' : params.row.password
        //     } 
        // },
        (isCurrentMeetings && !props.isLastSemCourse && {
            field: 'actions', headerName: 'Actions', flex: 1,
            renderCell: (params) => {
                let {row} = params;
                return <MeetingActions isTeaching = {props.course.isTeaching} activeTab = {props.activeTab} meetingDetails = {row} onEnableGuestSpeaker = {props.onEnableGuestSpeaker} onJoinBeforeHost = {props.onJoinBeforeHost} onAddAltHost = {props.onAddAltHost} openSnackbar = {props.openSnackbar} onDeleteMeeting = {props.onDeleteMeeting}/>
            }
        })
    ]
}