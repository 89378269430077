import { TOGGLE_SIDE_NAV } from "./actions";

const initialStateHeader = {
    isSideNav: true,
}

//needs to persist across pages
export const headerReducer = (state = initialStateHeader, action) => {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_SIDE_NAV: {
            return {...state, isSideNav: !state.isSideNav}
        }
        default:
            return state;
    }
}