import { Button, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "reactstrap";
import Box from '@mui/material/Box';
import PortalAutoComplete from "../../common/PortalAutocomplete";
import {cleanUpID} from "../../common/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const DdProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const boxStyle ={
    xs: 0.5,
    xxl:2 
}
class TutoringDetails extends React.Component {

    state = {
    }

    render() {
        return <React.Fragment>
            
            <Typography id={this.props.id +"__text_tutoringDetailsTitle"} variant="h4" className="py-3">Tutoring Details</Typography>
            <Row id={ this.props.id +"__row_courseDetailsWrapper"}>
                <Row id={ this.props.id +"__col_courseDetailsWrapper"}>
                    <Col id={ this.props.id +"__col_courseWrapper"} xs={12} xxl={2}>
                        <Box id={this.props.id +"__box_courseWrapper"} pt={3} pb={boxStyle}>
                            <InputLabel className="myOdu__label" id={ this.props.id +"__course_select_label"} htmlFor="course-select">
                                Course
                            </InputLabel>
                        </Box>
                    </Col>
                    <Col id = {this.props.id +"__col_course_select"}>
                        <Box id = {this.props.id +"__box_course_select"} py={boxStyle}>
                            <PortalAutoComplete
                                id = {this.props.id +"__course_select"}
                                options={this.props.courses}
                                disableOption = {this.props.disableOption}
                                selectedTutoring = {this.props.selectedTutoring}
                                isDetails = {this.props.isDetails}
                                placeholder="Enter Course subject, number or title"
                                getOptionDisabled = {(option) => {
                                    return this.props.disableOption(option) && !this.props.compareCourses(option, this.props.selectedTutoring)
                                }}
                                getOptionLabel={(option) => {
                                    if(!option.SUBJECT)
                                        return '';
                                    return option.SUBJECT.concat(" ", option.COURSE_NUMBER, " ", option.COURSE_TITLE)
                                }}
                                onChange = {(_, selectedValue) => {
                                    this.props.onCourseUpdate(selectedValue)
                                }}
                            />
                        </Box>
                    </Col>
                </Row>
                <Row id={this.props.id +"__box_formWrapper"}>
                    <Col id={this.props.id +"__col_locationsTitleWrapper"} xs={12} xxl={2}>
                        <Box id={this.props.id +"__box_text_locationsTitleWrapper"} pt={3} pb={boxStyle}>
                            <InputLabel className="myOdu__label" id={this.props.id +"__location_text_locationsTitle"} htmlFor="location-select-input">Locations</InputLabel>
                        </Box>
                    </Col>
                    <Col id={this.props.id +"__col_selectLocations_wrapper"}>
                        <Box id={this.props.id +"__box_selectLocations"} py={boxStyle}>
                            <Select
                                labelId="location-select-label" //for ADA
                                fullWidth size="small"
                                displayEmpty
                                id={this.props.id +"__dropdown_selectLocations"}
                                multiple
                                onChange={this.props.onLocationsUpdate}
                                MenuProps={DdProps}
                                value={this.props.selectedTutoring.locations}
                                disabled={!this.props.isDetails}
                                renderValue={(selected) => {
                                    if(!selected.length)
                                        return <span>Select location(s)</span>
                                    
                                    return selected.map((select) => select.text).join(', ')
                                }}
                                input={<OutlinedInput id={this.props.id +"__outlinedInput_selectLocations"} />}
                                >
                                {this.props.locations.map((location) => (
                                    <MenuItem
                                        id={this.props.id +"__menuItem_"+cleanUpID(location.text)}
                                        key={location.text}
                                        value={location}
                                    >
                                    {location.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Col>
                </Row>
                <Row id={this.props.id +"__row_dialog_addCourseTutoring_wrapper"}>
                    <Col id={this.props.id +"__button_col_addCourseTutoring"} className="p-3">
                        <Box id={this.props.id +"__box_dialog_addCourseTutoring"} style={{float: 'right'}}>
                            <Button
                                id={this.props.id +"__button_dialog_addCourseTutoring"}
                                variant="outlined" sx={{mt:1}} 
                                onClick={this.props.onSubmit}
                                disabled={!this.props.isDetails || !this.props.selectedTutoring.locations.length || !this.props.selectedTutoring.SUBJECT || this.props.isLoading}>
                                {this.props.buttonText}
                            </Button>
                        </Box>
                    </Col>
                </Row>
            </Row>
        </React.Fragment>
    }
}


export default TutoringDetails;