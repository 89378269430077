import React, {Component} from 'react';
import {Typography, Box} from '@mui/material';
import {Col, Row} from 'reactstrap';
import PortalModal from '../common/PortalModal';
import AddBookmark from './AddBookmark';
import EditBoomark from './EditBookmark';

class BookmarksManager extends Component {
    state = {};
    render() {
        return (
            <React.Fragment>
                <PortalModal
                    isOpen={this.props.isOpen}
                    onClose={this.props.onToggle}
                    title={'myBookmarks'}
                    isCloseable
                    className="myOdu__myBookmarksManager"
                    id={'bookmarksManager__modal_contentWrapper'}
                >
                    <Box
                        id={'bookmarksManager__box_addBookmark'}
                        className="addBookmark"
                    >
                        <Typography
                            id={'bookmarksManager__label_addBookmark'}
                            variant="h4"
                            component="h3"
                            className="py-3"
                        >
                            Add Bookmark
                        </Typography>
                        <AddBookmark
                            id={'bookmarksManager__inputFieldsWrapper_add'}
                            onAdd={this.props.onAdd}
                            isReset={this.props.isReset}
                            toggleIsReset={this.props.toggleIsReset}
                            isDuplicate={this.props.isDuplicate}
                        />
                    </Box>

                    <Box
                        id={'bookmarksManager__box_editBookmarks'}
                        className="editBookmarks"
                    >
                        <Typography
                            id={'bookmarksManager__box_addBookmark'}
                            variant="h4"
                            component="h3"
                            className="py-3"
                        >
                            Edit Bookmarks
                        </Typography>
                        <EditBoomark
                            id={
                                'bookmarksManager__inputFieldsWrapper_editDelete'
                            }
                            bookmarks={this.props.bookmarks}
                            onEdit={this.props.onEdit}
                            onDelete={this.props.onDelete}
                            isReset={this.props.isReset}
                            toggleIsReset={this.props.toggleIsReset}
                            isDuplicate={this.props.isDuplicate}
                        />
                    </Box>
                </PortalModal>
            </React.Fragment>
        );
    }
}

export default BookmarksManager;
