import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Link, Menu, MenuItem, Typography } from "@mui/material";
import React, {Component} from "react";
import { ZoomTabMapping } from "../config";
import moment from "moment";
import PortalTooltip from "../../../../../common/PortalTooltip";
import {meetingStartsWithin} from '../../../../../common/constants';
import { Time } from "../../../../../common/MomentTime";
import { isMeetingReadyToStart, startMeeting } from "../../../utils";
import { getUserEmail } from "../ScheduleMeeting/utils";
import { connect } from "react-redux";

class MeetingActions extends Component {
    state = {
        anchor: null,
        isOpen: false,
        isCopied: false,
        snackbar: {
            message: '',
            success: ''
        }
    }

    //Θ(1) open anchor
    dropAnchor = (e) => {
        this.setState({anchor: e.currentTarget, isOpen: true})
    }
    
    //Θ(1) Close anchor 
    pullAnchor = () => {
        this.setState({anchor: null, isOpen: false})
    }

    //Θ(1) Start meeting 
    onStartMeeting = (meetingDetails) => {
        //We first add current user to alt host list and then start the meeting
        startMeeting(getUserEmail(this.props.user, this.props.impersonation), meetingDetails, this.props.course);
    }

    render() {
        const isCurrentMeeting = this.props.activeTab === ZoomTabMapping.currentMeeting;
        const isQueued = this.props.meetingDetails.queued === true
        const meetingDetails = this.props.meetingDetails;
        const isAltHostEdit = meetingDetails?.settings?.alternative_hosts?.length > 0

        return <React.Fragment>
            {
                isCurrentMeeting && this.props.isTeaching && meetingDetails.start_url &&
                <div>
                    {/* //TODO: needed to address fontSize issue later */}
                    <Button className="myOdu__button cctActions" variant="outlined" endIcon={<FontAwesomeIcon icon={faChevronRight} style={{fontSize: ".8rem"}}/>} onClick={this.dropAnchor} size="small">
                        Actions
                    </Button>
                    <Menu
                        anchorEl={this.state.anchor}
                        open={this.state.isOpen}
                        onClose={this.pullAnchor}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {
                            isMeetingReadyToStart(meetingDetails) && <MenuItem onClick={() => {this.onStartMeeting(meetingDetails)}} id={'cct__menuItem_startMeeting_' + meetingDetails.id}>
                                Start Meeting
                            </MenuItem>
                        }

                        {!isQueued && <MenuItem onClick={() => this.props.onAddAltHost(meetingDetails)}>{isAltHostEdit > 0 ? "Edit Alt Host" : "Add Alt Host"}</MenuItem>}

                        {
                            !isQueued && meetingDetails.type === 2 && !meetingDetails.isAdhoc && meetingDetails.isShared === undefined 
                            && <MenuItem disabled={!meetingDetails.settings.meeting_authentication} onClick={() => this.props.onEnableGuestSpeaker(meetingDetails.id)}>
                                Enable Guest Speaker
                            </MenuItem>
                        }
                        {
                            !isQueued && meetingDetails.type === 2 && !meetingDetails.isAdhoc && meetingDetails.isShared === undefined 
                            && <MenuItem onClick={() => this.props.onJoinBeforeHost(meetingDetails.id, !meetingDetails.settings.join_before_host)}>
                                {
                                    meetingDetails.settings.join_before_host ?
                                    'Disable Join Before Host' :
                                    'Enable Join Before Host'
                                }
                            </MenuItem>
                        }
                        <MenuItem onClick={() => {this.props.onDeleteMeeting(meetingDetails)}}>Delete</MenuItem>
                    </Menu>
                </div>
            }
            {
                isCurrentMeeting && !this.props.isTeaching && meetingDetails.join_url && isMeetingReadyToStart(meetingDetails) &&
                <div>
                    <PortalTooltip title="Launch Zoom">
                        <Link
                            variant="body2"
                            href={meetingDetails.join_url}
                            target='_blank'
                            style={{cursor: 'pointer'}}
                        >
                            Launch Zoom
                        </Link>
                    </PortalTooltip>
                </div>
            }
            {
                !meetingDetails.join_url && !meetingDetails.start_url &&
                <div>
                    <Typography variant="p" component='p'>Processing Add to Zoom</Typography>
                </div>
            }
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation,
        course: state.CCTReducer.course
    }
}

export default connect(mapStateToProps)(MeetingActions);