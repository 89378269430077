import React, {Component} from 'react';
import {
    Stack,
    Grid,
    IconButton,
    Link,
    Typography,
    MobileStepper,
    Button
} from '@mui/material';
import {connect} from 'react-redux';
import Widget from '../common/Widget';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
    faCircle as emptyCircle
} from '@fortawesome/pro-light-svg-icons';
// import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import {
    faCheck,
    faXmark,
    faCircle as filledCircle
} from '@fortawesome/pro-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import {savePreferences, saveSetup} from '../SetUp/actions';
import {WithSmallScreen} from '../common/WithSmallScreen';
import {AppConfigs} from '../SetUp/config';
import {Link as RouterLink} from 'react-router-dom';
import {
    rectSortingStrategy,
    arrayMove,
    arraySwap,
    rectSwappingStrategy
} from '@dnd-kit/sortable';
import Droppable from './Droppable';
import {DndContext} from '@dnd-kit/core';
import {MYAPPS_LEFT_PANEL, MYAPPS_RIGHT_PANEL} from '../common/constants';
import {myPreferences} from '../../DataAccessLayer/services';
import {getData, putData} from '../../DataAccessLayer';
import {getUser} from '../Layout/utils';
import AppsPanel from './AppsPanel';
import {isMobile} from 'react-device-detect';
import PortalTooltip from '../common/PortalTooltip';
import {filterBadData, getSelectedApps} from './utils';

class MyApps extends Component {
    //Θ(1) Makes a copy of current prefereneces in setup and resets
    onReset = () => {
        if (Object.keys(this.props.preferences?.Apps?.Apps).length) {
            let setup = {reset: this.props.preferences};
            this.props.savePreferences({});
            this.props.saveSetup(setup);
        } else {
            this.props.savePreferences({});
        }
    };

    onReorderApps = () => {
        const oldApps = Object.assign({}, this.props.preferences.Apps.Apps);
        this.toggleIsReorderApps(true);
        this.setState({
            oldApps
        });
    };

    state = {
        isExpand: true,
        menu: [],
        active: 1,
        itemsPerPanel: 9,
        noItemsByWidth: {
            xs: 9,
            sm: 9,
            md: 9,
            lg: 9,
            xl: 9,
            xxl: 9,
            '^xxl': 9
        },
        selectedApps: [],
        isOverChangePanel: false,
        isMoveTriggered: false,
        isReorderApps: false,
        oldApps: {}
    };

    componentDidMount() {
        const selectedApps = getSelectedApps(
            this.props.preferences?.Apps?.Apps
        );
        const menu = [
            //    {id: 'myApps_editFavorites', title: 'Edit Favorites'},
            {
                id: 'myApps_resetSetup',
                title: 'Reset Setup',
                onClick: this.onReset
            },
            {
                id: 'myApps_reorderApps',
                title: 'Reorder Apps',
                onClick: this.onReorderApps,
                disabled: !selectedApps.length
            }
        ];
        this.setState({
            selectedApps,
            menu
        });
    }

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    toggleIsOverChangePanel = (
        isOverChangePanel = !this.state.isOverChangePanel
    ) => {
        this.setState({isOverChangePanel});
    };

    toggleIsMoveTriggered = (isMoveTriggered = !this.state.isMoveTriggered) => {
        this.setState({isMoveTriggered});
    };

    toggleIsReorderApps = (isReorderApps = !this.state.isReorderApps) => {
        this.setState({
            isReorderApps
        });
    };

    //Θ(N) where N is the number of selected apps
    //Returns the next panel in the list
    getNextActive = () => {
        const itemsPerPanel = this.state.noItemsByWidth[this.props.screenSize];
        let lastPanel = Math.ceil(
            Object.keys(this.state.selectedApps).length / itemsPerPanel
        );

        if (this.state.active < lastPanel) return this.state.active + 1;
        else return 1;
    };

    //Θ(N) where N is the number of selected apps
    //Returns the previous panel in the list
    getPrevActive = () => {
        const itemsPerPanel = this.state.noItemsByWidth[this.props.screenSize];
        let lastPanel = Math.ceil(
            Object.keys(this.state.selectedApps).length / itemsPerPanel
        );

        if (this.state.active > 1) return this.state.active - 1;
        else return lastPanel;
    };

    //Θ(1) Moves to next panel
    onNext = () => {
        this.setState({
            active: this.getNextActive()
        });
    };

    //Θ(1) Moves to previous panel
    onPrev = () => {
        this.setState({
            active: this.getPrevActive()
        });
    };

    //Θ(N) where N is the number of selected apps in preferences
    //Reorders the apps when drag ends
    onDragEnd = result => {
        const {active, over} = result;
        const items = result?.over?.data?.current?.sortable?.items;
        if (
            active?.id &&
            over?.id &&
            active.id !== over.id &&
            over.id !== MYAPPS_RIGHT_PANEL &&
            over.id !== MYAPPS_LEFT_PANEL
        ) {
            const oldIndex = this.state.selectedApps.findIndex(
                item => item === active.id
            );
            const newIndex = this.state.selectedApps.findIndex(
                item => item === over.id
            );

            //Array move is the function of dnd kit which reorders the apps. It moves all the items to left
            const currentApps = arrayMove(
                this.state.selectedApps,
                oldIndex,
                newIndex
            );

            let preferences = {...this.props.preferences};

            //Change the preferences as per the new order
            currentApps.forEach((appId, index) => {
                preferences.Apps.Apps[appId] = index;
            });

            this.props.savePreferences(preferences);
            this.setState({
                selectedApps: currentApps
            });
        }

        this.toggleIsOverChangePanel(false);
    };

    //Θ(1) Triggers the panel movement to given direction.
    triggerChangePanel = (result, direction, timeout = 1000) => {
        //IsMoveTriggered behaves as a lock which restricts multiple drag updates to trigger panel change which may lead to improper panel shifts.
        this.toggleIsMoveTriggered(true);
        setTimeout(() => {
            //After timeout completes to change the panel we want to make sure if user is still in the left/right droppable area. isOverChangePanel is the boolean used
            if (this.state.isOverChangePanel) {
                const active =
                    direction === MYAPPS_LEFT_PANEL
                        ? this.getPrevActive()
                        : this.getNextActive();
                this.setState(
                    {
                        active,
                        isMoveTriggered: false
                    },
                    () => {
                        this.onDragUpdate(result);
                    }
                );
                //onDragUpdate need to be called after setting isMoveTrigger state to false to conitnue scrolling to given panel. Else user will have to redrag the app to left or right end
            } else {
                this.toggleIsMoveTriggered(false);
            }
        }, timeout);
    };

    //Θ(1) This function helps in moving the panel to left or right when the app is dragged to respective direction
    onDragUpdate = result => {
        //Sets the isOverChangePanel boolean based on the position of the app being dragged.
        if (
            !result?.over?.id ||
            (result?.over?.id &&
                result?.over?.id !== MYAPPS_RIGHT_PANEL &&
                result.over.id !== MYAPPS_LEFT_PANEL)
        ) {
            this.toggleIsOverChangePanel(false);
        } else {
            this.toggleIsOverChangePanel(true);
        }

        const direction = result?.over?.id;
        //isMoveTriggered acts as a lock which enables us to trigger panel change on status of the lock.
        if (
            !this.state.isMoveTriggered &&
            (direction === MYAPPS_RIGHT_PANEL ||
                direction === MYAPPS_LEFT_PANEL)
        ) {
            this.triggerChangePanel(result, direction);
        }
    };

    //Θ(1) Saves the edited apps order
    onSaveNewArrangement = () => {
        this.toggleIsReorderApps(false);
        const preferences = this.props.preferences;
        const user = getUser(this.props.user, this.props.impersonation);
        putData(myPreferences, {
            preferences,
            midas: user.midas
        })
            .then(_ => {
                this.props.savePreferences(preferences);
                this.loadPreferences();
            })
            .catch(err => console.log(err));
    };

    loadPreferences = () => {
        getData(
            myPreferences +
                '/' +
                getUser(this.props.user, this.props.impersonation).midas,
            true
        )
            .then(preferences => {
                this.props.savePreferences(preferences);
            })
            .catch(err => {
                console.log(err);
            });
    };

    //Θ(1) Cancels the edit mode of myapps
    onRearrangeCancel = () => {
        let preferences = {
            ...this.props.preferences
        };
        preferences.Apps.Apps = this.state.oldApps;

        this.props.savePreferences(preferences);
        this.toggleIsReorderApps(false);
        const selectedApps = getSelectedApps(preferences?.Apps?.Apps);
        this.setState({
            selectedApps
        });
    };

    render() {
        const itemsPerPanel = this.state.noItemsByWidth[this.props.screenSize];
        const currentApps = filterBadData(
            this.state.selectedApps,
            AppConfigs
        )?.slice(
            itemsPerPanel * this.state.active - itemsPerPanel,
            itemsPerPanel * this.state.active
        );
        const noPanels = Math.ceil(
            Object.keys(this.state.selectedApps).length / itemsPerPanel
        );
        return (
            <React.Fragment>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        isMenuDisabled: this.state.isReorderApps,
                        menu: this.state.menu,
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    {...this.props}
                    className="myOdu__myApps"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        gap={0.5}
                    >
                        <Grid
                            item
                            container
                            className="align-items-center"
                            direction="row"
                            wrap="nowrap"
                            spacing={0}
                        >
                            {/* Left moving arrow */}
                            <Grid
                                item
                                container
                                xs={'auto'}
                                hidden={noPanels <= 1}
                            >
                                <Grid item xs="auto">
                                    <PortalTooltip title={'Previous Panel'}>
                                        <div>
                                            <IconButton
                                                className={'ms-xs-0 me-xl-3'}
                                                sx={{borderRadius: '0', p: 1}}
                                                aria-label="Previous App Panel"
                                                id="myApps__button_prev"
                                                onClick={this.onPrev}
                                                disabled={noPanels <= 1}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faChevronLeft}
                                                    fixedWidth
                                                />
                                            </IconButton>
                                        </div>
                                    </PortalTooltip>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className="px-0 text-center"
                                style={{}}
                            >
                                <Stack direction="row" justifyContent="end">
                                    <Link
                                        id="myApps__link_allApps"
                                        underline="hover"
                                        component={RouterLink}
                                        to="/allApps"
                                    >
                                        All Apps List
                                    </Link>
                                </Stack>
                                {currentApps.length != 0 ? (
                                    <AppsPanel
                                        isReorderApps={this.state.isReorderApps}
                                        onDragUpdate={this.onDragUpdate}
                                        onDragEnd={this.onDragEnd}
                                        apps={currentApps}
                                        className="appsPanelCenter"
                                        itsSmall={this.itsSmall}
                                        itemsPerPanel={itemsPerPanel}
                                        isMyApps={true}
                                    />
                                ) : (
                                    <div>
                                        <Typography
                                            component="p"
                                            variant="small"
                                        >
                                            No apps found.{' '}
                                            <Link
                                                style={{cursor: 'pointer'}}
                                                onClick={this.onReset}
                                            >
                                                Set up apps.
                                            </Link>
                                        </Typography>
                                    </div>
                                )}
                            </Grid>

                            {/* Right movinmg arrow */}
                            <Grid
                                item
                                container
                                xs={'auto'}
                                hidden={noPanels <= 1}
                            >
                                <PortalTooltip title={'Next Panel'}>
                                    <div>
                                        <IconButton
                                            className={'ms-xs-0 ms-xl-3'}
                                            sx={{borderRadius: '0', p: 1}}
                                            aria-label="Next App Panel"
                                            id="myApps__button_next"
                                            onClick={this.onNext}
                                            disabled={noPanels <= 1}
                                        >
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                fixedWidth
                                            />
                                        </IconButton>
                                    </div>
                                </PortalTooltip>
                            </Grid>
                        </Grid>
                        {/* Panel Dots */}
                        <Grid
                            item
                            container
                            style={{width: '100%'}}
                            gap={0.5}
                            justifyContent={'center'}
                        >
                            {Array(noPanels)
                                .fill(0)
                                .map((_, idx) => {
                                    const isSelected =
                                        idx === this.state.active - 1;
                                    return (
                                        <Grid item>
                                            <PortalTooltip
                                                title={
                                                    isSelected
                                                        ? 'Currently Selected Panel ' +
                                                          (idx + 1)
                                                        : 'Go to panel ' +
                                                          (idx + 1)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        isSelected
                                                            ? filledCircle
                                                            : emptyCircle
                                                    }
                                                    size={
                                                        isMobile ? 'sm' : '2xs'
                                                    }
                                                    onClick={() => {
                                                        this.setState({
                                                            active: idx + 1
                                                        });
                                                    }}
                                                    style={{cursor: 'pointer'}}
                                                />
                                            </PortalTooltip>
                                        </Grid>
                                    );
                                })}
                        </Grid>

                        {this.state.isReorderApps && (
                            <Stack
                                sx={{width: '100%', mx: 2}}
                                direction={{xs: 'col', sm: 'row'}}
                                alignItems={'center'}
                                justifyContent={'flex-end'}
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    id={'myApps__button_saveNewArrangement'}
                                    onClick={this.onSaveNewArrangement}
                                    className="myOdu__button primary myAppsButton"
                                >
                                    Save Arrangement
                                </Button>

                                <Button
                                    variant="outlined"
                                    size="small"
                                    id={'myApps__button_rearrangeCancel'}
                                    onClick={this.onRearrangeCancel}
                                    className="myOdu__button secondary myAppsButton"
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        )}
                    </Grid>
                </Widget>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({
    saveSetup: setup => dispatch(saveSetup(setup)),
    savePreferences: preferences => dispatch(savePreferences(preferences))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithSmallScreen(MyApps));
