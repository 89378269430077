import React, { Component } from 'react';
import { Stack, Typography, Link } from '@mui/material';
import { cleanUpID } from '../common/utils';

export function SafetyContacts (props){
    return(
        <Stack 
            id={props.id + "__stack_contactWrapper_" + props.data.id } 
            key={props.id + "__stack_contactWrapper_" + props.data.id } 
            direction="row" 
            justifyContent={"space-between"} 
        >
            <Typography id={props.id+"__text_title_" + props.data.id} className="phoneTitle">{props.data.title}</Typography>
            <Link id={props.id+"__link_phone_" + props.data.id+"_" +cleanUpID(props.data.phoneNumber)} href={"tel:" + props.data.phoneNumber} className="phoneNumber" underline='hover'>{props.data.phoneNumber}</Link>
        </Stack>
    );
}

