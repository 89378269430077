import React, { Component } from "react";
import Checkbox from "@mui/material/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { IconButton, Menu, MenuItem } from "@mui/material";
import PrettyCheckbox from "./PrettyCheckbox";
import { Tooltip } from "reactstrap";

class ActionCheckbox extends Component {
  state = {
      isOpen: false,
      anchorEl: ""
  }

  toggleIsOpen = () => {
    this.setState({isOpen: !this.state.isOpen});
  }
    
  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
    this.toggleIsOpen();
  };

  handleClose = () => {
    //TODO: navigate or run something when closed
    this.toggleIsOpen();
  };

  render () {
    return ( <div className="myOdu__actionCheckbox">
      <PrettyCheckbox {...this.props} />

      {/* //TODO: doesn't find target. always errors out */}
      {/* <Tooltip title={this.props.label} enterDelay={500} enterNextDelay={500} target={this.props.id + "_button"}> */}
        <IconButton sx={{p:0, borderRadius: 0}} onClick={this.handleClick} aria-label={this.props.label} id={this.props.id + "_button"} className="myOdu__actionCheckboxMenuButton">
          <FontAwesomeIcon icon={ faCaretDown } size="xs" fixedWidth style={{color: 'black', marginTop: "-5px" }}/>
        </IconButton>
      {/* </Tooltip> */}

      <Menu
          id={this.props.id + '_menu'}
          anchorEl={this.state.anchorEl}
          open={this.state.isOpen}
          onClose={this.handleClose}
          aria-label={this.props.label + " menu"}
        >

          {/* TODO://pull in menu from var */}
          <MenuItem onClick={this.handleClose}>Delete</MenuItem>
          <MenuItem onClick={this.handleClose}>Send Text</MenuItem>
          <MenuItem onClick={this.handleClose}>Send Email</MenuItem>
        </Menu>

    </div>);
  }
}
export default ActionCheckbox;