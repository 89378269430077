import React from 'react';
import {defaultAnimateLayoutChanges, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import AppCard from './AppCard';

function SortableApp(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.id,
    transition: {
      duration: 500,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  });


  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };
  
  return (
    <div className="mb-1 mx-1" ref={setNodeRef} style={{...style, height: '100%'}} {...attributes}>
       <div style={{height: '100%'}}>
          <AppCard className="sortableApp" app = {props.app} moveApp = {props.moveApp} getSideAppsIndexes = {props.getSideAppsIndexes} listeners = {{...listeners}} isDNDSelected = {props.isDNDSelected} />
        </div>
    </div>
  );
}

export default SortableApp;