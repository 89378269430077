import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import {getPersistor, getStore} from './store';
import {PersistGate} from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import interceptUrlParams from './interceptUrlParams';
import { identifyUser } from './AWS';
import { isUserAdmin } from './components/Layout/utils';

const root = ReactDOM.createRoot(document.getElementById('root'));
const error = interceptUrlParams();

const user = await identifyUser();

root.render(
    <Provider store={getStore(isUserAdmin(user))}>
        <PersistGate persistor={getPersistor(isUserAdmin(user))}>
            <App error={error} />
        </PersistGate>
    </Provider>
);
