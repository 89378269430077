import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ZoomIcon() {
  return (
    <SvgIcon>
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.15 300.12" style={{width: 30}}>
        <defs>
          <style>
            {/* .cls-1{stroke-width:5px;}
            .cls-1,.cls-2{fill:none;stroke:#000;stroke-miterlimit:10;} */}
            {/* .cls-2{stroke-width:4.85px;} */}
          </style>
        </defs>
        <circle class="cls-1" strokeWidth={"5"} fill='none' stroke='#000' strokeMiterlimit={10} cx="147.19" cy="151.44" r="136.94"/>
        <path class="cls-2" strokeWidth={"4.85"} fill='none' stroke='#000' strokeMiterlimit={10} d="M59.33,108.56v64.35c.07,14.54,11.91,26.26,26.44,26.21h93.81c2.64.01,4.79-2.11,4.81-4.75v-64.35c-.07-14.54-11.91-26.26-26.44-26.21h-93.78c-2.64-.03-4.81,2.09-4.84,4.73,0,.01,0,.02,0,.02h0ZM193.01,133.67l36.43-28.3c3.16-2.79,5.62-2.09,5.62,2.96v86.27c0,5.74-3,5.04-5.62,2.95l-36.43-28.22v-35.66Z"/>
      </svg>      
    </SvgIcon>
  );
}