import {Time} from '../../common/MomentTime';

const getColumns = actionFunction => {
    return [
        {
            field: 'STATE',
            headerName: 'State',
            flex: 1
        },
        {
            field: 'TIMESTAMP',
            headerName: 'Created At',
            flex: 1,
            valueGetter: params =>
                Time.University(params.row.TIMESTAMP).format(
                    'MM/DD/YYYY hh:mm:ss A'
                )
        },
        {field: 'MIDAS', headerName: 'Midas', flex: 1},
        {
            field: 'ERROR',
            headerName: 'Error',
            flex: 1
        },
        {
            field: 'DESCRIPTION',
            headerName: 'Description',
            flex: 1
        },
        {
            field: 'FIRST_NAME',
            headerName: 'First Name',
            flex: 1
        },
        {
            field: 'LAST_NAME',
            headerName: 'Last Name',
            flex: 1
        },
        {
            field: 'EMAIL',
            headerName: 'Email',
            flex: 1
        }
    ];
};

export default getColumns;
