export const SAVE_STATUS_ALERTS = 'SAVE_STATUS_ALERTS';
export const SAVE_STATUS_ALERTS_IS_ERROR = 'SAVE_STATUS_ALERTS_IS_ERROR';

export const saveStatusAlerts = statusAlerts => ({
    type: SAVE_STATUS_ALERTS,
    payload: {statusAlerts}
});

export const saveStatusAlertsIsError = isError => ({
    type: SAVE_STATUS_ALERTS_IS_ERROR,
    payload: {isError}
});
