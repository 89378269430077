import React, {Component} from 'react';
import {Stack} from '@mui/material';
import WithRouter from '../Router/WithRouter';

class Branding extends Component {
    getCrown = (isPositionTopNav, isVisible) => {
        return (
            <div className={"d-inline "}>
                <img
                    // className={"crown " +  (isPositionTopNav ? "blue" : "white")}
                    className="crown "
                    id="sideNav__img_crown"
                    src="/img/oduCrown_white.svg"
                    // src={(isPositionTopNav) ? "/img/oduCrown_blue.svg" : "/img/oduCrown_white.svg"}
                    alt="ODU Crown"
                    onClick={() => this.props.navigate('/')}
                    style={{cursor: 'pointer'}}
                />
            </div>
        );
    };

    getAppTitle = (isPositionTopNav, isVisible) => {
        return (
            <h1
                className={
                    'myOdu__stylizedText d-inline appTitle ' +
                    (isVisible ? 'opacity100 ' : 'opacity0 ') +
                    (isPositionTopNav ? 'positionTopNav ' : 'positionSideNav ')
                }
            >
                <span>My</span>
                <span>ODU</span>
            </h1>
        );
    };

    render() {

        return (
            <React.Fragment>
                <Stack
                    className={"branding d-none d-sm-block "}
                    direction="row"
                    justifyContent={'space-between'}
                    sx={{width: (this.props.isPositionTopNav? "": '100%')}}
                >
                    
                    {this.props.isCrownVisible && 
                        this.getCrown(this.props.isPositionTopNav)
                    }
                    
                    {this.getAppTitle(this.props.isPositionTopNav, true)}          
                </Stack>

                <Stack className={"branding d-block d-sm-none " + (this.props.isTitleVisible ? "titleVisible" : "titleHidden")}>
                    {this.getCrown(true)}
                    {this.getAppTitle(true, this.props.isTitleVisible)}  
                </Stack>
            </React.Fragment>
        );
    }
}

export default WithRouter(Branding);
