import {createTheme, responsiveFontSizes} from '@mui/material/styles';

const theme = responsiveFontSizes(
  createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'black',
            color: 'white',
            border: '',
            fontSize: '0.8rem'
          }
        }
      }
    },
    palette: {
      mode: 'light',
      primary: {
        dark: '#0A192D', //brand color 'midnight'
        main: '#003057', //brand color 'monarch blue'
        light: '#F0F8FF' //alice blue, not technically a lighter verison of 'monarch blue'
      },
      secondary: {
        main: '#1e7a92' //slightly darker brand color 'waterside'
      },
      tertiary: {
        light: '#d7deef',
        main: '#3658AD', //brand color 'denim'
        dark: '#263e79',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#c23335'
      },
      warning: {
        main: '#ffca28',
        contrastText: '#FFFFFF'
      },
      info: {
        main: '#3658ad'
      },
      //may not be used
      brandColors: {
        monarchBlue: '#003057',
        silverReign: '#828A8F',
        skyBlue: '#98C5EA',
        carrierMetal: '#404A5A',
        royalBlue: '#2B1ED9',
        bigBlue: '#202898',
        midnight: '#0A192D',
        coastalStorm: '#ACAAB4',
        azure: '#4348DD',
        denim: '#3658AD',
        highTide: '#164C6D',
        aluminum: '#E1EAF0',
        barryBlue: '#729BE6',
        hamptonBlue: '#4D89C1',
        waterside: '#2188A2',
        compliantWaterside: '#1F8098',
        wisconsinGray: '#D6D6DA',
        eveningBreeze: '#63B9D6',
        seafoam: '#20AEC5'
      }
    },
    typography: {
      fontFamily: "'Open Sans', Arial, Helvetica",
      // fontSize: 13,
      // color: 'black',

		h1: {
			fontSize: '2.2rem',
			fontWeight: 600,
		},
		h2: {
			fontSize: '2.0rem',
			fontWeight: 600,
		},
		h3: {
			fontSize: '1.5rem',
			fontWeight: 600,
		},
		h4: {
			fontSize: '1.3rem',
			fontWeight: 600,
		},
		h5: {
			fontSize: '1.2rem',
			fontWeight: 600,
		},
		h6: {
			fontSize: '1rem',
			fontWeight: 600,
			lineHeight: 1.4,
		},
		p: {
			fontSize: '0.9rem'
		},
		small: {
			fontSize: '0.9rem'
		},
		smaller:{
			fontSize: '0.8rem'
		},
		smallest: {
			fontSize: '0.75rem'
		},
		widgetTitle:{
			fontSize: '1rem',
			weight: 300,
			fontStyle: 'italic'
		},
		widgetTitleFirstWord: {
			fontSize: '1rem',
			weight: 700,
		},
	},
  	breakpoints:{
		values: { 
			xs: 0,
			mobileSm: 375,
			mobile: 414,
			sm: 576,
			md: 768,
			lg: 992,
			xl: 1200,
			xxl: 1400,
			xxxl: 1600,
			xxxxl: 1900,
			// '2xl': 1400,
			// '3xl': 1600,
			// '4xl': 1900,
		},
  	}
}));


export default theme;
