import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import WithRouter from '../Layout/Router/WithRouter';
import PageNavigationCard from './PageNavigationCard';

class AdminPanel extends Component {
    state = {
        adminPages: [
            {page: 'tutoring', title: 'Tutoring'},
            {page: 'locations', title: 'Locations'},
            {page: 'feedback', title: 'Feedback'},
            {
                page: 'cognito-error-log',
                title: 'Cognito Error Log'
            },
            {
                page: 'frontend-error-log',
                title: 'FE Error Log'
            }
        ]
    };
    render() {
        return (
            <React.Fragment>
                <div
                    id={'adminPanel__div_contentWrapper'}
                    className="myOdu__container_maxWidth"
                    style={{marginTop: '2rem'}}
                >
                    <h4 id={'adminPanel__text__adminPanelTitle'}>
                        Admin Panel
                    </h4>
                    <Row
                        id={'adminPanel__col_tutoringAndLocations'}
                        className="g-3"
                    >
                        {this.state.adminPages.map(item => (
                            <Col id={`adminPanel__col_${item.page}`} md={3}>
                                <PageNavigationCard
                                    page={item.page}
                                    title={item.title}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default WithRouter(AdminPanel);
