import React, { Component } from 'react';
import { Box, Stack, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationCheck, faCircleCheck, faCircleXmark, faLocationXmark, faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { faCircleDot, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from './PortalTooltip';

class PlaceSummary extends Component {
    state = {
        isExpand: true,
        active: 0,
    }

    getIcon = () => {
        let icon = "";
        switch(this.props.data.location.operatingStatus) {
            case ("open"):
                icon = (this.props.data.location?.mapUrl) ? faLocationCheck : faCircleCheck;
                break;
            case ("closed"):
                icon = (this.props.data.location?.mapUrl) ? faLocationXmark : faCircleXmark;
                break;
            default: 
                icon = (this.props.data.location?.mapUrl) ? faLocationDot : faCircleDot;
            }
        return icon;
    }

    getCssClass = () => {
        let cssClass = (this.props.data.location.operatingStatus == "open"? "myOdu__operatingStatus open" : (this.props.data.location.operatingStatus == "closed" ?  "myOdu__operatingStatus closed" : "myOdu__operatingStatus unknown"));
        cssClass += (this.props.data.location.mapUrl)? "" : " myOdu__operatingStatus locationDot";
        return cssClass;
    }

    getOperatingStatusMessage = () => {
        let greeting = "";
        switch(this.props.data.location.operatingStatus) {
            case ("open"):
                greeting = this.props.data.location.title + ' is open.';
                greeting += (this.props.data.location?.closesAt)? " It closes at " + this.props.location.closesAt + "." : "";
                break;
            case ("closed"):
                greeting = this.props.data.location.title + ' is closed.';
                break;
            default: 
                greeting = this.props.data.location.title + ' operating status is unknown. ';
            }

        greeting += (this.props.data.location.mapUrl)? " Open map in new window." : "";

        return greeting;
    }

    getOperatingStatus = () =>{
        return (
            this.props.data.location.operatingStatus == "open" ?
                <Typography className="myOdu__operatingStatus open" variant="smallest">Open</Typography>
                : (this.props.data.location.operatingStatus == "closed" ?
                    <Typography className="myOdu__operatingStatus closed" variant="smallest">Closed</Typography>
                    : ""
                )
        );
    }

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand})
    }

    onUnwantedClick = e => {
        e.stopPropagation(); 
    }

    render (){
        return  <>
            <Typography id={this.props.id + "__typography_title"} className="title" component="h4" onClick={() => this.toggleExpand()}>
                <Stack id={this.props.id + "__stack_titleWrapper"}>
                    {this.props.data.location.title}
                    <div id={this.props.id + "__title_operatingStatus"}>{this.getOperatingStatus()}</div>
                </Stack>
            </Typography>

            <Box id={this.props.id + "__flexBox"} sx={{ flexGrow: 1 }} onClick={() => this.toggleExpand()} />
           
            <Box id={this.props.id + "__box_mapDot"} className="" onClick={this.onUnwantedClick}>
                {/* mapUrl is to the google page, the mapIframeEmbedUrl is for the embedded map */}
                {this.props.data.location.mapUrl ? <>
                    <PortalTooltip id={this.props.id + "__mapUrlFound_tooltip_mapDot_iconButton"} title={this.getOperatingStatusMessage()}> 
                        <IconButton id={this.props.id + "__mapUrlFound_iconButton"} href={this.props.data.location.mapUrl} target="_blank" sx={{p:'.25rem', borderRadius: 0}}>
                            <FontAwesomeIcon id={this.props.id + "__mapUrlFound_icon_locationDot_" + this.getIcon()} fixedWidth style={{marginRight: '0.25rem', marginLeft: '0.25rem'}} size="sm" icon={this.getIcon()} className={this.getCssClass()} />
                            <div id={this.props.id + "__mapUrlFound_srText"} className="sr-only visuallyHidden">Open Google Map Location</div>
                        </IconButton>
                    </PortalTooltip>             
                    </>: <>
                        <PortalTooltip id={this.props.id + "__mapUrlNotFound_tooltip_mapDot_iconButton"} title={this.getOperatingStatusMessage()}>
                            <FontAwesomeIcon id={this.props.id + "__mapUrlNotFound_icon_locationDot_" + this.getIcon()} fixedWidth style={{marginRight: '0.5rem', marginLeft: '0.25rem', cursor: 'default'}} size="lg" icon={this.getIcon()} className={this.getCssClass()} />
                        </PortalTooltip>
                    </>
                }
                </Box>
        </>
    }
}
export default PlaceSummary;