import React from 'react';
import {useRouteError} from 'react-router-dom';
import Layout from '../';
import GenericError from './GenericError';
import InvalidRoute from './InvalidRoute';
import {Box} from '@mui/material';

const getError = (error, props) => {
    //401 will never be caught here as it's being handled in AdminPanelRoute
    switch (error.status) {
        case 404:
            return (
                <InvalidRoute
                    error={error}
                    isHideHomePage={props.isHideHomePage}
                />
            );
        default:
            return (
                <GenericError
                    error={error}
                    isHideHomePage={props.isHideHomePage}
                />
            );
    }
};

const Error = props => {
    let error = useRouteError();
    return (
        <React.Fragment>
            {props.isLoggedIn && !props.isHideLayout && <Layout>{getError(error, props)}</Layout>}
            {props.isHideLayout && (
                <Box sx={{marginTop: 3}}>{getError(error, props)}</Box>
            )}
        </React.Fragment>
    );
};

export default Error;
