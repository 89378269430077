import React from 'react';
import {Stack, Typography, Box} from '@mui/material';
import {FormTextArea} from '../../common/Form/FormTextArea';

export const FrontendErrorLogDetails = ({data}) => {
    const {error = '{}'} = data || {};

    return (
        <Stack spacing={1}>
            <Typography component="h3" variant="h3">
                Details
            </Typography>
            <Box className="myOdu__box bordered" sx={{m: 0}}>
                <Typography component="label" className="myOdu__label">
                    Error
                </Typography>
                <Typography component="p" variant="smaller">
                    <div className="myOdu__code">
                        {JSON.stringify(JSON.parse(error), null, 2)}
                    </div>
                </Typography>
            </Box>
        </Stack>
    );
};
